import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../Firebase";

export default async function customerPortal() {
  const functions = getFunctions(app, "us-central1");

  const billingPortalSession = httpsCallable(
    functions,
    "createPortalLink"
  );

  try {
    const { data } = await billingPortalSession({
      returnUrl: `${window.location.origin}/settings`,
    });
    window.location.assign(data.url);
  } catch (e) {
    console.log(e);
  }

}
