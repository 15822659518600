import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, db } from "../Firebase";
import { showNotification } from "@mantine/notifications";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useUserAuth } from "../contexts/UserAuthContext";
import { Elements, CardElement, PaymentElement } from "@stripe/react-stripe-js";
import { CheckoutForm, CheckoutSkeleton } from "../components";
import { Loader, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/core";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

import background from "../data/collage.png";

// styles

function Payments(props) {
  useAbleCDPTracking();

  // Page layout styles
  const useStyles = createStyles((theme) => ({
    wrapper: {
      backgroundSize: "cover",
      backgroundImage: `url(${background})`,
    },

    form: {
      borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]}`,
      minHeight: 1000,
      maxWidth: 690,
      paddingTop: 80,

      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        maxWidth: "100%",
      },

      // padding set to 15 on mobile devices
      [theme.fn.smallerThan("sm")]: {
        padding: 15,
      },
    },
  }));

  const { classes } = useStyles();

  const { user } = useUserAuth();

  const [data, setData] = useState(null);
  const [isTrialedSubscription, setIsTrialedSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeID, setStripeID] = useState(null);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [promoCode, setPromoCode] = useState("");

  const getStripePublishableKey = httpsCallable(functions, "getStripePublishableKey");

  const getUserData = async () => {
    setLoading(true);
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        setData(data);
        setLoading(false);
      } else {
        console.log("No user data available yet, checking again in 2 seconds...");
        setTimeout(getUserData, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch API key from cloud
  useEffect(() => {
    // Tracking
    // if (window.uipe) {
    //   window.uipe('init', '652a4334-c77f-4593-9dcc-c4e9fd85b7bb');
    //   window.uipe('track', 'PageView');
    // }

    try {
      getStripePublishableKey().then((response) => {
        const { publishableKey } = response.data;
        setStripePromise(loadStripe(publishableKey));
      });
    } catch (error) {
      console.error("Error fetching Stripe publishable key:", error);
    }
  }, []);

  useEffect(() => {
    if (user != null) {
      getUserData();
    }
  }, [user]);

  useEffect(() => {
    if (data != null && user != null) {
      initializeCheckout();
    }
  }, [user, data]);

  // Creates a customer and subscription then assigns client_secret state to continue payment flow
  const initializeCheckout = async () => {
    try {
      const response = await fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_ENVIRONMENT}.cloudfunctions.net/initializeCheckout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
          userId: data.uid,
          stripeId: data.stripeId,
          subscriptionId: data.subscriptionID,
          utmSource: data.utmSource,
          utmMedium: data.utmMedium,
          utmTerm: data.utmTerm,
          utmContent: data.utmContent,
          utmCampaign: data.utmCampaign
        }),
      });

      const responseJSON = await response.json();

      if (responseJSON.status === 200) {
        setIsTrialedSubscription(responseJSON.trialEnabled);
        setSubscriptionID(responseJSON.subscriptionId);
        setStripeID(responseJSON.customerId);
        setClientSecret(responseJSON.clientSecret);
      } else {
        showNotification({
          title: "You already have an active subscription!",
          autoClose: 8000,
          color: "green",
        });
        console.error(responseJSON.message);
      }
    } catch (error) {
      console.error("Error fetching Stripe client secret:", error);
    }
  };

  // Render loader while loadstripe is being called
  // if (loading) {
  //   return (
  //     <div
  //       id="wrapper"
  //       className="h-fit flex-grow flex-col w-full relative justify-center"
  //     >
  //       <Loader
  //         variant="dots"
  //         color="bb-light-green.0"
  //         size="xl"
  //         className="flex m-auto items-center justify-center h-screen"
  //       />
  //     </div>
  //   );
  // }

  // Dynamic checkout content variable which is a loader while information is being fetched and then the checkout elements when
  // everything is loaded.
  let checkoutContent;

  // If loading or any of the critical values are null present a loader
  if (loading || (user && stripePromise && clientSecret) == null) {
    checkoutContent = (
      <div id="wrapper" className="h-fit flex-grow flex-col w-full relative justify-center">
        <CheckoutSkeleton />
      </div>
    );
  } else {
    const appearance = {
      theme: "stripe",
      variables: {
        fontLineHeight: "1.5",
        fontSizeBase: "14px", // Set the base font size to a smaller value make larger later   },
      },
    };
    checkoutContent = (
      <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
        <CheckoutForm subId={subscriptionID} data={data} props={props} promoCode={promoCode} setPromoCode={setPromoCode} isTrialedSubscription={isTrialedSubscription} stripeId={stripeID} />
      </Elements>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center">
        <Paper className={classes.form} radius={0} px={100} py={50}>
          <Text order={2} className="font-Barlow text-2xl text-slate-700 " align="center" fw={700} mt="md" size="xl" mb={10}>
            Subscribe to Bid Banana
          </Text>
          <div className="">{checkoutContent}</div>
        </Paper>
      </div>
    </div>
  );
}

export default Payments;
