import { List, ThemeIcon } from "@mantine/core";
import { BsDot } from "react-icons/bs";
import React from "react";
import { BananaBanner } from "../components";
import { Link } from "react-router-dom";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";


const Privacypolicy = () => {
  useAbleCDPTracking()
  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <BananaBanner title1="Privacy" title2="Policy" />
      <div className="flex justify-center">
        <div className="flex justify-center max-w-sm  mx-5 sm:max-w-4xl">
          <main className="text-justify flex-col justify-center">
            <h1>Privacy Policy</h1>
            <br />
            <section>
              <span>
                We — meaning Bid Lab Technologies LLC, doing business as Bid
                Banana (also "we," "our," or "us") — prepared this Privacy
                Policy to help you understand our practices with respect to the
                collection, use, and disclosure of information we collect from
                you through: (i) BidBanana.TheBidLab.com, its subdomains, and
                any other website where Bid Banana’s Terms of Use are posted;
                (ii) Bid Banana’s online hosted services; and (iii) Bid Banana’s
                “Software,” meaning, collectively, our browser extensions,
                mobile applications, other downloadable apps, application
                programming interfaces (“APIs”), and tools and documentation
                ((i) through (iii) collectively, our “Service” or “Bid Banana”).
              </span>
            </section>
            <br />
            <h2 className="font-semibold">
              1. What Information We Collect and How
            </h2>
            <section>
              <span>We collect information from you when you:</span>
              <br />
              <List
                withPadding
                icon={
                  <ThemeIcon
                    className="text-black bg-white"
                    size={24}
                    radius="xl"
                  >
                    <BsDot size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  Create or register an account, or administer your account
                </List.Item>
                <List.Item>
                  Input, post, or upload information, data, or other content
                  through the Service
                </List.Item>
                <List.Item>
                  Submit questions, requests, or other communications to us via
                  various communication channels
                </List.Item>
                <List.Item>
                  Contact us for customer support or technical support
                </List.Item>
                <List.Item>
                  Visit any of our websites or download any of our applications
                </List.Item>
                <List.Item>
                  Participate in promotions, user conferences, webinars,
                  demonstrations, contests, sweepstakes, surveys, or other
                  marketing events
                </List.Item>
                <List.Item>Participate in research activities</List.Item>
                <List.Item>
                  Interact with other Bid Banana users and guests on our
                  community forums
                </List.Item>
                <List.Item>
                  Integrate third-party products and services with your Bid
                  Banana account
                </List.Item>
              </List>
              <br />
              <span>
                We also collect information about you from our business
                partners, including referral partners and resellers, and third
                parties that help us with sales and marketing efforts, such as
                prospecting. We may also obtain publicly-available business
                contact information.
              </span>
              <br />
              <span>
                Below is additional information about what we collect, depending
                on how you interact with the Service:
              </span>
              <br />
              <br />
              <h3>a. Your Content</h3>
              <br />
              <span>
                “Content” includes all information, data, and other content, in
                any form or medium, that is collected, downloaded, or otherwise
                received, directly or indirectly, from you (or on your behalf)
                by or through the Service. It also includes information you
                provide when connecting a third-party account, product, or
                service to the Service. Our collection, use, and disclosure
                practices with respect to Content are distinct from those with
                respect to other sorts of information, as explained in Section
                2.a. below.
                <br />
                <br /> We may upload Content automatically with your
                authorization from third-party services (such as from your
                Google Login). See Section 1.c. below.
                <br />
              </span>
              <br />

              <h3>b. Customer Information</h3>
              <br />
              <span>
                “Customer Information” consists of information related to your
                access or use of our Service, the creation of accounts, or that
                otherwise identifies you as a customer or end user of the
                Service. Customer Information includes:
              </span>
              <br />
              <List
                withPadding
                icon={
                  <ThemeIcon
                    className="text-black bg-white"
                    size={24}
                    radius="xl"
                  >
                    <BsDot size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  Identifiers. This includes your name, email address, telephone
                  number, and other similar identifiers.
                </List.Item>
                <List.Item>
                  Customer Records. This includes username and password, payment
                  information, company name, and business email address.
                </List.Item>
                <List.Item>
                  Commercial Information. This includes information about
                  products or services purchased, obtained or considered.
                </List.Item>
                <List.Item>
                  Internet/Network Activity Information. This includes your
                  browsing history, log and analytics data, information about
                  the device(s) used to access the Service, domain server,
                  search history and information regarding your interaction with
                  our Service, and other usage data.
                </List.Item>
                <List.Item>
                  Audio/Visual Information. This includes pictures you provide
                  or upload in connection with our Service, and the content and
                  audio or video recordings of phone or video calls between you
                  and us that we record where permitted by law.
                </List.Item>
                <List.Item>
                  Profession/Employment Information. This includes your current
                  employer, title, and location.
                </List.Item>
                <List.Item>
                  Other Personal Information. This includes personal information
                  you provide to us in relation to a survey, comment, question,
                  request, or inquiry, and information you provide when you
                  connect a third-party account, product or service to the
                  Service.
                </List.Item>
                <List.Item>
                  Inferences. This includes inferring your location using your
                  IP address, or using data from your use of our Service to make
                  predictions about your preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </List.Item>
              </List>
              <br />
              <span>
                If you are an end user using the Service through an account
                created on your behalf by a Bid Banana customer (such as an
                employer, an organization of which you are a member, or another
                individual), we may collect and process Customer Information
                about you on behalf of the Bid Banana customer with whom your
                use of the Service is associated.
              </span>
              <br />
              <br />
              <h3>c. Third-Party Integrations</h3>
              <br />
              <span>
                If you create your account using a service provided by a third
                party such as Google, Microsoft or Apple, or a single-sign-on
                service provided by a third party, we may collect Customer
                Information about you from the third-party service (such as your
                username or user ID associated with that third-party service).
                By choosing to create an account using a third-party service,
                you also authorize us to collect Customer Information necessary
                to authenticate your account with the third party.
              </span>
              <br />
              <br />
              <h3>d. Automatically-Collected Information</h3>
              <br />
              <span>
                We collect information about how you use Bid Banana and your
                actions on Bid Banana, including your IP addresses, browser
                types, operating systems, ISPs, platform types, device types,
                and mobile device identifiers such as make and model, and mobile
                carrier. We may also use tools, including third-party tools, to
                collect analytics data. Some of this information is collected
                through the use of cookies and other tracking technologies, such
                as web beacons, pixels, session replay scripts, and similar
                technologies.
              </span>
            </section>
            <br />
            <h2 className="font-semibold">
              2. How We Use the Information We Collect
            </h2>
            <span>a. Use of Content</span>
            <br />
            <br />
            <span>
              Notwithstanding anything to the contrary in this Privacy Policy,
              we will not use or access your Content except: to provide,
              maintain, improve, or optimize use of Bid Banana; where you (or
              the organization through which you have an account) explicitly
              approve access (e.g., during a customer support inquiry); in
              response to lawful requests by law enforcement or government
              authorities; in response to subpoenas, court orders, or other
              legal process or requests, as required by applicable law as
              determined in our discretion; where necessary (in our sole
              discretion) to ensure the stability and security of Bid Banana and
              our systems (e.g., where we have reason to believe specific
              Content is degrading server stability); and where necessary (in
              our sole discretion) to protect the rights, privacy, safety, or
              property of you, us, or others. Notwithstanding the foregoing, you
              acknowledge and agree that we may retain, take possession of,
              delete, or deny you access to your Content if we believe, in our
              sole discretion, that some or all of your Content, or your use of
              Bid Banana, violates our Terms of Use. We also may analyze
              metadata related to Content.
            </span>
            <br />
            <br />
            <span>b. Use of Other Information</span>
            <br />
            <br />
            <span>
              We use the information we collect for a variety of purposes, and
              how we use it depends on what we collect and which Service (or
              features of the Service) you use. These purposes may include:
            </span>
            <br />
            <List
              withPadding
              icon={
                <ThemeIcon
                  className="text-black bg-white"
                  size={24}
                  radius="xl"
                >
                  <BsDot size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>
                Fulfilling our Contract with You and Providing the Services to
                You. This includes use for the purpose of: (i) responding to
                requests or inquiries from you; (ii) providing customer support
                or technical assistance; (iii) contacting you to provide product
                updates or information about products you have requested or
                purchased; (iv) creating, managing, or administering your
                information and account, including identifying you with your
                account or the account of a Bid Banana customer; and (v)
                auditing relating to interactions, transactions, and other
                compliance activities.
              </List.Item>
              <List.Item>
                Analyzing and Improving our Services Pursuant to our Legitimate
                Interest. This includes use for the purpose of: (i) deriving
                market insights, ways to improve the Service, and performing
                other business analysis or research; (ii) customizing existing
                and future product offerings and other aspects of the Service to
                you and other users; (iii) securing the Service and our systems,
                and protecting your information and data; (iv) detecting
                security incidents and protecting against malicious, deceptive,
                fraudulent, or illegal activity, and prosecuting those
                responsible for that activity; and (v) measuring interest in and
                engagement with our Service and short-term, transient use, such
                as contextual customization of ads.
              </List.Item>
              <List.Item>
                Marketing Our Products and Services. This includes use for the
                purpose of: (i) tailoring and providing you with information
                about Bid Banana products, services, and events that you may be
                interested in, including by email or phone, (ii) monitoring the
                performance of our advertisements and marketing efforts, and
                (iii) communicating with you about your engagement with our
                Service.
              </List.Item>
              <List.Item>
                Legal Obligations and Rights. This includes use for the purpose
                of: (i) establishing, exercising, investigating, prosecuting, or
                defending against legal claims; (ii) complying with laws or
                responding to lawful requests and legal process; (iii)
                protecting the rights and property of Bid Banana, our agents,
                customers, and others, including to enforce our agreements,
                policies, or Terms of Use; (iv) detecting, suppressing, or
                preventing fraud; (v) reducing credit risk and collecting debts
                owed to us; (vi) protecting the health and safety of Bid Banana,
                our customers, or any person; or (vii) fulfilling other
                requirements under applicable law.
              </List.Item>
              <List.Item>
                Participation in any Bid Banana partner program. This includes
                use for the purpose of enabling or supporting participation in
                any such program, which may involve the provision by Bid Banana
                partners of implementation, training, app-building,
                base-building, or other similar services.
              </List.Item>
              <List.Item>
                Consent. This includes use for other purposes that are clearly
                disclosed to you at the time you provide personal information,
                or with your consent.
              </List.Item>
              <List.Item>
                Aggregated or De-Identified Data. This includes use of
                aggregated or de-identified information for a wide variety of
                statistical, analytical, and Service improvement purposes. The
                aggregation or de-identification process prevents the
                information from being reassociated or identified with any one
                customer account, user, or individual.
              </List.Item>
              <List.Item>
                Other Purposes. This includes use for our other, legitimate
                business purposes, or as permitted by applicable law.
              </List.Item>
            </List>
            <br />
            <h2 className="font-semibold">3. How We Disclose Information</h2>
            <span>
              We disclose information we collect for specific purposes,
              including:
            </span>
            <br />
            <List
              withPadding
              icon={
                <ThemeIcon
                  className="text-black bg-white"
                  size={24}
                  radius="xl"
                >
                  <BsDot size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>
                With affiliates and subsidiaries to provide you with aspects of
                the Service, such as customer support;
              </List.Item>
              <List.Item>
                With vendors that help us provide you with aspects of the
                Service, such as data storage, hosting, customer support, and
                payment processing, or that help us with marketing and email
                campaigns, to advertise, gain insights, and perform analytics
                into how the Service is used and how it might be improved;
              </List.Item>
              <List.Item>
                With third parties who enable certain features or
                functionalities of the Service that you’ve requested; Service.
              </List.Item>
              <List.Item>
                With an employer or other organization (or employees or other
                Bid Banana users associated with such an employer or other
                organization) on whose behalf you use the Service, that created
                an Bid Banana account on your behalf, or that owns, manages, or
                is associated with the email domain for an email address on your
                account;
              </List.Item>
              <List.Item>
                With partners in any Bid Banana partner program, when you elect
                to work with one of our partners for implementation, training,
                app-building, or other similar services.
              </List.Item>
              <List.Item>
                As necessary to comply with applicable law, including
                governmental requests and law enforcement requests, and
                otherwise to protect the rights, privacy, safety, or property of
                you, us, or others;
              </List.Item>
              <List.Item>
                As necessary in the event of a proposed or actual
                reorganization, merger, sale, joint venture, assignment,
                transfer, financing, or other disposition of all or any portion
                of our business, assets, or stock;
              </List.Item>
              <List.Item>
                With others for any legitimate business purpose that does not
                conflict with the statements made in this Privacy Policy.
              </List.Item>
            </List>
            <br />
            <h2 className="font-semibold">4. Transfers of Information</h2>
            <span>
              We may transfer to and process your personal information in
              countries outside of the jurisdiction where you are located for
              the various purposes described above. When required by law, we
              will ensure that we rely on an appropriate legal mechanism for the
              transfer, such as your consent, standard contractual clauses (or
              their equivalent), or adequacy decisions. You may ask us, using
              the contact information in Section 9 of this policy, for more
              information about the specific basis we use for transferring your
              data.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">5. Retention of Information</h2>
            <span>
              We store your personal information for no longer than necessary
              for the purposes for which it was collected, including for the
              purposes of satisfying any legal or reporting requirements, and in
              accordance with our legal obligations and legitimate business
              interests. To determine the appropriate retention period for
              personal data, we consider the amount, nature, and sensitivity of
              the personal data; the potential risk of harm from unauthorized
              use or disclosure of your personal data; the purposes for which we
              process your personal data; and the applicable legal requirements.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">6. Managing Your Information</h2>
            <br />
            <span>a. Content</span>
            <br />
            <br />
            <span>
              You may access, correct, amend, or delete Content within Bid
              Banana. You own all Content you upload provided you have lawful
              title thereto. Content you delete (including Content containing
              personal information) may be retained in archived or backup copies
              in order to enable you to use certain features like revision
              history and base snapshots.
            </span>
            <br /> <br />
            <span>b. Other Information</span>
            <br /> <br />
            <span>
              We may use some of the information we collect for marketing
              purposes, including to send you promotional communications about
              new Bid Banana features, products, events, or other opportunities.
              If you wish to stop receiving these communications or to opt out
              of use of your information for these purposes, please follow the
              opt-out instructions by clicking "Unsubscribe" (or similar opt-out
              language) in those communications. You also may have certain
              rights with respect to your data depending on the jurisdiction in
              which you live. Please see the jurisdiction-specific sections
              below for a description of those rights.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">7. Information from Children</h2>
            <span>
              Bid Banana is not intended for use by children under the age of 16
              (or other age as required by local law) and we do not knowingly
              collect personal information from children. If we learn that we
              have collected personal information from a child, we will take
              reasonable steps to delete such information from our files as soon
              as is practicable. If you learn that your child has provided us
              with personal information without your consent, you may contact us
              at support@bidbanana.com.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">8. Changes to Privacy Policy</h2>
            <span>
              Any information that we collect is subject to the Privacy Policy
              in effect at the time such information is collected. We may,
              however, revise the Privacy Policy from time to time. If a
              revision is material, as determined solely by us, we will notify
              you, for example via email. The current version will always be
              posted to our Privacy Policy page.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">9. Contact Us</h2>
            <span>
              If you have any questions about our privacy practices, including
              this policy, you may contact us by email at support@Bidbanana.com
              or by mail at 2960 Greenbriar Blvd, Wellington, FL 33414.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">
              10. EEA, UK, Swiss-Specific Disclosures
            </h2>
            <span>
              The disclosures in this section apply solely to residents of the
              European Economic Area ("EEA"), Switzerland, and the United
              Kingdom ("UK"), and describe how we collect, use, disclose, and
              otherwise process personal data about you. Unless otherwise
              expressly stated, all terms in this section have the same meaning
              as defined in the General Data Protection Regulation ("GDPR"). Bid
              Banana is the controller of the personal data we hold about you in
              connection with your use of the Service.
            </span>
            <br />
            <br />
            <span>a. Lawful Basis of Processing.</span>
            <br />
            <br />
            <span>
              We collect and process your personal data for purposes set forth
              in this Privacy Policy. Where required by law, we obtain your
              consent to use and process your personal data for these purposes.
              Otherwise, we rely on another authorized legal basis (including
              but not limited to the performance of a contract or legitimate
              interest) to collect and process your Personal Data.
            </span>
            <br /> <br />
            <span>b. Marketing and Advertising.</span>
            <br /> <br />
            <span>
              From time to time we may contact you with information about our
              products and services, including sending you marketing or
              advertising messages and asking for your feedback on our products
              and services. For some marketing or advertising messages, we may
              use personal data we collect about you to help us determine the
              most relevant marketing or advertising information to share with
              you. You can unsubscribe at any time from our marketing or
              advertising emails by clicking on the unsubscribe link at the
              bottom of the email or by contacting us at support@Bidbanana.com.
              In addition, when you give us your consent, we may provide your
              information to third parties for targeted advertising or we may
              allow our advertising partners to collect data about you for that
              purpose using cookies or similar technologies.
            </span>
            <br />
            <br/>
            <span>c. Your Privacy Rights</span>
            <br /> <br />
            <span>
              You have the following rights in respect of your personal data
              that we hold: (i) right of access; (ii) right of portability;
              (iii) right to rectification; (iv) right to erasure; (v) right to
              restriction; (vi) right to withdraw consent; and (vii) right to
              object. You also have the right to lodge a complaint to your local
              data protection authority. Information about how to contact your
              local data protection authority is available here. If you wish to
              exercise one of these rights, please submit a request by emailing
              Support@Bidbanana.com with the subject line "European Rights
              Request" Due to the confidential nature of data processing we may
              ask you to provide proof of identity when exercising the above
              rights.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">
              11. California-Specific Disclosures
            </h2>
            <span>
              The disclosures in this section apply solely to individual
              residents of the State of California and provide additional
              information about how we collect, use, disclose, and otherwise
              process personal information within the scope of the California
              Consumer Privacy Act of 2018, as amended, including its
              implementing regulations ("CCPA"). Unless otherwise expressly
              stated, all terms in this section have the same meaning as defined
              in the CCPA.
            </span>
            <br />
            <br />
            <span>a. Sensitive Personal Information</span>
            <br />
            <br />
            <span>
              Certain data elements we collect and use to provide the Service
              may be deemed "sensitive personal information" under CCPA. These
              include your username and password to access your account and
              contents of any messages you send through our email integration
              feature. We do not use or disclose such sensitive personal
              information to “infer” characteristics as defined under the CCPA,
              or for any purpose other than that which is necessary to provide
              the Service as specified in the CCPA.
            </span>
            <br /> <br />
            <span>
              b. Sales and Sharing of Personal Information (Targeted
              Advertising)
            </span>
            <br /> <br />
            <span>
              <Link to='/cookiepolicy' className='text-green-300 font-semibold'>We use cookies</Link> and similar tracking technologies that enable
              certain advertising networks, social media companies, analytics
              services, and other third-party businesses to collect and disclose
              your personal information directly from your browser or device
              when you visit or interact with our Service or otherwise engage
              with us online. In some cases, we may upload personal information
              to certain of these partners for advertising or analytics
              purposes. To opt out of these “sales” or “sharing” of personal
              information (as these terms are defined under the CCPA or other
              applicable US state privacy laws), you must submit a request by
              emailing Support@Bidbanana.com. Note that the above opt-out right
              does not apply where we have appropriately limited our partners to
              be our “service providers” or “processors” (as these terms are
              defined under the CCPA or other applicable US state privacy laws).
              To learn more about GPC, please visit
              http://globalprivacycontrol.org.
            </span>
            <br />
            <br />
            <span>c. California Privacy Rights</span>
            <br /> <br />
            <span>
              As a California resident, you may be able to exercise the
              following rights in relation to the personal information about you
              that we have collected (subject to certain limitations at law):
              rights.
            </span>
            <br />
            <List
              withPadding
              listStyleType="disc"
              icon={
                <ThemeIcon
                  className="text-black bg-white"
                  size={24}
                  radius="xl"
                >
                  <BsDot size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>
                The Right to Know any or all of the following information
                relating to your personal information we have collected and
                disclosed in the last 12 months, upon verification of your
                identity:
              </List.Item>
              <List
                withPadding
                listStyleType="disc"
                icon={
                  <ThemeIcon
                    className="text-black bg-white"
                    size={24}
                    radius="xl"
                  >
                    <BsDot size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  The specific pieces of personal information we have collected
                  about you;
                </List.Item>
                <List.Item>
                  The categories of personal information we have collected about
                  you;
                </List.Item>
                <List.Item>
                  The categories of sources of the personal information;
                </List.Item>
                <List.Item>
                  The categories of personal information that we have disclosed
                  to third parties for a business purpose, and the categories of
                  recipients to whom this information was disclosed;
                </List.Item>
                <List.Item>
                  The categories of personal information we have sold or shared
                  and the categories of third parties to whom the information
                  was sold or shared; and
                </List.Item>
                <List.Item>
                  The business or commercial purposes for collecting, selling,
                  or sharing the personal information.
                </List.Item>
              </List>
              <List.Item>
                The Right to Request Deletion of personal information we have
                collected from you, subject to certain exceptions.
              </List.Item>
              <List.Item>
                The Right to Request Correction of inaccurate personal
                information.
              </List.Item>
              <List.Item>
                The Right to Opt Out of Personal Information Sales or Sharing to
                third parties now or in the future.
              </List.Item>
            </List>
            <br />
            <span>
              You also have the right to be free of discrimination for
              exercising these rights. However, please note that if the exercise
              of these rights limits our ability to process personal information
              (such as in the case of a deletion request), we may no longer be
              able to provide you our Service or engage with you in the same
              manner.
            </span>
            <br />
            <br />
            <span>d. How to Exercise Your California Privacy Rights</span>
            <br /> <br />
            <span>
              Please see Section 11.b. above to exercise your right to opt out
              of personal information sales or sharing. To exercise your rights
              to know, correct, or delete, please submit a request by emailing
              Support@BidBanana.com with the subject line "California Rights
              Request" We will need to verify your identity before processing
              your request. In order to verify your identity, we will generally
              require either the successful login to your account (if
              applicable) and/or the matching of sufficient information you
              provide us to the information we maintain about you in our
              systems. Although we try to limit the personal information
              collected in connection with a request to know, correct, or
              delete, certain requests may require us to obtain additional
              personal information from you. In certain circumstances, we may
              decline a request to exercise the right to know, correct, or
              delete, particularly where we are unable to verify your identity
              or locate your information in our systems, or as permitted by law.
            </span>
            <br />
            <br />
            <span>e. Minors Under Age 16</span>
            <br /> <br />
            <span>
              We do not sell or share the personal information of consumers we
              know to be less than 16 years of age. Please contact us at
              Support@Bidbanana.com to inform us if you, or your minor child,
              are under the age of 16.
            </span>
            <br />
            <br />
            <span>f. California’s "Shine the Light" Law</span>
            <br /> <br />
            <span>
              In addition to the rights described above, California's "Shine the
              Light" law (Civil Code Section §1798.83) permits California
              residents that have an established business relationship with us
              to request certain information regarding our disclosure of certain
              types of personal information to third parties for their direct
              marketing purposes during the immediately preceding calendar year.
              To make such a request, please send an email to
              Support@Bidbanana.com.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">
              12. Disclosures to residents of Colorado, Connecticut, Virginia,
              and Utah
            </h2>
            <span>
              The disclosures in this section apply solely to individual
              residents of the States of Colorado, Connecticut, Virginia, and
              Utah. Privacy laws in these states give residents certain rights
              with respect to their personal data, when they take effect over
              the course of 2023. Those rights include:
            </span>
            <br />
            <List
              withPadding
              icon={
                <ThemeIcon
                  className="text-black bg-white"
                  size={24}
                  radius="xl"
                >
                  <BsDot size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>
                Right to Access Information. You have the right to access and
                obtain a copy of your personal data.
              </List.Item>
              <List.Item>
                Right to Request Deletion. You have the right to request that we
                delete personal data provided by or obtained about you.
              </List.Item>
              <List.Item>
                Right to Correct. You have the right to correct inaccuracies in
                your personal data.
              </List.Item>
              <List.Item>
                Right to Opt-Out of Targeted Advertising. You may ask us not to
                use or disclose your information for the purposes of targeting
                advertising to you based on your personal data obtained from
                your activity across different businesses, services, websites,
                etc.
              </List.Item>
              <List.Item>
                Right to Opt Out of Personal Information Sales to third parties.
              </List.Item>
            </List>
            <br />
            <span>
              To submit a request to exercise your access, deletion, or
              correction privacy rights, please email us at
              Support@Bidbanana.com with the subject line “Privacy Rights
              Request” and let us know in which state you live. Please see
              Section 11.b. for a description of how to exercise your right to
              opt-out of targeted advertising or sales. Residents of Colorado,
              Connecticut, and Virginia may appeal a refusal to take action on a
              request by contacting us by email at Support@Bidbanana.com.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">13. Nevada-Specific Disclosures</h2>
            <span>
              For residents of the State of Nevada, Chapter 603A of the Nevada
              Revised Statutes permits a Nevada resident to opt out of future
              sales of certain covered information that a website operator has
              collected or will collect about the resident. Although we do not
              currently sell covered information, please contact us at
              support@Bidbanana.com to submit such a request.
            </span>
            <br />
            <br />
            <h2 className="font-semibold">14. Canada-Specific Disclosures</h2>
            <span>If you live in Canada, you have the following rights:</span>
            <br />
            <List
              withPadding
              icon={
                <ThemeIcon
                  className="text-black bg-white"
                  size={24}
                  radius="xl"
                >
                  <BsDot size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>
                Right to Access. You can ask us to: (i) confirm that we have
                personal information about you, and (ii) provide you a copy of
                that information.
              </List.Item>
              <List.Item>
                Right to Correct. You can ask us to correct any inaccurate or
                incomplete personal information that we have about you.
              </List.Item>
            </List>
            <br />
            <span>
              You may submit a request by contacting us at Support@Bidbanana.com
              with the subject line “Canadian Privacy Rights Request”. Before we
              honor your request, we will need to verify your identity.
            </span>
            <br />
            <br />
            <br />
            <br />
            
          </main>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
