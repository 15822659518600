import React, { useEffect } from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Divider,
  Image,
  Group,
} from "@mantine/core";
import GoogleButton from "../components/SocialButtons/GoogleButton";
import AppleButton from "../components/SocialButtons/AppleButton";
import MicrosoftButton from "../components/SocialButtons/MicrosoftButton";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { CTAButton } from "../components/index.js";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import background from "../data/collage.png";
import fruits from "../data/collage.png";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 1000,
    backgroundSize: "100%",
    backgroundImage: `url(${background})`,
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 1000,
    maxWidth: 690,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },

    // padding set to 15 on mobile devices
    [theme.fn.smallerThan("sm")]: {
      padding: 15,
    },
  },
}));

// console.log("Initial URL:", window.location.href);

const Login = () => {
  useAbleCDPTracking()
  const { classes } = useStyles();

  const { logInWithEmail } = useUserAuth();

  // Validation logic
  const form = useForm({
    initialValues: { email: "", password: "" },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
    },
  });

  return (
    <div className={classes.wrapper}>
      {/* Left side / form side  */}
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            try {
              await logInWithEmail(values.email, values.password);
            } catch (e) {
              if (e.code === "auth/user-not-found") {
                form.setErrors({
                  email: "Email not found, please try again or register",
                });
              } else if (e.code === "auth/wrong-password") {
                form.setErrors({
                  password: "Incorrect password, please try again",
                });
              } else {
                form.setErrors({
                  email: "An error has occurred, please try again",
                });
              }
              console.log(e);
              console.log("Login failed:", e);
            }
          })}
        >
          <Paper className={classes.form} radius={0} p={100}>
            <Text
              order={2}
              className="font-Barlow text-3xl text-slate-700"
              align="center"
              fw={700}
              mt="md"
              size="xl"
              mb={40}
            >
              Welcome back to Bid Banana
            </Text>
            <div id="buttonsContainer">
              <div className="sm:mb-3 w-full flex-nowrap inline-flex justify-center">
                {/*Social Buttons*/}
                <Button.Group>
                  <GoogleButton text={"Continue with Google"} />
                  <MicrosoftButton text={"Continue with Microsoft"} />
                </Button.Group>
              </div>
            </div>

            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />

            <TextInput
              label="Email address"
              placeholder="hello@gmail.com"
              error={form.errors.email && "Invalid email"}
              size="md"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              error={form.errors.password && "Incorrect password"}
              mt="md"
              size="md"
              {...form.getInputProps("password")}
            />
            <div className="flex justify-end">
              <Link
                to="/forgotPassword"
                className="font-semibold text-bb-light-green ml-4"
              >
                Forgot password?
              </Link>
            </div>
            {/* <Checkbox label="Keep me logged in" mt="xl" size="md" /> */}
            <div className=" justify-end py-3 my-3 sm:mt-2 flex sm:flex sm:justify-end">
              <Button
                id="loginSubmitButton"
                variant="outline"
                type="submit"
                size="md"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Log In
              </Button>
            </div>
            <Text mr="md" align="center">
              Don't have an account?&nbsp;&nbsp;
              <Link
                to="/register"
                className="font-semibold text-bb-light-green"
              >
                Register
              </Link>
            </Text>
            <Divider orientation="vertical" />
          </Paper>
        </form>
      </div>
    </div>

    // <div>
    //   <Image
    //   src={fruits}
    //   />
    // </div>
  );
};

export default Login;
