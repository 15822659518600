import React, { useState, useEffect } from "react";
import { useUserAuth } from "../contexts/UserAuthContext";
import { GlobalLoader, BananaBanner, PleaseLogin } from "../components";
import { NotFound } from ".";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";
import { db } from "../Firebase";
import { getDocs, collection } from "firebase/firestore";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const MailStatuses = () => {
	
	useAbleCDPTracking();

	// Access user state
	const AuthContext = useUserAuth();
	const user = AuthContext.user;

	// State for holding mail data
	const [mailStatuses, setMailStatuses] = useState(null);

	// *** Data Table Render/Filter Templates ***

	// Priority field render template
	const startTimeRenderTemplate = (mail_status) => {
		let date = new Date(mail_status.delivery.startTime.seconds * 1000);
		return <p>{date.toString()}</p>;
	};

	// Priority field render template
	const SubjectRenderTemplate = (mail_status) => {
		return <p>{mail_status.message.subject}</p>;
	};

	// Document ID field render template
	const DocumentIDRenderTemplate = (mail_status) => {
		return <p>{mail_status.id}</p>;
	};

	// Priority field render template
	const ErrorRenderTemplate = (mail_status) => {
		if (mail_status.delivery.error == null) {
			return <p>Success</p>;
		}
		return <p>Error</p>;
	};

	// Fetch mail data from Firebase, set the "MailStatuses" state, and initialize filters.
	useEffect(() => {
		const mailCollection = collection(db, "mail");
		getDocs(mailCollection).then((mailCollectionSnapshot) => {
			let data = mailCollectionSnapshot.docs.map((doc) => {
				let mailEntry = doc.data();
				mailEntry.id = doc.id;
				return mailEntry;
			});
			setMailStatuses(data);
		});
	}, []);

	if (user == null) {
		return (
			<div id="Favorites" className="mb-10 h-fit flex-grow flex-col w-full relative justify-center">
				<div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
					<BananaBanner title1="Oops!" title2="Please log in" />
					<section>
						<PleaseLogin
							text="Search is only available to Bid Banana Users"
							description="Please log in to access search or create an account!"
						/>
					</section>
					<div id="buttons wrapper" className="flex text-center justify-center sm:mt-12 sm:mb-5 ">
						<Button
							variant="outline"
							type="type"
							size="md"
							radius="xl"
							className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
							component={Link}
							to="/register"
						>
							Sign up to continue
						</Button>
						<div className="inline-block align-baseline pt-2">
							<Link to="/login" className="text-bb-light-green font-semibold mx-8 align-baseline">
								Or sign in
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (user.searchKey == null || !user.admin) {
		return (
			<div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
				<NotFound />
			</div>
		);
	}

	if (mailStatuses != null) {
		return (
			<div id="wrapper" className="h-fit flex-grow w-full relative justify-center">
				<header className="flex flex-col justify-center mt-0 items-center min-h-16 pt-0  text-center">
					<p className="m-0">{/* logo here */}</p>
					<BananaBanner title1="Mailing Status Panel" />
				</header>
				<div id="PageBodyContents" className="flex flex-col items-center w-full pt-8 px-2  bg-[#f8f9fa]">
					<div
						id="StatusContentWrapper"
						className="border-1 border-gray-200 bg-[#fff]  rounded-md px-2 sm:px-6 py-2 sm:py-3  w-full sm:w-3/4 lg:w-full mb-10 overflow-x-auto"
					>
						<DataTable
							value={mailStatuses}
							paginator
							paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink  RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} emails"
							rows={50}
							rowsPerPageOptions={[50, 100, 500]}
							removableSort
							className="font-Barlow"
							size="sm"
							editMode="cell"
							scrollable
							stripedRows
							sortField="delivery.startTime"
							sortOrder="-1"
							emptyMessage="No emails found."
						>
							<Column field="delivery.error" header="Delivery Status" body={ErrorRenderTemplate} style={{ minWidth: "10rem" }}></Column>
							<Column field="message.subject" header="Subject" body={SubjectRenderTemplate} style={{ minWidth: "10rem" }}></Column>
							<Column field="id" header="Document ID" body={DocumentIDRenderTemplate} style={{ minWidth: "10rem" }}></Column>
							<Column
								field="delivery.startTime"
								header="Time Sent"
								body={startTimeRenderTemplate}
								style={{ minWidth: "10rem" }}
							></Column>
						</DataTable>
					</div>
				</div>
			</div>
		);
	} else {
		return <GlobalLoader />;
	}
};

export default MailStatuses;
