import { Anchor, Text, Button, List, Modal, Image } from "@mantine/core";
import React, { useState } from "react";
import { IconCircleCheck } from "@tabler/icons";
import Meeting from "../data/Meeting.webp";
import Chart from "../data/Chart.webp";
import FindBid from "../data/FindBid.webp";
import Scheduler from "./Forms/Scheduler";

const SalesCopyBidFinder = () => {
  const [schedulerModalOpen, setSchedulerModalOpen] = useState(false);

  // Function to open the schedule modal
  const openSchedulerModal = () => {
    setSchedulerModalOpen(true);
  };

  // Function to close the schedule modal
  const closeSchedulerModal = () => {
    setSchedulerModalOpen(false);
  };

  return (
    <>
    <section className="bg-[#d5eeed]"> 
  <div className="grid max-w-screen-xl px-4 py-12 mx-auto lg:gap-8 xl:gap-0 lg:py-24 lg:grid-cols-12">
    <div className="mr-auto place-self-center px-2 lg:col-span-7">
      <h1 className="max-w-2xl mb-6 text-5xl font-extrabold tracking-tight leading-none md:text-6xl">
        Bid Finder
      </h1>
      <p className="max-w-2xl mb-8 font-medium text-gray-600 lg:mb-10 md:text-xl lg:text-2xl">
        Having seen thousands of RFPs, RFIs, RFQs, RFTs, and government
        contracts, we know that each individual firm has its own unique
        needs when sourcing bid opportunities. Unlike other contract
        companies that focus on automated solutions, our RFP Finder
        combines technological efficiencies with human intelligence. Click
        on the button below to find the perfect RFP for your business!
      </p>
      <div className="flex justify-center sm:block my-6">
        <Button
          variant="outline"
          size="lg"  
          radius="xl"
          className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black tour-getStarted"
          onClick={openSchedulerModal}
        >
          Free consultation
        </Button>
      </div>
    </div>
    <div className="hidden lg:block lg:col-span-5 lg:relative"> {/* Use relative positioning */}
      <div
        className="absolute inset-0" // Stretch the image container to fill the parent
        style={{ width: '100%', height: '100%' }} // Full width and height
      >
        <Image
          src={Meeting}
          alt="People sitting looking at chart data"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Make image cover the whole area
        />
      </div>
    </div>
  </div>
</section>

      {/* Second Section */}
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div
              style={{ width: 800, marginLeft: "auto", marginRight: "auto" }}
            >
              <Image
                size="auto"
                src={FindBid}
                alt="People sitting looking at chart data"
              />
            </div>
          </div>
          <div className="ml-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
              What We Do
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              Let our team of dedicated experts actively search, find, and
              present bidding opportunities to make your business grow. With
              extensive experience utilizing public and private bid databases,
              we can find leads to expand your pipeline. Unlike other services
              that simply match keywords to bid opportunities, we have real
              humans sifting through RFPs to deliver you only the most strategic
              bids.
            </p>
            <div className="flex justify-center sm:block my-4">
              <Button
                variant="outline"
                size="md"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black tour-getStarted"
                onClick={openSchedulerModal}
              >
                Show Me
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Third section */}
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
              Why Us
            </h1>
            <ul className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl space-y-4">
              <li>
                <span className="font-semibold text-black">Quality Over Quantity.</span>{" "}
                Using our custom Bid Finder company profile questionnaire, we
                eliminate opportunities you aren’t eligible for based on the
                RFP’s requirements.
              </li>
              <li>
                <span className="font-semibold text-black">Time Saving.</span> We don’t
                just match your products or services with keywords. Our team
                sifts through all the opportunities that match your business so
                you don’t have to.
              </li>
              <li>
                <span className="font-semibold text-black">Increased Win Rate.</span> By
                staying focused on only the most strategic bids for your
                business, you’ll find yourself winning more often.
              </li>
              <li>
                <span className="font-semibold text-black">Complimentary Offering.</span>{" "}
                Did we mention this is a completely free service if you are a
                Bid Manager client?
              </li>
            </ul>
            <div className="flex justify-center sm:block my-4">
              <Button
                variant="outline"
                size="md"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black tour-getStarted"
                onClick={openSchedulerModal}
              >
                Inquire for more information
              </Button>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div
              style={{ width: 800, marginLeft: "auto", marginRight: "auto" }}
            >
              <Image src={Chart} alt="People sitting looking at chart data" />
            </div>
          </div>
        </div>
      </section>

      {/* Modal for scheduler */}
      <Modal
        opened={schedulerModalOpen}
        onClose={closeSchedulerModal}
        size="md"
        radius="lg"
      >
        <Scheduler handleClose={closeSchedulerModal} />
      </Modal>
    </>
  );
};

export default SalesCopyBidFinder;
