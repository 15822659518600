import React, { useState } from "react";
import { useUserAuth } from "../contexts/UserAuthContext";
import { BidFinderClientsTable, NotAuthenticated } from "../components";
import { Button, Modal } from "@mantine/core";
import { AiOutlinePlus } from "react-icons/ai";
import BidFinderClientEnrollmentForm from "../components/Forms/BidFinderClientEnrollmentForm";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const BidFinderAdminPage = () => {

  useAbleCDPTracking();
  const AuthContext = useUserAuth();
  const user = AuthContext.user;

  // State to manage the modal's open/close status
  const [opened, setOpened] = useState(false);

  // State for triggering a table refresh
  const [refreshTable, setRefreshTable] = useState(false);

  // If no user/admin role show Not Authenticated Component
  if (!user || !user.admin) {
    return <NotAuthenticated />;
  }

  // Close modal handler
  const handleCloseModal = () => {
    setRefreshTable((prev) => !prev);
    setOpened(false);
  };

  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow w-full relative justify-center"
    >
      <header className="flex flex-col justify-center mt-0 items-center min-h-16 pt-0 text-center">
      </header>
      <div
        id="PageBodyContents"
        className="flex flex-grow bg-[#f8f9fa] pt-5 h-screen p-4"
      >
        <aside className="w-48 bg-[#f8f9fa] p-4 flex-shrink-0">
          <div className="flex">
            <Button
              onClick={() => setOpened(true)}
              className="text-md hover:bg-gray-100 hover:transition-all ease-in-out items-center bg-white rounded-xl border-0 shadow text-gray-700 inline-flex h-16 min-w-[6.25rem] py-[1.125rem] pl-4 pr-[1.25rem] transition-shadow duration-75 select-none relative"
            >
              <div className="mr-1">
                <AiOutlinePlus size={24} color="black" />
              </div>
              New Client
            </Button>
          </div>
        </aside>
        <main className="flex-grow p-4 rounded-xl bg-white overflow-auto">
          <BidFinderClientsTable
            refresh={refreshTable}
          />
        </main>
      </div>

      {/* Bid Finder Eligible Users Modal */}
      <Modal
        overflow="inside"
        radius="lg"
        size="50%"
        transition="fade"
        transitionDuration={600}
        transitionTimingFunction="ease"
        overlayOpacity={0.1}
        overlayBlur={0}
        opened={opened}
        onClose={handleCloseModal}
        title="Enroll Bid Finder Client"
      >
        <div className="max-h-[60vh] overflow-y-auto">
          <BidFinderClientEnrollmentForm closeModal={handleCloseModal}/>
        </div>
      </Modal>
    </div>
  );
};

export default BidFinderAdminPage;
