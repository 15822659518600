import { Modal } from "@mantine/core";
import React, { useState } from "react";
import useBidFinderClients from "../hooks/useBidFinderClients";
import { db } from "../Firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { showNotification } from "@mantine/notifications";

const BidFinderClientsModal = ({ isOpen, onClose, bidData, ...props }) => {

  const { clients, deleteClient } = useBidFinderClients();

  // Loading state for populating Bid Finder clients
  const [loading, setLoading] = useState(false);

  const saveBidToSelectedClients = async (selectedClientIDs, bidData) => {

    // Define clientID
    const clientID = selectedClientIDs[0];

    try {

      // Get the bid's unique ID
      const bidId = bidData.objectID;

      // Create a reference to the specific user's saved_bids sub-collection in Firestore
      const clientsavedBidsRef = collection(
        db,
        "bidfinder_clients",
        clientID,
        "saved_bids"
      );

      // Check if this bidId already exists in saved_bids for this user.
      const bidDataSnapshot = await getDocs(
        query(clientsavedBidsRef, where("objectID", "==", bidId))
      );

      // If the bid already exists, alert the user to Select another bid
      if (!bidDataSnapshot.empty) {
        showNotification({
          title: "Oops!",
          message: `This client already has this bid saved.`,
          color: "red",
        });
        return;
      }

      // Get current date in dd/mm/yy format
      const currentDate = new Date();

      // Format the date
      const month =
        (currentDate.getMonth() + 1).toString().padStart(2, "0") + "/";
      const day = currentDate.getDate().toString().padStart(2, "0") + "/";
      const year = currentDate.getFullYear();
      const formattedDate = `${month}${day}${year}`;

      // Get UNIX timestamp in seconds
      const unixTimestamp = Math.floor(currentDate.getTime() / 1000);

      // Add the bid Data along with the date and UNIX timestap to the user's saved_bids sub-collection
      const newBidData = {
        ...bidData,
        addedDate: formattedDate,
        addedTimestamp: unixTimestamp,
      };
      await addDoc(clientsavedBidsRef, newBidData);

      showNotification({
        title: "Success!",
        message: `Bid data saved successfully to client's Bid Finder`,
        color: "green",
      });
    } catch (error) {
      console.error(
        `Error saving bid data to client's Bid Finder`,
        error
      );
      showNotification({
        title: "Oops!",
        message: `There was an error adding this bid.`,
        color: "red",
      });
    }
  };

  // Define columns
  const columns = [
    {
      header: "Organization Name",
      key: "organization_name",
      class: "text-sm font-medium text-gray-800",
    },
    {
      header: "Emails",
      key: "emails",
      class: "text-sm text-gray-600",
    },
  ];

  return (
    <Modal
      radius="lg"
      overflow="inside"
      size="50%"
      transition="fade"
      transitionDuration={600}
      transitionTimingFunction="ease"
      overlayOpacity={0.1}
      overlayBlur={0}
      opened={isOpen}
      onClose={onClose}
      title="Select a client whose Bid Finder you would like to add this bid to"
    >
      <div className="max-h-[500px] overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              {columns.map((col) => (
                <th
                  key={col.header}
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {clients.map((client) => (
              <tr
                key={client.id}
                onClick={() => saveBidToSelectedClients([client.id], bidData)}
                className="hover:bg-gray-100 cursor-pointer"
              >
                {columns.map((col) => (
                  <td key={col.key} className="px-4 py-3">
                    <div className={col.class}>{client[col.key]}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default BidFinderClientsModal;