import React from "react";
import { Badge } from "@mantine/core";

const ScraperFieldStatuses = ({ status }) => {

  const fields = [
    "title",
    "agency",
    "state",
    "open_date",
    "close_date",
    "description",
    "url"
  ];

  if (status.bidCount == 0) {
    return (
      <div className="pl-8">
        <div className="text-red-500 ml-2">
          No bids currently on Bid Banana.
        </div>
      </div>
    )
  }

  return (
    <div className="pl-8">
      {fields.map((field) => {
        const fieldStatus = status[field];
        const isError = fieldStatus == "ERROR";

        return (
          <div key={field} className="flex items-center space-x-2">
            <Badge color={isError ? "red" : "green"} size="sm">
              {isError ? "Issue" : "Good"}
            </Badge>
            <div>{field}</div>
            {/* Text color red mostly for illustrative reasons during Dev. once API works lets see what it looks like as normal black  */}
            {isError && (
              <div className="text-red-500 ml-2">
                {fieldStatus.errorMessage}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ScraperFieldStatuses;
