import React from 'react'

const Greeting = ({ category, title, text, italics, text2 }) => {
  return (
    <div className='mb-1'>
      <p className='text-gray-400 '>
        {category}
      </p>
      <p className='text-6xl sm:text-7xl
        text-slate-900 text-center font-normal mb-2 '>
        {title}
      </p>
      <div className='flex justify-center'>
      <p className='text-sm sm:text-2xl tracking-widest text-slate-900 text-center font-extralight'>
        {text}
      </p>
      <p className='text-sm sm:text-2xl italic tracking-widest text-slate-900 text-center font-extralight'>
        {italics}
      </p>
      <p className='text-sm sm:text-2xl tracking-widest text-slate-900 text-center font-extralight'>
        {text2}
      </p>
      </div>
    </div>
  )
}

export default Greeting