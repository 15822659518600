import { Image } from '@mantine/core'
import React from 'react'
import BananaMan from '../data/BananaMan.svg'

const PleaseLogin = ({text, description}) => {

  return (
    <div className='m-4 flex flex-col justify-center sm:flex items-center  sm:flex-col  sm:min-w-4xl'>
       <div className=' p-2 sm:justify-center items-center  rounded-lg sm:pl-10 sm:pr-10'>
        <Image 
        src={BananaMan}
        alt="Man slipping on Banana"
        height={380}
        />
        </div>
        <section className='my-8'>
        <p className='text-3xl font-semibold mb-5 text-center text-slate-800'>
            {text}
        </p>
        <p className='text-center text-md mt-2 text-slate-500'>{description}</p>
        </section>
        </div>
  )
}

export default PleaseLogin
