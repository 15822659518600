import React from 'react'

const BananaOff = (props) => {
  return (
    <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="100%" 
    height="100%"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M11.74 11.11c-.46-2.98 1.89-7.93 1.13-9.93a.4.4 0 0 0-.4-.27c-1.07.05-1.54 2.49-1.86 3.3-.94 2.96-1.24 6.09-.73 9.08.05.23.27-.62.35-.71.09-.21.41-.57.63-.7.17-.23.49-.77.87-.76l.01-.01Z"
      style={{
        fill: "#f4f4f4",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M12.74.99c-1.95-2.52-3.77 2.88-4.15 4.73-.53 1.97-.52 4.44-.42 6.36.72-.24 1.36.31 1.68.93-.03-.3-.1-.86-.15-1.35-.03-.24-.11-2.07.16-4.11.23-1.75 1.31-5.03 1.48-5.44.39-.95.85-1.13.93-1.15.01 0 .09-.03.17-.04.06 0 .12 0 .17.01.05.01.12.05.13.07V.99Z"
      style={{
        fill: "#eee",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M11.75.34c-1.71-.45-3.44 2.84-3.96 4.62-.56 1.96-.85 5.21-.76 7.12 0 0 .41-.11 1.13 0 0 0-.03-.69-.04-.96 0-.24-.02-2.67.2-4.07.39-2.5 1.27-4.67 1.91-5.58.41-.59.52-.65.58-.72.05-.05.26-.2.32-.25.11-.07.29-.14.44-.16h.18Z"
      style={{
        fill: "#e6e6e6",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M10.02 13.18c1.31-4.37 6.63-2.61 8.97.89.34.5 1.38 2.45.92 2.35-1.23-3.11-7.31-8.08-9.88-3.24h-.01Z"
      style={{
        fill: "#eee",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M12.13 11.67c-.71 3.35 2.83 7 5.21 8.9.56.45 1.49 1.25 1.63 1.32 0 0-.16.08-.18.35v.41c-4.09 0-7.38-3.94-8.66-7.87-.25-.88-.33-1.01-.17-1.52 0 0 1.07-1.42 2.16-1.6l.01.01Z"
      style={{
        fill: "#dcdcdc",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M9.97 13.26c2.22-2.82 5.46-1.44 7.52.51 1.12 1.07 2.45 2.72 2.42 2.64-1.14-3.07-7.36-8.18-9.94-3.15Z"
      style={{
        fill: "#c2c2c2",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M12.19 11.76c-.12 1.38.18 3.05 1.45 4.86 1.23 1.77 2.88 3.2 3.75 3.89.18.14.39.32.6.5.44.36.94.81.99.88l-.06.05c-.16-.12-.55-.44-1.01-.83-.21-.17-.43-.35-.6-.49-1.42-1.13-5.91-5.06-5.2-8.83l.08-.02v-.01Z"
      style={{
        fill: "#c2c2c2",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M8.96 12.87c.13-.13.34-.13.47 0 .16.16.32.45.45.9 1.07 3.77 2.73 5.83 4.46 7.23.29.23 1.17.83 2.07 1.19.38.15 1 .31 1.3.36.49.08.79.09 1.09.09 0 0 0 .06.02.34.01.17-.85.27-1.96.3-2.48.06-4.89-.99-6.84-4.03-.37-.59-1.49-2.25-1.49-3.26v-.86c-.01-.85-.07-1.79.43-2.27v.01Z"
      style={{
        fill: "#d1d1d1",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M18.98 23.13c0 .17-1.27.48-1.88.55-2.47.28-4.36-.24-6.47-2.18-.51-.47-1.53-1.82-1.8-2.62-.19-.59-.25-2.45-.25-2.45.52 1.74 2.35 4.53 4.32 5.82.42.28 1.23.65 2.05.85.75.18 1.52.2 1.91.2.78 0 1.88-.14 1.96-.3l.16.14v-.01Z"
      style={{
        fill: "#c2c2c2",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M9.89 13.78s.06-.48.08-.52c-.51-1.4-1.48-1.36-2.93-1.18-3.14.39-1.14 8.72 1.85 8.47.24-.02.22-1.08.17-1.18-.47-.92-.42-2.54-.47-2.95-.04-.32-.09-1.77.01-2.75.08-.7.81-1.59 1.29.11Z"
      style={{
        fill: "#eee",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M7 12c-.86-.1-2.34-.18-3.09.57-.07.07.17-.38.31-.55.24-.27.6-.51.92-.68.54-.32 1.31-.3 1.84-.23l.01.88L7 12Z"
      style={{
        fill: "#eee",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M3.87 12.6c-.37.52-.64 1.27-.54 2.15.25 1.76.57 2.23.86 2.64.29.41.41.51.53.26.05-.11.08-.22.08-.34.03-1.11.03-4.57.76-5.03.35-.24 1.31-.24 1.47-.21L7 11.99c-.98-.11-2.37-.17-3.13.6v.01Z"
      style={{
        fill: "#c2c2c2",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
    <path
      d="M19.16 22.59c0 .39.03.45-.15.54-.14.07-.24-.15-.24-.54s.07-.79.24-.7c.17.09.15.31.15.7Z"
      style={{
        fill: "#505050",
        fillRule: "nonzero",
      }}
      transform="matrix(-1 0 0 1 23.334 0)"
    />
  </svg>
    
  )
}

export default BananaOff