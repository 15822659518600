import React, { useEffect } from "react";


const Scheduler = ({handleClose}) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    // Add a 2 second delay between requests to prevent 429 error
    const delay = 2000; // 2 second delay in milliseconds
    const timer = setTimeout(() => {
      document.body.appendChild(script);
    }, delay);

    return () => {
      clearTimeout(timer);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/tblconsultation/bid-banana-initial-consultation?embed=true"
        width="900"
        height="700"
      ></div>
    </>
  );
};

export default Scheduler;