import React from 'react'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import BananaMan from '../data/BananaMan.svg'

export const NoResults = () => {
    const {results} = useInstantSearch()

    const hasRefinements = results.getRefinements().length >= 3;
    const description = hasRefinements
    ? 'Try and reset your applied filters.'
    : 'Please try another search.'

  return (
    <div className='m-4 flex flex-col justify-center sm:flex items-center  sm:flex-col sm:mr-23 sm:ml-7 sm:min-w-4xl'>
       <div className=' p-2 sm:justify-center items-center  rounded-lg sm:pl-10 sm:pr-10 sm:ml-65 sm:mr-60'>
        <img 
        src={BananaMan}
        alt="Man slipping on Banana"
        />
        <p className='text-2xl font-semibold mb-0 text-center text-slate-800'>
            Sorry we cannot find any matches to your search!
        </p>
        <p className='text-center text-sm mt-2 text-slate-500'>{description}</p>
        {/* <ClearFilters /> */}
        </div>
    </div>
  )
}
