import React from "react";
import { Link } from "react-router-dom";
import {
  createStyles,
  Text,
  Container,
  Group,
  Footer,
  ActionIcon,
} from "@mantine/core";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 0,
  },

  inner: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,
    // borderBottom: '1px solid #6B7280', // gray-500 equivalent

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "hidden",
    },
  },
}));

const footerLinks = [
  { to: "/results", label: "Search", key: 0 },
  { to: "/favorites", label: "Favorites", key: 1 },
  { to: "/getexperthelp", label: "Get Expert Help", key: 2 },
  { to: "/FAQ", label: "FAQ", key: 3 },
  { to: "/privacypolicy", label: "Privacy Policy", key: 4 },
  { to: "/termsofuse", label: "Terms of Use", key: 5 },
];

const socialMediaLinks = [
  { icon: BsFacebook, url: "https://www.facebook.com/bidbanana", key: 0 },
  { icon: BsTwitter, url: "https://twitter.com/BidBananaRFP", key: 1 },
  { icon: BsLinkedin, url: "https://www.linkedin.com/company/bid-banana", key: 2 },
  { icon: BsInstagram, url: "https://www.instagram.com/bidbananarfp/", key: 3 },
  {
    icon: BsYoutube,
    url: "https://www.youtube.com/channel/UCFGU_YvhHHI5BYmmZZbDDTQ",
    key: 4
  },
];

export function FooterCentered() {
  const { classes } = useStyles();

  const renderLink = (link) => (
    <Group key={link.key} className={classes.links.hiddenMobile}>
      <Link className="hover:text-gray-700" to={link.to}>
        {link.label}
      </Link>
    </Group>
  );

  const renderSocialIcon = (social) => (
    <a key={social.key} href={social.url} target="_blank" rel="noopener noreferrer">
      <ActionIcon size="lg" color="dark" variant="transparent">
        <social.icon size={24} />
      </ActionIcon>
    </a>
  );

  return (
    <Footer
      position={{ bottom: 0 }}
      className="hidden sm:block sm:bg-leaf-lightest-green sm:py-4 sm:mt-0 sm:text-sm sm:relative sm:bottom-0 z-0"
    >
      <div className={classes.footer}>
        <div className="p-4 mx-12 xl:mx-20 flex justify-between items-center border-b border-gray-500">
          {footerLinks.map(renderLink)}
        </div>
      </div>
      <Container className="flex justify-between items-center mt-4 pb-3">
        <Text c="dimmed" size="sm" className="text-gray-700">
          This website and its content is copyright of The Bid Lab © 2024.
          <br />
          The Bid Lab is a trademark of Bid Lab, LLC. All rights reserved.
        </Text>

        <Group
          gap={0}
          className="flex-shrink-0"
          justify="flex-end"
          wrap="nowrap"
        >
          {socialMediaLinks.map(renderSocialIcon)}
        </Group>
      </Container>
    </Footer>
  );
}
