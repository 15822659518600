import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  SortBy,
  InstantSearch,
  SearchBox,
  HitsPerPage,
  Hits,
  RefinementList,
  Pagination,
} from "react-instantsearch-hooks-web";
import {
  Panel,
  NoResults,
  NoResultsBoundary,
  EmptyQueryBoundary,
  AddBidEntry,
  AdminHits,
  MobileFilter,
  EmptyQuery,
  PleaseLogin,
} from "../components/index";
import { NotFound } from ".";
import { Stats, SwitchRefinement } from "../components/index";
import { Link } from "react-router-dom";
import BananaBanner from "../components/BananaBanner";
import { Button, Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useUserAuth } from "../contexts/UserAuthContext";
import { useNavigate } from "react-router-dom";

// This function formats the data beig passed in from Algolia
const AdminDashboard = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const navigate = useNavigate();

  const AuthContext = useUserAuth();
  const user = AuthContext.user;
  const placeholderMobile = useMediaQuery("(min-width: 900px");

  const addBidOnClick = () => {
    if (AuthContext.user) {
      setModalOpened(true);
    }
  };

  // If the user is not logged in, do not load a favorites page.
  if (user == null) {
    return (
      <div
        id="Favorites"
        className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
      >
        <div
          id="wrapper"
          className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
        >
          <BananaBanner title1="Oops!" title2="Please log in" />
          <section>
            <PleaseLogin
              text="Search is only available to Bid Banana Users"
              description="Please log in to access search or create an account!"
            />
          </section>
          <div
            id="buttons wrapper"
            className="flex text-center justify-center sm:mt-12 sm:mb-5 "
          >
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link
                to="/login"
                className="text-bb-light-green font-semibold mx-8 align-baseline"
              >
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user.searchKey == null || !user.admin) {
    return (
      <div
        id="wrapper"
        className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
      >
        <NotFound />
      </div>
    );
  }

  const searchClient = algoliasearch("U3KGE4YPBJ", user.searchKey);

  return (
    <div
      id="wrapper"
      className="h-fit flex-grow w-full relative justify-center"
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_PRIMARY_BIDS_INDEX}
        routing={true}
      >
        <header className="flex flex-col justify-center mt-0 items-center min-h-16 pt-0 pb-4 text-center">
          <p className="m-0">{/* logo here */}</p>
          <BananaBanner
            title1="Administrator Panel"
            title2="Edit and hide bids here"
          />
          {/* <PageHeader title='Search and discover' secondLine='the perfect bid-easily'/> */}
          <SearchBox
            placeholder={
              placeholderMobile
                ? 'e.g. "school construction", "software development" or "medical services"'
                : 'e.g. "school construction"'
            }
            classNames={{
              root: "w-4/5 flex justify-center sm:flex w-full sm:mt-5 sm:max-w-xl lg:max-w-2xl",
              form: "sm:flex w-full mt-5 transition duration-200 hover:border-1 hover:border-bb-dark-blue hover:shadow-md focus-within:shadow-md max-w-md rounded-full border border-gray-300 px-5 py-3 items-center sm:max-w-xl lg:max-w-2xl",
              input:
                "font-Barlow bg-white w-5/6 text-md sm:flex-grow focus:outline-none sm:text-md",
              submit: "text-sm",
              reset: "hidden",
            }}
          />
          <div className="flex-col">
            <Button
              variant="default"
              size="md"
              radius="xl"
              className="mr-5 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mt-10"
              onClick={function (e) {
                e.preventDefault();
                addBidOnClick();
              }}
            >
              Add a bid
            </Button>
            <Button
              variant="default"
              size="md"
              radius="xl"
              className="mr-5 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mt-10"
              onClick={function (e) {
                e.preventDefault();
                navigate("/admin/hidden");
              }}
            >
              Hidden Bids
            </Button>
            <Button
              variant="default"
              size="md"
              radius="xl"
              className="mr-5 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mt-10"
              onClick={function (e) {
                e.preventDefault();
                navigate("/admin/statuses/scrapers");
              }}
            >
              Scraper Statuses
            </Button>
            <Button
              variant="default"
              size="md"
              radius="xl"
              className="mr-5 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mt-10"
              onClick={function (e) {
                e.preventDefault();
                navigate("/admin/statuses/mail");
              }}
            >
              Mail Statuses
            </Button>
            <Button
              variant="default"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mt-10"
              onClick={function (e) {
                e.preventDefault();
                navigate("/admin/BidFinder");
              }}
            >
              Bid Finder
            </Button>
          </div>
        </header>

        <Modal
          centered
          overlayColor="gray"
          overlayOpacity={0.55}
          overlayBlur={3}
          size="lg"
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
        >
          <AddBidEntry />
        </Modal>

        {/*filtering by state */}
        <main className="w-full sm:flex m-auto sm:max-w-7xl sm:min-w-7xl sm:py-8 sm:px-4">
          {/* container wrapper */}
          <div id="leftColumn">
            <section className="md:block flex-1 items-center mr-14 max-w-xs hidden">
              {/* container header */}
              <div className="flex items-center justify-between min-h-20">
                <h2 className="font-semibold text-xl py-4">Filters</h2>
                {/* component to clear filters will go here */}
              </div>

              {/* Side Panel */}
              <div className="border-t-1 border-bb-dark-blue py-2 max-w-64">
                <Panel header="State">
                  <RefinementList
                    attribute="state"
                    sortBy={["name:asc"]}
                    limit={5}
                    showMore={true}
                    showMoreLimit={100}
                    classNames={{
                      root: "p-0  ",
                      list: "p-0 font-light",
                      item: "py-1",
                      checkbox:
                        "accent-bb-light-green hover:accent-bb-light-green",
                      count: "p-2 font-thin",
                      labelText: "pl-4 font-normal",
                      showMore: "font-thin font-Barlow flex py-2 pl-2",
                    }}
                  />
                </Panel>

                <Panel header="Site Name">
                  <RefinementList
                    attribute="site_name"
                    sortBy={["name:asc"]}
                    limit={5}
                    showMoreLimit={1500}
                    showMore={true}
                    classNames={{
                      root: "p-0  ",
                      list: "p-0 font-light",
                      item: "py-1",
                      checkbox:
                        "accent-bb-light-green hover:accent-bb-light-green",
                      count: "p-2 font-thin",
                      labelText: "pl-4 font-normal",
                      showMore: "font-thin font-Barlow flex py-2 pl-2",
                    }}
                  />
                </Panel>

                <Panel header="Agency">
                  <RefinementList
                    attribute="agency"
                    searchable={true}
                    sortBy={["name:asc"]}
                    limit={5}
                    searchablePlaceholder="&nbsp;&nbsp;Search agencies"
                    showMoreLimit={500}
                    showMore={true}
                    classNames={{
                      root: "p-0  ",
                      searchBox: "w-fit p-1 pl-3 border-1  rounded-full pl-1 ",
                      list: "pl-1 font-light",
                      item: "py-1",
                      checkbox:
                        "pl-2 accent-bb-light-green hover:accent-bb-light-green",
                      count: "p-2 font-thin",
                      labelText: "pl-4 font-normal",
                      showMore: "font-thin font-Barlow flex py-2 pl-2",
                    }}
                  />
                </Panel>

                <Panel header="AI">
                  <SwitchRefinement
                    attribute="ai_enhanced"
                    label="Show only AI Enhanced"
                    on={true}
                  />
                </Panel>

                <Panel header="Additional Steps">
                  <SwitchRefinement
                    attribute="login_required"
                    label="Hide Login Required"
                    on={false}
                  />
                </Panel>
              </div>
            </section>

            {/* filters footer */}
            <footer className="my-16 mx-0"></footer>
          </div>

          {/* Hits Section */}
          <section className="flex-3 min-w-7xl">
            {/* container header and options */}

            {/* no results boundary */}
            <EmptyQueryBoundary fallback={<EmptyQuery />} user={user}>
              <div
                id="refinement bar"
                className="hidden md:flex w-full h-min-20 py-7 px-7 font-thin text-sm"
              >
                {/* <div className='bottom-1 border-bb-dark-blue justify-end mt-7 py-7 px-0'> */}
                <Stats user={user} />
                <div className="flex ml-auto">
                  <SortBy
                    className="mr-0"
                    classNames={{
                      root: "font-Barlow",
                      select: "font-Barlow",
                      option: "font-Barlow",
                    }}
                    items={[
                      {
                        label: "Relevance",
                        value: "bids",
                      },
                      {
                        label: "Newest",
                        value: "bids_open_date_asc",
                      },
                      {
                        label: "Closing Soon",
                        value: "bids_close_date_asc",
                      },
                    ]}
                  />
                  <HitsPerPage
                    className="ml-4"
                    classNames={{
                      root: "font-Barlow",
                      select: "font-Barlow",
                      option: "font-Barlow",
                    }}
                    items={[
                      {
                        label: "8 results per page",
                        value: 8,
                        default: true,
                      },
                      {
                        label: "16 results per page",
                        value: 16,
                      },
                      {
                        label: "32 results per page",
                        value: 32,
                      },
                    ]}
                  />
                </div>
              </div>
              <NoResultsBoundary fallback={<NoResults />}>
                <Hits hitComponent={AdminHits} />
                <footer className="mb-24 mx-0">
                  <Pagination
                    padding={2}
                    showFirst={true}
                    showLast={true}
                    showNext={true}
                    showPrevious={true}
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    classNames={{
                      root: "flex flex-row mx-0 mb-10 items-center justify-center left-1/2",
                      list: "flex flex-row",
                      item: "mx-1 border border-gray-800, m-1",
                      selectedItem: "bg-bb-yellow",
                      link: "mx-3 my-3",
                    }}
                  />
                </footer>
              </NoResultsBoundary>
            </EmptyQueryBoundary>
          </section>
        </main>
        <MobileFilter user={user} />
      </InstantSearch>
    </div>
  );
};

export default AdminDashboard;
