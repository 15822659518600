import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import {
  createUserWithEmailAndPassword,
  OAuthProvider,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  sendPasswordResetEmail,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth, db } from "../Firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const UserAuthContext = createContext();
const provider = new GoogleAuthProvider();
const MicrosoftProvider = new OAuthProvider("microsoft.com");

export function useUserAuth() {
  return useContext(UserAuthContext);
}

export default UserAuthContext;

export function UserAuthContextProvider({ children }) {
  // Navigation hook for navigating user after successful singout.
  const navigate = useNavigate();
  const location = useLocation();

  // The user state keeps track of the currently logged in user and their data
  const [user, setUser] = useState();

  // The data state holds the user account metadata such as organization name and phone number
  const [data, setData] = useState(null);

  // The data state holds the user's favorites
  const [favorites, setFavorites] = useState(null);

  // This state object is used to update the favorites from outside this component and trigger a Firebase upload
  const [updateFavorites, setUpdateFavorites] = useState(null);

  // State to force a user data update.
  const [forceUpdate, setForceUpdate] = useState(0);

  // Loading state for refernce in other components
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Get UTM parameters from the current URL if they exist
    const urlParams = new URLSearchParams(window.location.search);

    // Capture UTM parameters and save them to localStorage
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");
    const utmTerm = urlParams.get("utm_term");
    const utmContent = urlParams.get("utm_content");

    if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent) {
      localStorage.setItem("utm_source", utmSource || "");
      localStorage.setItem("utm_medium", utmMedium || "");
      localStorage.setItem("utm_campaign", utmCampaign || "");
      localStorage.setItem("utm_term", utmTerm || "");
      localStorage.setItem("utm_content", utmContent || "");
    }
  }, []);

  // This effect refreshes the user data whenever the user state gets updated (any sign in or sign out action)
  useEffect(() => {
    if (user != null) {
      getUserData();
      getUserFavorites();
    } else {
      setFavorites(null);
      setData(null);
    }
  }, [user, forceUpdate]);

  // This effect updates the user data whenever the favorites is updated from another component
  useEffect(() => {
    if (user != null && updateFavorites != null) {
      uploadNewUserFavorites();
      setFavorites(updateFavorites);
    }
  }, [updateFavorites]);

  function forceUserDataUpdate() {
    setForceUpdate(forceUpdate + 1);
  }

  // This function updates the user data with the current favorites state.
  async function uploadNewUserFavorites() {
    console.log("updating favorites...");
    const favoritesDocRef = doc(db, "users", user.uid, "favorites", "favorites");
    await setDoc(favoritesDocRef, {
      favorites: updateFavorites,
    });
  }

  async function updateUserAccountData(name, organizationName, phoneNumber) {
    const userDocRef = doc(db, "users", user.uid);
    await updateDoc(userDocRef, {
      name: name,
      organization_name: organizationName,
      phone_number: phoneNumber,
    });
    const userDocSnap = await getDoc(userDocRef);
    const data = userDocSnap.data();
    setData(data);
  }

  async function getUserFavorites() {
    setLoading(true);
    const userFavoritesDocRef = doc(db, "users", user.uid, "favorites", "favorites");
    const userFavoritesDocSnap = await getDoc(userFavoritesDocRef);
    const userFavoritesData = userFavoritesDocSnap.data();
    if (userFavoritesData == undefined) {
      setFavorites({});
    } else {
      setFavorites(userFavoritesData.favorites);
    }

    setLoading(false);
  }

  // This function retrieves the user's profile data from Firebase.
  async function getUserData() {
    setLoading(true);

    // Get reference to the user data doc
    const userDocRef = doc(db, "users", user.uid);

    // Get a snapshot of the user data doc
    const userDocSnap = await getDoc(userDocRef);

    // Access the data of the documents and assign to respective states
    const userData = userDocSnap.data();

    setData(userData);

    setLoading(false);
  }

  async function resetUserPassword(email) {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Password reset email sent!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  }

  // updating users Email
  async function handleUpdateEmail(newEmail, userPassword) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User is not authenticated");
    }

    // Document reference to user's data
    const userDocRef = doc(db, "users", user.uid);

    try {
      // Update email in firebase Authentication
      const credential = EmailAuthProvider.credential(user.email, userPassword);
      await reauthenticateWithCredential(user, credential);

      await updateEmail(user, newEmail);

      // Update email in Firestore
      await updateDoc(userDocRef, { email: newEmail });

      return { success: true, message: "Email updated successfully!" };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  // This function logs the user in using their Google account and generates the necessary
  // user data files if they do not already exist.
  async function logInWithGoogle(redirectUrl) {
    // //Check if there is a stored redirect URL in localStorage
    // const storedRedirectUrl = localStorage.getItem("redirectUrl")
    // if (storedRedirectUrl) {
    //   // Clear the stored redirect URL from localStorage
    //   localStorage.removeItem("redirectUrl")

    //   console.log("Navigating to store Redirect URL:", storedRedirectUrl);

    //   //Navigate user to the stored redirect URL
    //   navigate(storedRedirectUrl)
    // } else {
    //   // redirect to a default fallback URL
    //   console.log("No stored redirect URL found. Navigating to default URL");
    //   navigate("/search")
    // }
    return signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        // If userDocSnap doesn't exist, this is a new user
        if (!userDocSnap.exists()) {
          const utmSource = localStorage.getItem("utm_source");
          const utmMedium = localStorage.getItem("utm_medium");
          const utmCampaign = localStorage.getItem("utm_campaign");
          const utmTerm = localStorage.getItem("utm_term");
          const utmContent = localStorage.getItem("utm_content");

          await setDoc(userDocRef, {
            name: result.user.displayName,
            email: result.user.email,
            uid: result.user.uid,
            utmSource: utmSource ? utmSource : "",
            utmMedium: utmMedium ? utmMedium : "",
            utmCampaign: utmCampaign ? utmCampaign : "",
            utmTerm: utmTerm ? utmTerm : "",
            utmContent: utmContent ? utmContent : "",
          });

          navigate("/loader");
          navigate("/payments");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  }

  // Microsoft signin with popup
  async function logInWithMicrosoft() {
    return signInWithPopup(auth, MicrosoftProvider)
      .then(async (result) => {
        const user = result.user;
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          const utmSource = localStorage.getItem("utm_source");
          const utmMedium = localStorage.getItem("utm_medium");
          const utmCampaign = localStorage.getItem("utm_campaign");
          const utmTerm = localStorage.getItem("utm_term");
          const utmContent = localStorage.getItem("utm_content");

          await setDoc(userDocRef, {
            name: result.user.displayName,
            organization_name: "",
            phone_number: "",
            email: result.user.email,
            uid: result.user.uid,
            utmSource: utmSource ? utmSource : "",
            utmMedium: utmMedium ? utmMedium : "",
            utmCampaign: utmCampaign ? utmCampaign : "",
            utmTerm: utmTerm ? utmTerm : "",
            utmContent: utmContent ? utmContent : "",
          });
          navigate("/loader");
          navigate("/payments");
        }
      })
      .catch((e) => {
        if (e.code === "auth/account-exists-with-different-credential") {
          alert("This email address is already registered. Please sign in.");
        } else {
          alert("An error has occurred, please try again");
        }
        console.log(e);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  }

  // Logs the user in using their email and password
  async function logInWithEmail(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  // Signs the user up using a traditional email and password system
  async function signUp(email, password, name) {
    return createUserWithEmailAndPassword(auth, email, password).then(async (result) => {
      const user = result.user;
      await updateProfile(user, { displayName: name }).catch((err) => console.log(err));
      const userDocRef = doc(db, "users", user.uid);

      const utmSource = localStorage.getItem("utm_source");
      const utmMedium = localStorage.getItem("utm_medium");
      const utmCampaign = localStorage.getItem("utm_campaign");
      const utmTerm = localStorage.getItem("utm_term");
      const utmContent = localStorage.getItem("utm_content");

      try {
        await setDoc(userDocRef, {
          name: name,
          organization_name: "",
          phone_number: "",
          email: result.user.email,
          uid: result.user.uid,
          utmSource: utmSource ? utmSource : "",
          utmMedium: utmMedium ? utmMedium : "",
          utmCampaign: utmCampaign ? utmCampaign : "",
          utmTerm: utmTerm ? utmTerm : "",
          utmContent: utmContent ? utmContent : "",
        });
        navigate("/loader");
        navigate("/payments"); // Navigate to the payments page
      } catch (e) {
        console.log("Error writing user doc");
        console.log(e);
      }
      return result.user;
    });
  }

  async function logOut() {
    return signOut(auth).then(() => {
      navigate("/");
    });
  }

  async function fetchUserClaims(currentUser) {
    try {
      const idToken = await currentUser.getIdToken(true);
      const response = await fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_ENVIRONMENT}.cloudfunctions.net/getSearchKey`, {
        headers: { Authorization: "Bearer " + idToken },
      });
      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }
      const responseJSON = await response.json();
      currentUser.searchKey = responseJSON.key;
      currentUser.subscriptionStatus = responseJSON.subscriptionStatus;
      currentUser.admin = responseJSON.adminFlag;
      // Refresh user ID token to get updated claims
      await currentUser.getIdToken(true);
      return currentUser;
    } catch (error) {
      console.error("Error in fetchUserClaims:", error);
      return currentUser;
    }
  }

  // This effect updates the user state when the authentication state changes.
  useEffect(() => {
    setLoading(true);
    // Cache the current location (with potential search parameters) for redirect after logging in
    const redirectURL = location.pathname + location.search !== "/" ? location.pathname + location.search : "/search";
    // console.log(redirectURL);
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser != null) {
        showNotification({
          id: "sign-in",
          title: "Signing you in...",
          color: "blue",
          loading: true,
          autoClose: 10000,
        });

        fetchUserClaims(currentUser)
          .then((user) => {
            setUser(user);
          })
          .finally(() => {
            // If this is not the user's first time signing in and they are not currently on the final questions page, redirect them to their previous url
            // or the search page.
            if (currentUser.metadata.creationTime != currentUser.metadata.lastSignInTime && !window.location.href.includes("/finalquestions")) {
              // After successful login, redirect to the cached URL.
              navigate(redirectURL);
            }
            // Set a state flag or a global variable indicating that the user just logged in
            window.justLoggedIn = true;
            updateNotification({
              id: "sign-in",
              title: "Successfully signed in!",
              icon: <IconCheck size={18} />,
              color: "green",
              autoClose: 4000,
            });
          });
      } else {
        setUser(currentUser);
      }
    });
    setLoading(false);
    return unsubscribe;
  }, []);

  const values = {
    user,
    loading,
    data,
    favorites,
    setUpdateFavorites,
    setData,
    forceUserDataUpdate,
    updateUserAccountData,
    handleUpdateEmail,
    resetUserPassword,
    signUp,
    logInWithGoogle,
    logInWithMicrosoft,
    logInWithEmail,
    logOut,
    fetchUserClaims,
  };

  return <UserAuthContext.Provider value={values}>{children}</UserAuthContext.Provider>;
}
