import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  Button,
  Title,
  Text,
} from "@mantine/core";
import GoogleButton from "../components/SocialButtons/GoogleButton";
import AppleButton from "../components/SocialButtons/AppleButton";
import MicrosoftButton from "../components/SocialButtons/MicrosoftButton";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { Link } from "react-router-dom";
import { useForm } from "@mantine/form";
import background from "../data/collage.png";
import { showNotification, updateNotification } from '@mantine/notifications';
import { AiOutlineCheck } from 'react-icons/ai'
import { useAbleCDPTracking } from "../hooks/useAbleTracking";


const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 1000,
    backgroundSize: "100%",
    backgroundImage: `url(${background})`,
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 1000,
    maxWidth: 690,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },

    // padding set to 15 on mobile devices
    [theme.fn.smallerThan("sm")]: {
      padding: 15,
    },
  },

  // if We want to put a logo
  // logo: {
  //   width: 120,
  //   display: 'block',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  // },
}));

const ForgotPassword = () => {
  useAbleCDPTracking()

  const { classes } = useStyles();

  const { resetUserPassword, auth } = useUserAuth();

  // Validation logic
  const form = useForm({
    initialValues: { email: "" },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
    },
  });

  return (
    <div className={classes.wrapper}>
      {/* Left side / form side  */}
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (values.email != "") {
              showNotification({
                id: "load-data",
                title: "Checking email",
                color: "bb-light-green.0",
                loading: true,
              });

              await resetUserPassword(values.email).catch((err) => {console.log(err)})

              if (!values.email) {
                updateNotification({
                  id: "load-data",
                  title: "No user associated with this email",
                  color: "red",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                });
              } else {
                updateNotification({
                  id: "load-data",
                  title: "Instructions sent to your email!",
                  color: "bb-light-green.0",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                });
              }
            }
          })}
        >
          <Paper className={classes.form} radius={0} p={100}>
            <Title
              order={2}
              className="font-Barlow font-base"
              align="center"
              mt="md"
              mb={40}
            >
              Reset Your Password
            </Title>
            <Text my="lg">
              Fear not. Enter the email address you used when you joined and
              we’ll send you instructions to reset your password.
            </Text>
            <TextInput
              label="Email address"
              placeholder="hello@gmail.com"
              required
              size="md"
              {...form.getInputProps("email")}
            />

            {/* <Checkbox label="Keep me logged in" mt="xl" size="md" /> */}
            <div className=" justify-end py-3 my-3 sm:mt-2 flex sm:flex sm:justify-end ">
              <Link
                className="text-green-300 font-semibold pt-2 mx-3"
                to="/login"
              >
                Return to Login
              </Link>
              <Button
                variant="outline"
                // onClick={() => {resetUserPassword()}}
                type="submit"
                size="md"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Reset Password
              </Button>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
