import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Radio, TextInput, Image } from "@mantine/core";
import Bidlabgroup from "../../data/Bidlabgroup.svg";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification, updateNotification } from '@mantine/notifications';
import { AiOutlineCheck } from 'react-icons/ai'


const UploadBidSourceForm = () => {
  const { data, user } = useUserAuth();

  const dataForm = useForm({
    initialValues: {
      name: data ? data.name : "loading...",
      organizationName: data ? data.organization_name : "loading...",
      url: "",
    },
  });

  const userForm = useForm({
    initialValues: {
      name: user ? user.displayName : " ",
      email: user ? user.email : " ",
    },
  });

  //populates the forms after data has been loaded
  useEffect(() => {
    if (user && data) {
      dataForm.setValues({
        name: data.name,
        organizationName: data.organization_name,
      });
      userForm.setValues({ email: user.email });
    } else if (user) {
      dataForm.setValues({ name: "", organizationName: "" });
      userForm.setValues({ email: user.email });
    }
  }, [user, data]);

  return (
    <div className="m-x-xs m-3">
      <form
        onSubmit={dataForm.onSubmit(async (data, values) => {
          try {
            if (
              data.name != "" &&
              user.email != "" &&
              data.organizationName != "" &&
              values.url != "" 
            ) 
            {
              showNotification({
                id: 'load-data',
                title: 'Uploading bid info',
                color: 'bb-light-green.0',
                loading: true,
              })
              
              const doc = await addDoc(collection(db, "mail"),
              {
                to: "maurice@thebidlab.com, amandam@thebidlab.com, brittany@thebidlab.com, amanda@thebidlab.com, respond@thebidlab.com, jacob@thebidlab.com",
                  message: {
                    subject: "Requesting a bid source",
                    html: `${data.name} from ${data.organizationName} would like to add this bid source to bid Banana: ${data.url}. They can be reached at ${user.email}`,
                  }
              })
              if (doc.id == null ) {
                updateNotification({
                id: 'load-data',
                title: 'Mail failed to send',
                color: 'red',
                icon: <AiOutlineCheck size={16} />,
                autoClose: 2000,
              })
              } else {
                updateNotification({
                  id: 'load-data',
                  title: 'Thank you!',
                  color: 'bb-light-green.0',
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                })
              }
            } else {
              alert("field cannot be left empty");
            }
          } catch (e) {
            console.log(e);
          }
        })}>
      <section className="m-2 p-2 flex justify-center  border-1 rounded-xl bg-bb-yellow">
        <Image
          src={Bidlabgroup}
          position="centered"
          fit="contain"
          width={200}
          height={200}
        />
      </section>
      <section className="m-2 p-2  border-1 rounded-xl">
        <article className="px-2">
          <h1 className="font-medium">Ready to upload a bid source?</h1>
          <h2 className="text-justify">
            Please add the link to the bid source you would like to see and we
            will add it to Bid Banana!
          </h2>
        </article>
      </section>
      <section className="m-2 p-2 border-1 rounded-xl">
        <article className="px-2">
          <TextInput
            label="Name"
            placeholder="Name"
            required
            {...dataForm.getInputProps("name")}
          />
          <TextInput
            mt="md"
            label="Email"
            required
            placeholder="Email"
            {...userForm.getInputProps("email")}
          />
          <TextInput
            mt="md"
            mb="md"
            required
            label="Company/Organization Name"
            placeholder="Company/Organization Name"
            {...dataForm.getInputProps("organizationName")}
          />
        </article>
      </section>

      <section className="m-2 p-2  border-1 rounded-xl">
        <article className="px-2">
          <TextInput
            mb="md"
            label="Bid source link"
            required
            placeholder="url"
            {...dataForm.getInputProps("url")}
          />
        </article>
      </section>
      <section className="m-2 p-1 flex justify-start mt-2">
        <Button
          // onClick={() => setOpened2(false)}
          variant="outline"
          type="submit"
          size="sm"
          radius="xl"
          className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
        >
          Submit
        </Button>
      </section>
      </form>
    </div>
  );
};

export default UploadBidSourceForm;
