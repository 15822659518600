/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { useNavigate } from "react-router-dom";
import { Button, Avatar } from "@mantine/core";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";

const User = (props) => {
  const [error, setError] = useState();

  const { user, logOut } = useUserAuth();

  let navigate = useNavigate();

  // Function for handling logout process
  function handleLogout() {
    setError("");
    try {
      logOut();
      props.close();
      navigate("/");
    } catch {
      setError("Failed to log out");
      console.log(error);
    }
  }

  // Navigates to the login page
  const routeToLogin = () => {
    navigate("/login");
    props.close();
  };

  // Handle user account actions with dynamic components
  let userAction;
  let userImg;

  if (user) {
    // If user is logged in, provide a Sign Out option.
    userAction = (
       <Button
       onClick={handleLogout}
       variant="default"
       size="md"
       radius="xl"
       className="flex w-fit justify-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow bg-bb-yellow border-black duration-900 text-gray-900 font-normal"
     >
       Log out
     </Button>
    );
    if (user.photoURL != null) {
      userImg = (
        <img
          className="h-12 rounded-full cursor-pointer"
          src={user.photoURL}
          alt="User Account"
          referrerPolicy="no-referrer"
        />
      );
    } else {
      userImg = <FaUser size={22} />;
    }
  } else {
    // If user is not logged in, provide a Log In option.
    userAction = (
      <Button
        onClick={routeToLogin}
        variant="default"
        size="md"
        radius="xl"
        className="flex w-fit justify-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow bg-bb-yellow border-black duration-900 text-gray-900 font-normal"
      >
        Log In
      </Button>
    );
    userImg = <MdOutlineLogin size={20} />;
  }

  return (
    <>
      <div className="flex justify-center">
        <Button
          leftIcon={userImg}
          className="bg-bb-white text-gray-700 rounded-full hover:bg-transparent"
        ></Button>
      </div>
      {userAction}
    </>
  );
};

export default User;