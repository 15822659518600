import { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = `
      (function (w, d, s, o, f, js, fjs) {
        w["botsonic_widget"] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script", "Botsonic", "https://widget.botsonic.com/CDN/botsonic.min.js");

      Botsonic("init", {
        serviceBaseUrl: "https://api-azure.botsonic.ai",
        token: "5371eb4d-78ce-4327-9aa4-f9932b278785",
      });
    `;

    document.body.appendChild(script);

    // Clean up the script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div></div>; // UI component that will trigger the bot
};

export default Chatbot;