import React from "react";
import { Skeleton, Grid, Card, Center } from "@mantine/core";

const BlogSkeletons = () => {
  const SkeletonLoader = () => (
    <div className="flex flex-col items-center max-w-xl mx-4">
      <Card p={0} className="m-2 mb-8 mx-4 max-w-sm">
        {/* For image */}
        <div className="w-full mb-4">
          <Skeleton height={200} width={384} radius="md" />
        </div>
        {/* For title */}
        <div className="w-full mb-4">
          <Skeleton height={20} width="85%" radius="md" />
        </div>
        {/* For smaller lines */}
        <div className="w-full mb-2">
          <Skeleton height={12} width="100%" radius="md" />
        </div>
        <div className="w-full mb-2">
          <Skeleton height={12} width="95%" radius="md" />
        </div>
        <div className="w-full mb-2">
          <Skeleton height={12} width="90%" radius="md" />
        </div>
        <div className="w-full mb-2">
          <Skeleton height={12} width="95%" radius="md" />
        </div>
      </Card>
    </div>
  );

  // Create an array of 12 SkeletonLoaders
  const skeletonLoaders = Array.from({ length: 12 }, (_, i) => (
    <SkeletonLoader key={i} />
  ));

  return (

    <Grid
      gutter="md"
      cols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
      className="flex justify-center mb-5 sm:mb-15 mt-5 sm:mt-10"
    >
      {skeletonLoaders}
    </Grid>
  );
};

export default BlogSkeletons;
