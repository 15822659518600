import { createCheckoutSession } from '@stripe/firestore-stripe-payments'
import { payments, db } from '../../Firebase';
import { collection, getDocs } from "firebase/firestore";

export default async function checkout(user) {
  try {
    let trialAvailable = false;


    console.log(user.uid)
    // const { user } = useUserAuth();
    const subscriptionCollectionRef = collection(db, "users", user.uid, "subscriptions");
    const subscriptionCollectionSnap = await getDocs(subscriptionCollectionRef);
    console.log(subscriptionCollectionSnap.empty)
    if (subscriptionCollectionSnap.empty) {
      trialAvailable = true;
    }

    const session = await createCheckoutSession(payments, {
      price: 'price_1MF1E4IlyzdMcOuf7On0U594',
      allow_promotion_codes: true,
      success_url: `${window.location.origin}/finalquestions`,
      cancel_url: `${window.location.origin}/canceledpayment`,
      trial_from_plan: trialAvailable,
    })
    window.location.assign(session.url)
  } catch (event) {
    console.log(event);
  }
}  