import React from 'react'
import { Loader } from '@mantine/core';

const GlobalLoader = () => {
  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex justify-center "
    >
      <Loader variant="dots" color='bb-light-green.0' size='xl' className='flex m-auto items-center justify-center h-screen' />
    </div>

  )
}

export default GlobalLoader