import React from "react";
import { BananaBanner, PleaseLogin } from ".";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";

const NotAuthenticated = () => {
  return (
    <div
      id="Favorites"
      className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
    >
      <div
        id="wrapper"
        className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
      >
        <BananaBanner title1="Oops!" title2="Please log in" />
        <section>
          <PleaseLogin
            text="Search is only available to Bid Banana Users"
            description="Please log in to access search or create an account!"
          />
        </section>
        <div
          id="buttons wrapper"
          className="flex text-center justify-center sm:mt-12 sm:mb-5 "
        >
          <Button
            variant="outline"
            type="type"
            size="md"
            radius="xl"
            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
            component={Link}
            to="/register"
          >
            Sign up to continue
          </Button>
          <div className="inline-block align-baseline pt-2">
            <Link
              to="/login"
              className="text-bb-light-green font-semibold mx-8 align-baseline"
            >
              Or sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAuthenticated;
