import React, { useEffect } from "react";
import { Button } from "@mantine/core";
import { FcGoogle } from "react-icons/fc";
import { useUserAuth } from "../../contexts/UserAuthContext.js";
import { useLocation, useNavigate } from "react-router-dom";

const GoogleButton = ({ text }) => {
  const { logInWithGoogle } = useUserAuth();

  async function handleGoogleLogin() {
    // Tracking the attempt to sign in with Google
    if (window.uipe) {
      window.uipe('track', 'SSOAttempt', {
        method: 'Google',
        location: 'LoginPage' 
      });
    }

    try {
      await logInWithGoogle();

      // Optionally, track successful Google sign-in
      if (window.uipe) {
        window.uipe('track', 'SSOSuccess', {
          method: 'Google'
        });
      }
    } catch {
      console.log("Log in with Google failed");
      // Optionally, track failed Google sign-in
      if (window.uipe) {
        window.uipe('track', 'SSOFailure', {
          method: 'Google'
        });
      }
    }
  }

  return (
    <Button
      variant="default"
      size="sm"
      radius="xl"
      leftIcon={<FcGoogle size={22} />}
      onClick={handleGoogleLogin}
      className="mx-0 sm:justify-center sm:flex sm:w-fit text-xs sm:text-base sm:items-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
    >
      {text}
    </Button>
  );
};

export default GoogleButton;
