import { useConnector } from 'react-instantsearch-hooks-web';
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats';

export function useStats(props) {
  return useConnector(connectStats, props);
}

export default function Stats(props) {
  const {
    hitsPerPage,
    nbHits,
    areHitsSorted,
    nbSortedHits,
    nbPages,
    page,
    processingTimeMS,
    query,
  } = useStats(props);

  if (!props.user.admin && query.length < 3) {
    return <>{<span className='text-gray-500'>0 results on 0 pages in 0ms</span>}</>;
  }
  return <>{<span className='text-gray-500'>{nbHits} results on {nbPages} pages in {processingTimeMS}ms</span>}</>;
}