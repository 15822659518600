import React from "react";
import {
  BananaBanner,
  SalesCopyBidFinder,
} from "../components";

const BidFinderSalesPage = () => {

  return (
    <>
      <BananaBanner title1="Bid Finder!" title2="ripe for the picking" />
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <main id="inner-wrapper" className="sm:px-8 sm:pt-8 sm:pb-10">
          <section id="content" role="main">
            <div
              id="containedData"
              className="overflow-auto relative sm:m-auto"
            >
              <SalesCopyBidFinder />
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default BidFinderSalesPage;
