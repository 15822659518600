import React from "react";
import {
  Container,
  Title,
  Accordion,
  createStyles,
  Text,
  Anchor,
} from "@mantine/core";
import { BananaBanner } from "../components";
import { HiOutlineLockClosed } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },
}));

const placeholder =
  "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.";
// sources, bids, functionality, support, general

const FAQ = () => {
  const { classes } = useStyles();
  useAbleCDPTracking()
  
  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <BananaBanner title1="Frequently Asked" title2=" Questions" />

      {/* Title Block */}

      <Container size="md" className={classes.wrapper}>
        <Title className="my-2 mb-4 flex font-Barlow justify-start">
          <div className="bg-bb-yellow">Bid Banana FAQ</div>
        </Title>

        <Text size="large" className="my-8">
          Bid Banana, The Bid Lab’s search engine, was built by a team of RFP
          experts and skilled developers. Together they created the industry’s
          best RFP search engine. Bid Banana helps users easily find RFPs across
          all lines of business and geographic locations.
        </Text>

        {/* Accordian Sources*/}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">
            Sources
          </div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">
                How many sources do you monitor?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We monitor over 4,000 agencies! Using our proprietary process, we
              update our database every day to include all newly announced
              public procurement opportunities.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">Which sources do you use?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Bid Banana uses thousands of sources to ensure we have the most
              comprehensive and accurate bid search engine. If you have a
              question about a specific source, please don’t hesitate to reach
              out to our team for confirmation. Additionally, unlike competing
              bid databases, if you do not see a bid you are looking for, you
              are able to request a bid source to be added. Within 3 business
              days, someone from our team will confirm the status of your
              request in Bid Banana.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Do you have private opportunities?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We intend to have more private opportunities on our application
              soon with our upload feature. The upload option on Bid Banana
              allows agencies and companies in our network to publish RFPs
              directly to our database. Bid Banana also posts private
              opportunities that are sourced through The Bid Lab’s
              <div className="inline-flex">
                <Anchor
                  href="https://www.thebidlab.com/bid-builder/"
                  target="_blank"
                  className="font-semibold text-green-300"
                >
                  &nbsp;Bid Builder&nbsp;
                </Anchor>
              </div>
              service as clients permit.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="credit-card"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What percentage of bids are uploaded vs sourced?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              The majority of bids on our portal are sourced; however, this
              number will vary depending on how frequently bids are being
              uploaded.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Accordian Bids*/}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">Bids</div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">
                How many bids are currently on your database?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              This number is always changing since the number of opportunities
              is fluctuating with agencies’ needs. At the time of this posting,
              over 35,000 bids were live on our search engine! We add new
              opportunities daily as they are released. We are also constantly
              adding new sources to our database, so let us know if you are
              interested in a specific one and we’ll get on it with our
              development team!
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">
                How many new bids are added per day?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              The number of bids added each day is dependent on the agencies
              releasing the bids and when they choose to invite vendors to bid.
              Some industries do tend to see bid trends for certain times of the
              year, and procurement overall can be affected by events such as
              holidays.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Can I see bids after their due date?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Currently, when the due date for a bid passes, the procurement
              opportunity is taken out of our search engine. Bid Banana will be
              rolling out a closed bid feature soon to capture data for all past
              bids that we’ve sourced.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="credit-card"
          >
            <Accordion.Control>
              <div className="font-semibold">
                How long does it typically take to find a bid on your platform?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Our easy-to-use portal is very intuitive and efficient for bid
              searching and favoriting! Our search bar helps you refine your
              opportunity targets, while our state filters let you search within
              a geographical boundary if needed. The time it takes is dependent
              on the specificity of your search, as well as the availability of
              public and private opportunities.{" "}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Accordian Functionality */}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">
            Functionality
          </div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">Can I filter by state?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Yes! Bid Banana lets you filter by all 50 states! You can also
              include multiple states in your customized search using our
              filters.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">Can I filter by industry?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Our search feature helps connect users with opportunities within
              their industry through keywords. We do not include filters for
              industries because of the cross-industry nature of many
              procurement sites. As a search engine focused on small businesses,
              we also encourage business owners to expand their search to
              synonyms, as well as utilizing quotation marks to search phrases.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">Do you offer 24/7 access?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Bid Banana is available 24/7, all day and all the time!
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="agenciesRegister"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Do I need to register with an agency to view bids once I am
                already logged in to Bid Banana?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              For the vast majority of results, users are able to view bid
              documents directly from Bid Banana. For agencies that have
              restrictions around document viewing, Bid Banana will show you
              when a login is required with a
              <div className="inline-flex text-gray-500">
                &nbsp;
                <HiOutlineLockClosed />
                &nbsp;
              </div>
              . We are actively developing a functionality that shares a quick
              how-to tutorial that guides you through the registration process.
              
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="maintenance"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Will I be notified of maintenance?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Yes, you will always be notified of maintenance. Typically we run
              maintenance on our portal during off hours.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="updates"
          >
            <Accordion.Control>
              <div className="font-semibold">
                How often do you update your search engine?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We update Bid Banana daily.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="launch"
          >
            <Accordion.Control>
              <div className="font-semibold">When did you launch?</div>
            </Accordion.Control>
            <Accordion.Panel>
              We launched on January 3, 2023! We have been actively developing
              the software for two years, and we are constantly making
              improvements and innovations based on our extensive experience
              serving clients through The Bid Lab.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Accordian Subscription*/}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">
            Subscription
          </div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What do I get with the subscription?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Users have access to all Bid Banana features, including public and
              private bid opportunities, the ability to upload a bid or source,
              a favorites page and more.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">How much is a subscription?</div>
            </Accordion.Control>
            <Accordion.Panel>
              A Bid Banana subscription is only $49.99 per month!
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Why is Bid Banana so much less expensive than other databases?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Bid Banana was built to bring bidding opportunities to small and
              medium-sized businesses. We believe that bids should be accessible
              to all - not just huge corporations able to pay thousands of
              dollars to our competitors.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="credit-card"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What is your cancellation policy?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Cancel at any time with no contract times or lock-in periods. If a
              subscriber decides to cancel during the monthly period, the
              remaining portion of the fee will not be refunded. Your membership
              remains fully active until the upcoming due date of your account.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="trial"
          >
            <Accordion.Control>
              <div className="font-semibold">Can I get a free trial?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Yes, we provide a 7 day free trial upon initial signup. 
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="discount"
          >
            <Accordion.Control>
              <div className="font-semibold">Do you offer any discounts?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Bid Banana does not offer any discounts at this time; however, Bid
              Banana is fully complimentary for our{" "}
              <div className="inline-flex">
                <Anchor
                  href="https://www.thebidlab.com/bid-manager/"
                  target="_blank"
                  className="font-semibold text-green-300"
                >
                  &nbsp;Bid Manager&nbsp;
                </Anchor>
              </div>{" "}
              clients.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="no find"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What happens if I don’t find anything?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Keep looking! There are bids available in every industry and new
              opportunities are added every single day. Try changing up your
              keywords or filtering parameters to expand your search, or use our
              saved search feature to be notified when something matching your
              search criteria is posted!
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="tool"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What are the terms and conditions for using your tool?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              You can see the terms and conditions by clicking{" "}
              <div className="inline-flex">
                <Anchor
                  href="https://bidbanana.thebidlab.com/termsofuse"
                  target="_blank"
                  className="font-semibold text-green-300"
                >
                  &nbsp;here
                </Anchor>
              </div>
              .
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="access"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Does each sign-up only have access through one email/ login?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Yes. Users should never share their email and login credentials
              with others to avoid infringing on our terms of use and for their
              account security.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="bundle"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Are there bundle deals for multi-year enrollments?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Not at this time. Bid Banana does not aim to lock you into
              multi-year contracts like our competitors. The Bid Lab’s clients
              do receive complimentary access to Bid Banana, so inquire for more
              information
              <div className="inline-flex">
                <Anchor
                  href="https://www.thebidlab.com/contact/"
                  target="_blank"
                  className="font-semibold text-green-300"
                >
                  &nbsp;here
                </Anchor>
              </div>
              .
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Accordian Support*/}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">
            Support
          </div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">What support do you offer?</div>
            </Accordion.Control>
            <Accordion.Panel>
              Support queries are available via chat function in Bid Banana, or
              by emailing support@bidbanana.com. A representative will respond
              as soon as possible.{" "}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What if I need help with a bid?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We are here to help! Bid Banana is a product of The Bid Lab, and
              our experts are waiting to help you win your next bid! Just click
              the ‘Get Started’ button on any of the results to submit a form
              for immediate assistance. Our representatives respond within 24
              hours.{" "}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What do you charge for managing bid responses?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We are happy to walk you through our bid management process and
              billing structure. We can also provide you with a free estimate
              prior to kicking off your bid based on the RFP’s requirements. We
              offer two pricing options: hourly work, or a reduced hourly fee
              with a commission on bids won for you. If you have a specific
              budget in mind, let us know and we are happy to detail exactly
              what can be done within that time.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {/* Accordian General*/}

        <Title className="my-8 flex font-Barlow justify-start">
          <div className="text-bb-dark-blue text-2xl font-semibold">
            General
          </div>
        </Title>

        <Accordion variant="separated">
          <Accordion.Item
            className="bg-white border-gray-200 rounded-lg"
            value="reset-password"
          >
            <Accordion.Control>
              <div className="font-semibold">
                Is there a referral or partnership program?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              We are always happy to explore referrals or partnerships. Please
              contact us by emailing support@bidbanana.com.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="another-account"
          >
            <Accordion.Control>
              <div className="font-semibold">How do I sign up?</div>
            </Accordion.Control>
            <Accordion.Panel>
              On our website by clicking{" "}
              <div className="inline-flex">
              <Link
                to="/register"
                className="font-semibold text-bb-light-green"
              >
                &nbsp;here
              </Link>
              </div>
              .
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className="bg-white border-gray-200 border rounded-lg"
            value="newsletter"
          >
            <Accordion.Control>
              <div className="font-semibold">
                What else should I know about Bid Banana?
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              Bid Banana is the only RFP search engine that has RFP experts
              ready to assist you. This means you can find a bid and have a
              professional help you win it all in one place. No matter the
              industry, complexity or timeline, The Bid Lab has a team of RFP
              gurus who can help your business submit a successful bid. To get
              in touch with an RFP expert,{" "}
              <div className="inline-flex">
                <Anchor
                  href="https://www.thebidlab.com/contact/"
                  target="_blank"
                  className="font-semibold text-green-300"
                >
                  &nbsp;clicking here
                </Anchor>
              </div>
              .
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <br />
        <br />
      </Container>
    </div>
  );
};

export default FAQ;
