import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Tooltip, Spoiler, Accordion, Anchor } from "@mantine/core";
import { KickoffForm, PleaseLogin, Paywall } from "../components";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useUserAuth } from "../contexts/UserAuthContext";
import { showNotification } from "@mantine/notifications";
import { BsTrash3Fill } from "react-icons/bs";
import { IconX } from "@tabler/icons";
import AiIcon from "../components/AiIcon";
import BananaBanner from "../components/BananaBanner";
import { HiOutlineLockClosed, HiQuestionMarkCircle } from "react-icons/hi";
import { TiCreditCard } from "react-icons/ti";
import { TbCalendarTime } from "react-icons/tb";
import BananaOn from "../components/Buttons/Bananas/BananaOn";
import FavoritesSkeleton from "../components/Skeletons/FavoritesSkeleton";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Favorites = (props) => {
  useAbleCDPTracking();
  const AuthContext = useUserAuth();
  const user = AuthContext.user;
  const favorites = AuthContext.favorites;
  const firestore = getFirestore();

  // Set close_cutoff date for conditionally displaying the "Closed" tags
  const closed_cutoff = new Date();
  closed_cutoff.setDate(closed_cutoff.getDate() - 1);

  // A state used for opening and closing of popup form (<Modal/>)
  const [modalOpened, setModalOpened] = useState(false);
  // A state used for opening and closing of popup to confirm clearing favorities (<Modal/>)
  const [clearFavoritesOpened, setClearFavoritesOpened] = useState(false);
  // A state that represents the objectID of the currently selected bid
  const [selectedBid, setSelectedBid] = useState(null);

  // This effect sets the initial selected bid if any favorites exist.
  useEffect(() => {
    if (user != null && AuthContext.favorites != null && !AuthContext.loading && Object.keys(AuthContext.favorites).length != 0) {
      // Slow, but we need to set the initial selected bid to something.
      setSelectedBid(Object.keys(AuthContext.favorites)[0]);
    } else if (user != null && AuthContext.favorites != null && !AuthContext.loading && Object.keys(AuthContext.favorites).length == 0) {
      setSelectedBid(0);
    }
  }, [AuthContext.favorites, AuthContext.loading]);

  // If the user is not logged in, do not load a favorites page.
  if (!user) {
    return (
      <div id="Favorites" className="mb-10 h-fit flex-grow flex-col w-full relative justify-center">
        <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
          <BananaBanner title1="Uh Oh!" title2="Please login" />
          <section>
            <PleaseLogin text="Favorites is only available to Bid Banana users" description="Please login to access favorites or create an account!" />
          </section>
          <div id="buttons wrapper" className="flex text-center justify-center sm:mt-12 sm:mb-5 ">
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link to="/login" className="text-bb-light-green font-semibold mx-8 align-baseline">
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user.subscriptionStatus == null || user.subscriptionStatus != "active" || user.searchKey == null) {
    return (
      <div className="flex justify-center">
        <Paywall text="The favorites feature is only available for paid users" body='Click the "checkout" below to purchase your subscription to Bid Banana' buttonText="Checkout" />
      </div>
    );
  }

  // This function adds the removed favorite to the users past_favorites collection
  async function addToPastFavorites(data, objectID) {
    // Create doc reference for the past_favorite in the past_favorites subcollection
    const pastFavoriteRef = doc(firestore, "users", user.uid, "past_favorites", objectID);
    // Create new document replica of bid in user's past_favorites subcollection
    await setDoc(pastFavoriteRef, data);
  }

  // This function removes the selected bid from the users favorites
  async function modifyUserFavorite(objectID) {
    let favoritesCopy = structuredClone(AuthContext.favorites);
    await addToPastFavorites(favoritesCopy[objectID], objectID);
    delete favoritesCopy[objectID];
    if (objectID == selectedBid) {
      if (Object.keys(favoritesCopy).length != 0) {
        setSelectedBid(Object.keys(favoritesCopy)[0]);
      } else {
        setSelectedBid(0);
      }
    }
    AuthContext.setUpdateFavorites(favoritesCopy);
  }

  // This componenet represents a single bid on the left side of the favorites page
  const FavoriteTab = (props) => {
    // Onclick callback function for activiating the kickoff bid modal
    const kickoffBidOnClick = () => {
      if (!AuthContext.user) {
        showNotification({
          title: "You must be logged in to kick off a bid!",
          autoClose: 3000,
          color: "red",
        });
      } else {
        setModalOpened(true);
      }
    };

    // Onclick callback function for calling modifyUserFavorite
    const removeOnClick = (objectID, title) => {
      showNotification({
        title: "Favorite removed",
        icon: <IconX size={18} />,
        autoClose: 3000,
        message: `Removed "${title}" from favorites!`,
        color: "red",
      });
      return new Promise((resolve, reject) => resolve(modifyUserFavorite(objectID)));
    };

    // Sets the selectedBid state to the index of the clicked bid.
    const favoriteTabOnClick = (objectID) => {
      setSelectedBid(objectID);
    };

    // From Bid Finder:
    // let classes = "border-bb border-2 rounded relative min-w-3xl cursor-pointer hover:border-bb-dark-blue flex px-4 py-3";
    // if (props.objectID === selectedBid) {
    //   classes = "bg-bb-yellow border-2 border rounded relative min-w-3xl cursor-pointer border-bb-dark-blue flex px-4 py-3";
    // }

    // If the currently selectedBid matches the index of this tab, give it a blue border.
    let classes = "bg-transparent border-bb border rounded relative min-w-3xl cursor-pointer hover:border-bb-dark-blue flex px-4 py-3";
    if (props.objectID === selectedBid) {
      classes = "bg-transparent border-bb border rounded relative min-w-3xl cursor-pointer border-bb-dark-blue flex px-4 py-3";
    }

    return (
      <div id="BidContent">
        {/* bid cards min width of 3xl may need some adjusting */}
        <div
          id="CardShell"
          className={classes}
          onClick={function (e) {
            favoriteTabOnClick(props.objectID);
          }}
        >
          <div id="CardInside" className="min-w-full w-100 flex-col grow  border-fuchsia-500">
            {/* Algolia inputs go here */}
            <span className="flex ml-2 font-normal text-sm shrink-0 justify-between">
              <p className="w-4/5 sm:pr-4 sm:w-4/5 text-base font-light truncate text-gray-800">{props.bid.title}</p>
              <div className="flex flex-row justify-end">
                <div className="inline-flex justify-end">
                  <div className="flex ml-auto">
                    <BananaOn className="h-6 w-6" />
                  </div>
                </div>
              </div>
            </span>
            <span className="hidden sm:flex ml-2 font-normal text-sm">
              <p className="text-xs text-gray-600 font-light">{props.bid.state}</p>
            </span>
            <span className="hidden sm:flex ml-2 text-xs text-gray-800 font-light">
              Close Date: <p className="pl-1 text-xs text-gray-800 font-light">{props.bid.close_date.slice(-4) !== "2099" ? props.bid.close_date : "TBD"}</p>
              {new Date(props.bid.close_date) < closed_cutoff && <p className="pl-1 text-xs text-red-600 font-bold">** CLOSED **</p>}
            </span>
            {/* The collapsable content */}
            <Accordion variant="filled" className="bg-white sm:hidden" chevronPosition="right">
              <Accordion.Item value="close-date">
                <Accordion.Control className="p-0 m-0 items-end">
                  {/* <span className="flex ml-2 font-normal text-sm shrink-0 "><p className='w-fit sm:pr-4 sm:w-4/5 text-base font-light truncate text-gray-800'>{props.title}</p></span> */}
                  <span className="flex ml-2 font-normal text-sm">
                    <p className="text-xs text-gray-600 font-light">{props.bid.state}</p>
                  </span>
                  <span className="flex ml-2 text-xs text-gray-800 font-light">
                    Close Date: <p className="pl-1 text-xs text-gray-800 font-light">{props.bid.close_date.slice(-4) !== "2099" ? props.bid.close_date : "TBD"}</p>
                    {new Date(props.bid.close_date) < closed_cutoff && <p className="pl-1 text-xs text-red-600 font-bold">** CLOSED **</p>}
                  </span>
                </Accordion.Control>
                <Accordion.Panel>
                  {/* specific bid content here  */}
                  <div id="BidDetails" className="p-4 min-w-full flex-wrap ">
                    <div id="JobDescriptionContent">
                      {/* Algolia Description */}
                      {/* Title, Agency, State, Open Date, Close Date   */}
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                        Title:&nbsp;&nbsp;
                        <p className="pl-0 font-light text-gray-800 text-sm ">{props.bid.title}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                        Agency:&nbsp;&nbsp;
                        <p className="pl-0 font-light text-gray-800 text-sm break-normal">{props.bid.agency}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        State: <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.state}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        Open Date: <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.open_date}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        Close Date: <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.close_date.slice(-4) !== "2099" ? props.bid.close_date : "TBD"}</p>
                      </span>
                      <span className="pl-2 ml-2 font-semibold text-gray-800 text-sm text-wrap">
                        Description:{" "}
                        <Spoiler
                          maxHeight={40}
                          showLabel="Show more"
                          hideLabel="Hide"
                          className={`text-gray-800 text-sm flex-column ${
                            props.bid.description_use_newlines ? "whitespace-pre-line leading-none" : "whitespace-normal"
                          } mr-3 git p-2 ml-2 font-light justify-center text-wrap`}
                        >
                          {props.bid.description}
                        </Spoiler>
                      </span>
                      <span className="inline-flex mx-3 pt-2 pl-2 pb-6">
                        <div className={props.bid.ai_enhanced ? "visible" : "hidden"}>
                          <Tooltip
                            label="Some data for this bid has been extracted using AI"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <AiIcon size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={props.bid.login_required ? "visible" : "hidden"}>
                          <Tooltip
                            label="This bid requires an additional login"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <HiOutlineLockClosed size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={props.bid.payment_required ? "visible" : "hidden"}>
                          <Tooltip
                            label="This bid requires additional payment"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={new Date(props.bid.open_date) > new Date() ? "visible" : "hidden"}>
                          <Tooltip label="This is a forecasted opportunity" withArrow multiline position="bottom" width={215} openDelay={400} transition="pop" transitionDuration={200} color="bb-blue">
                            <p className="">
                              <TbCalendarTime size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                      </span>
                      <Modal
                        centered
                        overlayColor="gray"
                        overlayOpacity={0.55}
                        overlayBlur={3}
                        size="lg"
                        opened={modalOpened}
                        onClose={() => setModalOpened(false)}
                        onSubmit={() => setModalOpened(false)}
                      >
                        <KickoffForm title={props.bid.title} url={props.bid.url} />
                      </Modal>

                      {/* Delete, Link to bid and CTA button */}
                      <div className="flex justify-center mx-2">
                        <div className="flex mx-2">
                          <Button
                            variant="default"
                            onClick={function (e) {
                              removeOnClick(props.objectID, props.bid.title);
                              e.stopPropagation();
                            }}
                            size="md"
                            radius="xl"
                            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                          >
                            <BsTrash3Fill size={18} className="text-red-400" />
                          </Button>
                        </div>
                        <div className="flex mx-2">
                          <Button
                            variant="default"
                            size="md"
                            radius="xl"
                            className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                          >
                            <Anchor href={props.bid.url} target="_blank" className="text-gray-600 text-sm font-normal hover:text-gray-600 no-underline hover:no-underline">
                              Link to bid
                            </Anchor>
                          </Button>
                        </div>
                        <div className="flex justify-center mx-2">
                          {/* kickoff button */}
                          <Button
                            onClick={() => kickoffBidOnClick()}
                            variant="outline"
                            size="md"
                            radius="xl"
                            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] text-sm transition-all font-normal ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black "
                          >
                            Get started!
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    );
  };

  const FavoriteCard = (props) => {
    // Onclick callback function for calling modifyUserFavorite
    const removeOnClick = (objectID, title) => {
      showNotification({
        title: "Favorite removed",
        icon: <IconX size={18} />,
        autoClose: 3000,
        message: `Removed "${title}" from favorites!`,
        color: "red",
      });
      return new Promise((resolve, reject) => resolve(modifyUserFavorite(objectID)));
    };

    const kickoffBidOnClick = () => {
      if (!AuthContext.user) {
        showNotification({
          title: "You must be logged in to kick off a bid!",
          autoClose: 3000,
          color: "red",
        });
      } else {
        setModalOpened(true);
      }
    };

    // is close date classified as TBD
    const isTBD = props.bid.close_date.slice(-4) == "2099";

    return (
      <>
        <div id="IndividualBidContent" className="hidden sm:flex relative basis-3/5 bg-white sm:border-1">
          <div className="flex grow-0 basis-full h-fit">
            {/* <div>later For mobile</div> */}
            <article id="scrollable active content area" className="min-w-full">
              <div>
                {/* !! when content is scrolled header areas stays fixed */}
                <div id="StickyHeader" className="min-w-full relative my-auto">
                  <div className="max-w-3/5 min-w-full">
                    <div id="BidDataViewsHeader">
                      <div className="inline-flex flex-nowrap w-full flex-row justify-between p-1">
                        <div id="LeftSideOfStickyHeader" className="flex-col w-full">
                          {/* Rows on left side of sticky header: Title, State, Due date, Icon */}
                          <div id="Title" className="h-auto w-full flex-col justify-center py-3 text-base font-bold">
                            <span className="flex w-full ml-3 font-normal text-sm">
                              {new Date(props.bid.close_date) < closed_cutoff && <p className="pl-3 text-lg text-red-600 font-bold mr-4">CLOSED</p>}
                              <p className="ml-0 text-lg font-semibold text-gray-800 pr-4">{props.bid.title}</p>
                              <div className="flex ml-auto mr-4">
                                <BananaOn className="h-8 w-8" />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="DetailedDescrition" className="flex-1 relative break-all min-w-full ">
                  <div className="bg-white pb-16">
                    <div id="BidDetails" className="border-t-1 p-4 min-w-full flex-wrap ">
                      <div id="JobDescriptionContent">
                        {/* Algolia Description */}
                        {/* Title, Agency, State, Open Date, Close Date   */}
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                          Title:&nbsp;&nbsp;
                          <p className="pl-0 font-light text-gray-800 text-sm ">{props.bid.title}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                          Agency:&nbsp;&nbsp;
                          <p className="pl-0 font-light text-gray-800 text-sm break-normal">{props.bid.agency}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          State: <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.state}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          Open Date: <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.open_date}</p>
                        </span>
                        <span className="flex p-2 ml-2 text-gray-800 text-sm">
                          <div className="flex font-semibold">
                            Close Date: <p className="pl-2 font-light text-gray-800 text-sm">{isTBD ? "TBD " : props.bid.close_date}</p>
                          </div>
                          <div>
                            {isTBD && (
                              <Tooltip
                                label="The close date is either continuous or unknown at this time."
                                allowPointerEvents
                                withArrow
                                multiline
                                position="right"
                                width={240}
                                openDelay={100}
                                transition="pop"
                                transitionDuration={200}
                                color="bb-blue"
                              >
                                <p className="ml-2">
                                  <HiQuestionMarkCircle size={20} className="text-gray-400" />
                                </p>
                              </Tooltip>
                            )}
                          </div>
                        </span>
                        <span className="pl-2 ml-2 font-semibold text-gray-800 text-sm text-wrap">
                          Description:{" "}
                          <Spoiler
                            maxHeight={40}
                            showLabel="Show more"
                            hideLabel="Hide"
                            className={`text-gray-800 text-sm flex-column ${
                              props.bid.description_use_newlines ? "whitespace-pre-line leading-none" : "whitespace-normal"
                            } mr-3 git p-2 ml-2 font-light justify-center text-wrap`}
                          >
                            {props.bid.description}
                          </Spoiler>
                        </span>
                        <span className="inline-flex mx-3 pt-2 pl-2 pb-6">
                          <div className={props.bid.ai_enhanced ? "visible" : "hidden"}>
                            <Tooltip
                              label="Some data for this bid has been extracted using AI"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <AiIcon size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={props.bid.login_required ? "visible" : "hidden"}>
                            <Tooltip
                              label="This bid requires an additional login"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <HiOutlineLockClosed size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={props.bid.payment_required ? "visible" : "hidden"}>
                            <Tooltip
                              label="This bid requires additional payment"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={new Date(props.bid.open_date) > new Date() ? "visible" : "hidden"}>
                            <Tooltip
                              label="This is a forecasted opportunity"
                              withArrow
                              multiline
                              position="bottom"
                              width={215}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="">
                                <TbCalendarTime size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                        </span>
                        <Modal
                          centered
                          overlayColor="gray"
                          overlayOpacity={0.55}
                          overlayBlur={3}
                          size="lg"
                          opened={modalOpened}
                          onClose={() => setModalOpened(false)}
                          onSubmit={() => setModalOpened(false)}
                        >
                          <KickoffForm title={props.bid.title} url={props.bid.url} />
                        </Modal>

                        {/* Link to bid and CTA button */}
                        <div className="flex justify-center mx-2">
                          <div className="flex mr-auto">
                            <Button
                              variant="default"
                              onClick={function (e) {
                                removeOnClick(props.objectID, props.bid.title);
                                e.stopPropagation();
                              }}
                              size="sm"
                              radius="xl"
                              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                            >
                              <BsTrash3Fill size={18} className="text-red-400" />
                            </Button>
                          </div>
                          <Button
                            // component='a'
                            // href={props.bid.url}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            variant="default"
                            size="md"
                            radius="xl"
                            className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                          >
                            <Anchor href={props.bid.url} target="_blank" className="text-gray-600 font-normal text-sm hover:text-gray-600 no-underline hover:no-underline">
                              Link to bid
                            </Anchor>
                          </Button>
                          <div className="flex justify-center mx-2">
                            {/* kickoff button */}
                            <Button
                              onClick={() => kickoffBidOnClick()}
                              variant="outline"
                              size="md"
                              radius="xl"
                              className="hover:shadow-[0_5px_0px_rgba(0,0,0)]  transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black "
                            >
                              Get started!
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </>
    );
  };

  // If the loading state is set to true, display a loading wheel instead of attempting to
  // populatie the bids.
  let pageContent;
  let favoriteTabs;
  let favoriteContent;
  if (AuthContext.loading || selectedBid == null) {
    return (
      <div id="wrapper" className="h-fit flex-grow flex-col w-full relative justify-center">
        <FavoritesSkeleton />
      </div>
    );
  }

  // Display content to the user depending on if they have any favorites saved currently
  if (favorites != null) {
    if (Object.keys(favorites).length == 0 || selectedBid == 0) {
      pageContent = (
        <div id="PageContent" className="h-screen relative z-10 max-w-7xl w-full margin-auto">
          <div id="PageBodyContents">
            <div className="flex-col align items-center mb-4 overflow-hidden py-20 px-5">
              <span>
                {/* svg can go here */}
                <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">You haven't saved any bids</h3>
                <p className="mb-6 text-center">Visit the search page to add favorites by clicking the banana icon and then come back here to review them!</p>
                <div className="flex justify-center m-2">
                  <Link to="/search">
                    <Button
                      variant="default"
                      size="md"
                      radius="xl"
                      className="flex w-fit  hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
                    >
                      Back to Search
                    </Button>
                  </Link>
                </div>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      // This map function iterates through all of the currently favorited bids and renders
      // a 'FavoriteTab' componenent for each individual bid which is displayed on the left side of the page
      favoriteTabs = Object.keys(favorites).map((key, index) => <FavoriteTab bid={favorites[key]} objectID={key} key={key} />);

      // This component displays the currently selected bid on the right side of the page
      favoriteContent = <FavoriteCard bid={favorites[selectedBid]} objectID={selectedBid} title={favorites[selectedBid].title} />;

      pageContent = (
        <div id="PageContent" className="h-full relative z-10 max-w-7xl w-full margin-auto">
          <div id="PageBodyContents">
            <div className="flex justify-start m-1">
              <Modal
                centered
                size="auto"
                transition="pop"
                overlayColor="gray"
                overlayOpacity={0.55}
                overlayBlur={3}
                // size='lg'
                opened={clearFavoritesOpened}
                onClose={() => setClearFavoritesOpened(false)}
              >
                <div className="flex justify-center mb-8">
                  <span>
                    <p>Are you sure you want to clear all of your favorites?</p>
                  </span>
                </div>
                <div className="flex justify-center mx-2  mb-8 ">
                  <span className="mx-2">
                    <Button
                      variant="default"
                      size="md"
                      radius="xl"
                      onClick={() => setClearFavoritesOpened(false)}
                      className="flex w-fit px-3 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
                    >
                      No, keep my favorites
                    </Button>
                  </span>
                  <span className="mx-2">
                    <Button
                      variant="default"
                      size="md"
                      radius="xl"
                      onClick={function (e) {
                        //clearFavoritesCallback();
                      }}
                      className="flex px-3 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 bg-green-300 hover:bg-green-300 border-black duration-900 text-gray-600 font-normal"
                    >
                      Yes, clear them all
                    </Button>
                  </span>
                </div>
              </Modal>
            </div>

            <div className="h-full">
              <div className="h-full flex sm:mb-12">
                <article id="MainColumn" className="w-full sm:flex-row mr-2 h-[34rem] border-1 bg-white border-t-1 sm:basis-2/5 sm:overflow-auto pb-12 transition-all ease-in-out duration-300">
                  {favoriteTabs}
                  <div id="FooterOrPagination" className="m-4 sm:hidden">
                    <br />
                    <br />
                  </div>
                </article>
                {favoriteContent}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div id="Favorites" className="h-fit flex-grow flex-col w-full relative justify-center ">
      <BananaBanner title1="Favorite" title2="fruits of your labor" />
      {/* <PageHeader title='Your favorite fruits' secondLine='Ripe and ready' className='pb-2'/> */}
      {/* fine tune justify-center side margins too big on smaller windows */}
      <div id="FavoritesPageWrapper" className="h-fit flex flex-grow m-auto w-full relative justify-center">
        {pageContent}
      </div>
    </div>
  );
};

export default Favorites;
