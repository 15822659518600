import { List } from "@mantine/core";
import React from "react";
import { BananaBanner } from "../components";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const CookiePolicy = () => {
  useAbleCDPTracking()


  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <BananaBanner title1="Cookie" title2="Policy" />
      <div className="flex justify-center">
        <div className="flex justify-center max-w-sm  mx-5 sm:max-w-4xl">
          <main className="text-justify flex-col justify-center">
            <h1>Bid Banana Cookie Policy </h1>
            <br />
            <section>
              This Cookie Policy describes the types of cookies and similar
              tracking technologies (collectively, “Cookies”) used by Bid Lab
              Technologies LLC, dba Bid Banana (“we”, “our”, or “us”) on: (i)
              bidbanana.thebidlab.com, its subdomains, and any other website
              where Bid Banana’s Terms of Service are posted; (ii) Bid Banana’s
              online hosted services; and (iii) Bid Banana’s “Software,”
              meaning, collectively, our browser extensions, mobile
              applications, other downloadable applications, application
              programming interfaces (“APIs”), and tools and documentation ((i)
              through (iii) collectively, our “Services” or “Bid Banana”).
            </section>

            <br />
            <h2 className="font-semibold">
              1. What are Cookies and why does Bid Banana use them?
            </h2>
            <section>
              <span>
                Cookies are small text files that are stored by your web browser
                when you visit certain websites or mobile properties. We use
                cookies in our Service for the purpose of facilitating your use
                of our Service, customizing your browsing experience, tracking
                usage trends and patterns, and delivering targeted advertising.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">
              2. What types of Cookies does Bid Banana use?
            </h2>
            <section>
              <span>
                Our Services uses “first-party Cookies” (i.e., Cookies served by
                us) and “third-party Cookies” (i.e., Cookies served by third
                parties that we work with). Cookies are unique to your account
                or your browser. Session-based Cookies last only while your
                browser is open and are automatically deleted when you close
                your browser. Persistent Cookies last until you or your browser
                deletes them or until they expire.
                <br />
                Our uses of Cookies fall into the following categories:
              </span>
            </section>

            <br />

            <section>
              <span>
                <List withPadding listStyleType="disc">
                  <List.Item>
                    Strictly-Necessary. We use Cookies that allow you access to
                    our Service; are required to identify irregular site
                    behavior, prevent fraudulent activity, and improve security;
                    and allow you to make use of our facility;
                  </List.Item>
                  <List.Item>
                    b. BidBanana makes no warranty that (i) the service will
                    meet your requirements, (ii) the service will be
                    uninterrupted, timely, secure, or error-free, (iii) the
                    results that may be obtained from the use of the service
                    will be accurate or reliable, (iv) the quality of any
                    products, services, information, or other material purchased
                    or obtained by you through the service will meet your
                    expectations, (v) any errors in the software will be
                    corrected, (vi) or that this web site, its content, and the
                    servers on which the web site and content are available are
                    free of viruses or other harmful components.
                  </List.Item>
                  <List.Item>
                    Functionality-Related. We may use Cookies that allow us to
                    offer you enhanced functionality when accessing or using our
                    Service, including, for example, identifying you when you
                    sign into our Service or keeping track of your specified
                    preferences, interests, or past items viewed;
                  </List.Item>
                  <List.Item>
                    Performance-Related. We may use Cookies to assess the
                    performance of our Service and, as part of our analytic
                    practices, to help us understand how you use the Service;
                  </List.Item>
                  <List.Item>
                    Advertising- or Targeting-Related. We may use first-party or
                    third-party Cookies to deliver content, including
                    advertising, relevant to your interests, on our Service or
                    on third-party sites.
                  </List.Item>
                </List>
              </span>
            </section>
            <br />
            <h2 className="font-semibold">
              3. Does Bid Banana use Cookies for interest-based advertising?
            </h2>
            <section>
              <span>
                Yes. We work with third-party partners like online advertising
                networks, social media companies, and other third-party services
                that use Cookies, or other information in connection with use of
                our Service, in order to display personalized or interest-based
                advertising on the Service, on other websites, through apps or
                services you may use, and on other devices or browsers you may
                use. We and our third-party partners use this information to
                make the advertisements you see online more relevant to your
                interests, as well as to provide advertising-related services
                such as reporting, attribution, analytics, service optimization,
                and market research. These Cookies typically contain a
                pseudonymous cookie ID and collect device metadata, such as IP
                address and user agent string, and actions on the Service to
                assist with these advertising and related services. Please note
                that the information we share with those advertising networks
                might be combined with other information about you that those
                networks may have collected from us or other sources.
              </span>
            </section>

            <br />

            <br />
            <h2 className="font-semibold">4. How do I manage Cookies?</h2>
            <section>
              <span>
                a. How do I manage Cookies used by Bid Banana? <br />
                Depending on your region, a pop-up window may appear the first
                time you visit our Service, informing you that our Service uses
                Cookies, and allowing you to set your cookie preferences for
                your device. Please be aware that this does not apply to
                strictly-necessary Cookies. Even if the pop-up window does not
                appear, you may indicate or change certain preferences.
              </span>
              <br />
              <br />
              <span>
                <span>
                  b. How do I opt out of Google data collection?
                  <br />
                  We also may use certain forms of display advertising and other
                  advanced features through Google Universal Analytics, such as
                  Remarketing with Google Analytics, Google Display Network
                  Impression Reporting, the DoubleClick Campaign Manager
                  Integration, and Google Analytics Demographics and Interest
                  Reporting. These features enable us to use first-party Cookies
                  (such as the Google Analytics cookie) and third-party Cookies
                  (such as the Doubleclick advertising cookies) or other
                  third-party Cookies together to inform, optimize, and display
                  ads based on your past visit to the Service.
                </span>
              </span>
            </section>
            <List withPadding listStyleType="disc">
              <List.Item>
                You may opt out of certain Google advertising products by
                visiting the Google Ads Preferences Manager, currently available
                at google.com/ads/preferences.
              </List.Item>
              <List.Item>
                You can view Google’s currently available Google Analytics
                opt-out options as tools.google.com/dlpage/gaoptout.
              </List.Item>
              <List.Item>
                You may learn more about Google’s practices with Google
                Analytics by visiting Google’s privacy policy, currently
                available at google.com/policies/privacy/partners.
              </List.Item>
            </List>
            <br />
            <span>
              c. How do I opt out via self-regulatory programs?
              <br />
              You can opt-out of advertising companies serving interest-based
              advertising to you by using the self-regulatory program opt-outs
              below (to the extent those companies participate in such industry
              opt-out pages).<br/> <br/> Opting out via the above mechanisms does not mean
              you will no longer receive online advertising. It means that the
              online advertising that you do see from these self-regulatory
              program participants should not be based on your interests. We are
              not responsible for the effectiveness of, or compliance with, any
              third-party opt-out options or programs, or the accuracy of their
              statements regarding those options or programs. In addition,
              third-parties may still use cookies or other tracking technologies
              to collect information about your use of our online services,
              including for analytics and fraud prevention as well as any other
              purpose permitted under those self-regulatory programs.
            </span>
            <br />
            <br />
            <br />
            <br />
          </main>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
