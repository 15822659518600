import { Skeleton, Card } from "@mantine/core";
import React from "react";

const BidCardSkeleton = () => {
  return (
    <div id="Container" className="flex justify-center items-center">
    <div id="main container" className="px-2 sm:max-w-4xl w-full ">
    <Card className="my-5 w-full mx-auto sm:w-full mb-4">
      <Skeleton height={6} radius="xl" />
      <Skeleton height={6} mt={6} radius="xl" />
      <Skeleton height={6} mt={6} width="70%" radius="xl" />
      <Skeleton height={6} mt={6} width="50%" radius="xl" />
      <Skeleton height={6} mt={6} width="80%" radius="xl" />
      <div className="flex justify-end items-center mt-4 pb-2">
        <Skeleton height={35} width={100} radius="xl" />
        <Skeleton height={35} width={100} ml={10} radius="xl" />
      </div>
    </Card>
    </div>
    </div>
  );
};

export default BidCardSkeleton;
