import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { hotjar } from 'react-hotjar';

const ReactHotJar = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      hotjar.initialize(3436261, 6);
      // console.log('hotjar tracking on');
    } else {
      // console.log('hotjar tracking off');
    }
  }, [location.pathname]);
  
  return null;
}

export default ReactHotJar;