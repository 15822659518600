import React from 'react'
import { Avatar, Button } from '@mantine/core'
import { BsMicrosoft } from 'react-icons/bs'
import { useUserAuth } from '../../contexts/UserAuthContext.js'
import logo from '../../data/Microsoft_logo.svg'
import { useNavigate } from "react-router-dom";

const MicrosoftButton = ({text}) => {

  const { logInWithMicrosoft } = useUserAuth();
  const navigate = useNavigate();

  async function handleMicrosoftLogin() {
    try {
      await logInWithMicrosoft()
    } catch {
      console.log("Log in with Mircosoft failed")
    }
  }

  return (
      <Button
      variant='default'
      size='sm'
      radius='xl'
      leftIcon={<Avatar src={logo} size={21}/>}
      onClick={handleMicrosoftLogin}

      className="mx-0 sm:justify-center sm:flex sm:w-fit text-xs sm:text-base sm:py-2 sm:items-center  hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
    >
      {text}
    </Button>
  )
}

export default MicrosoftButton