import React, { useState } from "react";
import { Loader, Button, Text, Card, Group } from "@mantine/core";
import { useUserAuth } from "../contexts/UserAuthContext";
import { BananaBanner, PleaseLogin } from "../components";
import { Link, NavLink } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import customerPortal from "../stripe/functions/CustomerPortal";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Billing = () => {
  useAbleCDPTracking();
  const [loading, setLoading] = useState(false);

  const { user } = useUserAuth();

  const titleText = user
    ? "Hi, " + user.displayName + "!"
    : "Welcome to Bid Banana";

  let userImg;

  if (user) {
    if (user.photoURL != null) {
      userImg = (
        <img
          className="h-12 rounded-full cursor-pointer inline-flex align-baseline"
          src={user.photoURL}
          height={48}
          width={48}
          alt="User Account"
          referrerPolicy="no-referrer"
        />
      );
    } else {
      userImg = <FaUserCheck size={22} />;
    }
  }

  // Render loader if loading
  if (loading) {
    return (
      <div
        id="wrapper"
        className="h-fit flex-grow flex-col w-full relative justify-center"
      >
        <Loader
          variant="dots"
          color="bb-light-green.0"
          size="xl"
          className="flex m-auto items-center justify-center h-screen"
        />
      </div>
    );
  }

  if (!user) {
    return (
      <div
        id="Favorites"
        className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
      >
        <div
          id="wrapper"
          className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
        >
          <BananaBanner title1="Oops!" title2="Please login" />
          <section>
            <PleaseLogin
              text="Settings page is only available to Bid Banana Users"
              description="Please login to view settings or create an account!"
            />
          </section>
          <div
            id="buttons wrapper"
            className="flex text-center justify-center sm:mt-12 sm:mb-5 "
          >
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link
                to="/login"
                className="text-bb-light-green font-semibold mx-8 align-baseline"
              >
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <BananaBanner/> */}
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <div id="inner-wrapper" className="sm:px-8 sm:pt-8 sm:pb-10">
          <section id="content" role="main">
            <div
              id="containedData"
              className=" py-4 sm:max-w-4xl sm:py-8 overflow-auto relative sm:m-auto"
            >
              {/* Header Bar  */}
              <div className="block w-full  my-4 sm:mb-8 pl-7 sm:pl-0">
                <Group>
                  <div id="photoURL">{userImg}</div>
                  <div style={{ flex: 1 }}>
                    <Text
                      size="sm"
                      weight={500}
                      className="text-xl text-black font-semibold"
                    >
                      {titleText}
                    </Text>
                    <Text color="dimmed" size="md">
                      Manage your subscription and other billing settings
                    </Text>
                  </div>
                </Group>
              </div>
              {/* Mobile menu */}
              <div className="flex justify-center sm:hidden"></div>
              {/* Side bar  */}
              <div
                id="sideBar"
                className=" pl-2 sm:block sm:text-lg sm:float-left sm:w-23/100"
              >
                <ul className="mb-5">
                  {/* Side Links */}

                  <NavLink to="/settings">
                    <li className="pl-1 text-lg font-medium">My Profile</li>
                  </NavLink>

                  <NavLink to="/savedsearches">
                    <li className="pl-1 text-lg font-medium">Saved Searches</li>
                  </NavLink>

                  <NavLink to="/billing">
                    <li className="pl-1 text-lg font-medium">
                      <strong>Billing</strong>
                    </li>
                  </NavLink>
                </ul>
              </div>
              <div id="main container" className="px-2 sm:float-right sm:w-4/5">
                <div>
                  <Card withBorder radius="md" className="sm:w-4/5 my-2 py-6">
                    <Button
                      variant="outline"
                      type="button"
                      size="sm"
                      radius="lg"
                      onClick={async () => {
                        setLoading(true);
                        await customerPortal();
                      }}
                      className="space-between mr-5 hover:shadow-[0_5px_0px_rgba(0,0,0)] transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-bb-yellow border-1 border-black hover:bg-bb-yellow hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
                    >
                      Manage Bid Banana Subscription
                    </Button>
                  </Card>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Billing;
