import React from "react";

const AiIcon = ({ size = 18, color = "black" }) => {
  const circleStyle = { fill: "none", stroke: "rgb(156,163,175)", strokeWidth: "2px" };
  const pathStyle = { fill: "rgb(156,163,175)", fillRule: "nonzero" };
  const pathStyleWithStroke = { fill: "rgb(156,163,175)", fillRule: "nonzero", stroke: "rgb(156,163,175)", strokeWidth: "2px" };

  return (
    <svg
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.982" cy="11.625" r="5.982" style={circleStyle} />
      <path
        d="M17.544,23.109C17.934,23.5 18.567,23.5 18.958,23.109C19.348,22.719 19.348,22.086 18.958,21.695L17.544,23.109ZM11.794,17.36L17.544,23.109L18.958,21.695L13.208,15.945L11.794,17.36Z"
        style={pathStyle}
      />
      <path
        d="M19.833,5.894L19.696,5.57L19.559,5.894C19.433,6.194 19.194,6.433 18.894,6.559L18.57,6.696L18.894,6.833C19.194,6.96 19.433,7.199 19.559,7.498L19.696,7.823L19.833,7.498C19.96,7.199 20.199,6.96 20.498,6.833L20.823,6.696L20.498,6.559C20.199,6.433 19.96,6.194 19.833,5.894Z"
        style={pathStyleWithStroke}
      />
      <path
        d="M15.374,0.556L14.764,2L13.321,2.61L14.764,3.22L15.374,4.663L15.984,3.22L17.428,2.61L15.984,2L15.374,0.556Z"
        style={pathStyle}
      />
    </svg>
  );
};

export default AiIcon;
