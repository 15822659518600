import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Space, TextInput, Image } from "@mantine/core";
import Bidlabgroup from "../../data/bidlabgroup.png";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification} from "@mantine/notifications";
import { AiOutlineCheck } from "react-icons/ai";

const KickoffBidFinder = ({ url, title, closeModal }) => {

	const form = useForm({
		initialValues: {
			organizationName: "",
			title: title,
			url: url,
		},
	});

	const handleFormSubmit = async (data) => {
		try {
			if (data.title && data.url && data.organizationName) {
				const doc = await addDoc(collection(db, "mail"), {
					to: "maurice@thebidlab.com, amandam@thebidlab.com, brittany@thebidlab.com, amanda@thebidlab.com, respond@thebidlab.com, jacob@thebidlab.com",
					message: {
						subject: "Bid Finder: Kick off",
						html: `${data.organizationName} would like to kick off "${title}" from their Bid Finder report.`,
					},
				});
				showSuccessNotification();
				closeModal();
			} else {
				alert("field cannot be left empty");
				showErrorNotification();
			}
		} catch (e) {
			console.log(e);
			showErrorNotification();
		}
	};

	useEffect(() => {
		form.setValues({
			title: title,
			url: url,
		});
	}, []);

	const showSuccessNotification = () => {
		showNotification({
			title: "Submission Successful",
			message: "Your Kick off Bid has been successfully submitted.",
			color: "green",
			icon: <AiOutlineCheck />,
		});
	};

	const showErrorNotification = () => {
		showNotification({
			title: "Submission Error",
			message: "There was an error while submitting your Kick off Bid.",
			color: "red",
			icon: <AiOutlineCheck />,
		});
	};

	return (
		<div className="m-x-xs m-3">
			<form id="kickoff-form" onSubmit={form.onSubmit(handleFormSubmit)}>
				<section className="m-2 p-2 flex justify-center  border-1 rounded-xl bg-bb-yellow">
					<Image src={Bidlabgroup} position="centered" fit="contain" width={200} height={200} alt="Bid lab folks" />
				</section>
				<section className="m-2 p-2  ">
					<article className="px-2">
						<div className="flex justify-center">
							<h1 className="font-medium">Ready to get started?</h1>
						</div>
						<Space h="sm" />
						<div className="text-justify">
							Interested in kicking off this bid or learning more about it? Please confirm the following fields, and a member of our
							team will contact you shortly. Thanks for your interest, and we look forward to working with you.
						</div>
						<Space h="md" />
					</article>
					<article className="px-2">
						<TextInput
							mt="md"
							mb="md"
							required
							label="Your Company/Organization Name"
							placeholder="Your Company/Organization Name"
							{...form.getInputProps("organizationName")}
						/>
					</article>
					<article className="px-2">
						<TextInput mt="sm" mb="md" label="RFP Title" disabled placeholder="RFP Title" {...form.getInputProps("title")} />
						<TextInput mt="sm" mb="md" label="Source URL" disabled placeholder="URL" {...form.getInputProps("url")} />
					</article>
				</section>
				<section className="m-2 p-1 flex justify-end mb-3">
					<Button
						variant="outline"
						type="submit"
						size="md"
						radius="xl"
						className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green  border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
					>
						Submit
					</Button>
				</section>
			</form>
		</div>
	);
};

export default KickoffBidFinder;