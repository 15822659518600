import React, { useEffect } from "react";
import {
  Button,
  Text,
  Card,
  Group,
  ActionIcon,
  Modal,
  TextInput,
} from "@mantine/core";
import { BsTrash3 } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import { useUserAuth } from "../contexts/UserAuthContext";
import { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { InstantSearch } from "react-instantsearch-dom";
import { Panel } from "./Panel";

const SavedSearchesCard = ({
  search,
  index,
  deleteSavedSearch,
  setSavedSearches,
}) => {
  const bids_index = process.env.REACT_APP_PRIMARY_BIDS_INDEX;
  const navigate = useNavigate();
  const AuthContext = useUserAuth();
  const user = AuthContext.user;
  const [modalOpen, setModalOpen] = useState(false);
  const [editedName, setEditedName] = useState(search.name);


  const runSearchOnClick = async () => {
    try {
      // console.log("This should run Algolia search using search.query");

      navigate(search.route);
    } catch (error) {
      console.error("Error navigating to route:", error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setEditedName(search.name);
    }
  };
  const CustomModalTitle = () => {
    return <h2 className="text-center text-lg font-bold">Edit Saved Search</h2>;
  };

  const handleSave = async () => {
    try {
      if (editedName.trim() === "") {
        throw new Error("Search name is required");
      }
      //Get a reference to the document containing the saved searches for the current user
      const userSavedSearchRef = doc(db, "saved_searches", user.uid);
      // Fetch DocumentSnapshot from the database
      const docSnapshot = await getDoc(userSavedSearchRef);
      //Check if the document exists
      if (docSnapshot.exists()) {
        //Retrieve the data from the document
        const searchData = docSnapshot.data();
        //Get the array of saves searcges from the document data
        const searches = searchData.savedSearches || [];

        // Update the name of the selected saved search at the specified index
        searches[index].name = editedName;

        //update the document in the database with the modified saved
        await setDoc(userSavedSearchRef, { savedSearches: searches });

        // Update the local state with the modified saved searches
        setSavedSearches(searches);
      }
      toggleModal();
    } catch (error) {
      console.log("Error updating name in Firebase:", error);
    }
  };

  return (
    <InstantSearch
      searchClient={algoliasearch("U3KGE4YPBJ", user.searchKey)}
      indexName={bids_index}
    >
      <Card
        withBorder
        radius="md"
        className="sm:w-4/5 mb-4 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-white hover:border-black duration-900"
        key={index}
      >
        <div
          id="run-search-onclick"
          className="cursor-pointer"
          onClick={runSearchOnClick}
        >
          <div className="flex items-start">
            
          <div className="max-w-lg">
              <div className="font-bold hover:underline pb-1 ">
                <Text>{search.name}</Text>
              </div>
              {/* <div className="text-sm pb-4 mr-2">
              <Text>{search.query}</Text>
            </div> */}
              <div className="text-md font-medium pb-4 mr-2">
                <p>Search Query:&nbsp;&nbsp;"{JSON.parse(search.query).query}"</p>
                <div className="flex">
                  <p className="mr-0"></p>
                  <div className="flex">
                  Filters:&nbsp;&nbsp;[
                    {JSON.parse(search.query).facetFilters &&
                    JSON.parse(search.query).facetFilters.length > 0 ? (
                      JSON.parse(search.query).facetFilters.map(
                        (filter, index) => (
                          <p key={index} className="capitalize">
                            {filter.map((f, i) => (
                              <span key={i}>
                                {f}
                                {i !== filter.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </p>
                        )
                      )
                    ) : (
                      <p>None</p>
                    )}
                  ]</div>
                </div>
              </div>
            </div>
            <div className="flex justify-items-end flex-grow">
            <div className="ml-auto">
              <AiOutlineSearch size="1.2rem" />
            </div>
            </div>
          </div>
        </div>
        {/* <Text>{JSON.stringify(search.query)}</Text> */}
        <Card.Section>
          <Button.Group position="apart">
            <Button
              variant="default"
              className="flex w-full justify-center rounded-t-none border-t-1 border-r-0 border-l-0 border-b-0 "
              leftIcon={<MdOutlineEdit size="1.1rem" />}
              onClick={toggleModal}
            >
              EDIT NAME
            </Button>
            <Button
              variant="default"
              className="flex w-full justify-center rounded-t-none border-r-0 border-l-0 border-b-0 "
              leftIcon={<BsTrash3 />}
              onClick={() => deleteSavedSearch(index)}
            >
              DELETE
            </Button>
          </Button.Group>
        </Card.Section>
      </Card>

      <Modal
        overlayColor="gray"
        overlayOpacity={0.55}
        overlayBlur={3}
        title={<CustomModalTitle />}
        opened={modalOpen}
        onClose={toggleModal}
        size="sm"
        centered
        shadow="md"
      >
        <TextInput
          value={editedName}
          required
          onChange={(event) => setEditedName(event.currentTarget.value)}
          placeholder="Enter new search name"
        />

        <div className="flex justify-center m-4 pt-3">
          <Button variant="default" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Modal>
    </InstantSearch>
  );
};

export default SavedSearchesCard;
