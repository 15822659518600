import { useInstantSearch } from 'react-instantsearch-hooks-web'

export const EmptyQueryBoundary = ({ children, fallback, user }) => {

    const { indexUiState } = useInstantSearch();

    if (!user.admin && (!indexUiState.query || indexUiState.query.length < 3)) {
        return fallback;
    }

    return children;
}

