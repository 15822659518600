import { ActionIcon, Button, Menu } from "@mantine/core";
import React, { useReducer, useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { RxDotsVertical } from "react-icons/rx";
import { IoMapOutline, IoTrailSignOutline } from "react-icons/io5";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { db } from "../Firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { async } from "@firebase/util";

// Tour steps
const TOUR_STEPS = [
  {
    target: ".tour-welcome",
    title: "Welcome to Bid Banana!",
    content: "Type any term into the search bar to view bid results.",
    disableBeacon: false, // This makes the tour to start automatically without clicking
    placement: "top",
    disableOverlayClose: true,
  },
  {
    target: ".tour-card",
    title: "Bid",
    content: "These are your search results cards.",
    disableBeacon: false,
    placement: "top",
    disableOverlayClose: true,
  },
  {
    target: ".tour-description",
    title: "Description",
    content: "View more description details by clicking 'Show more'.",
    disableBeacon: true,
    placement: "top",
    disableOverlayClose: true,
    spotlightClicks: true,
  },
  {
    target: ".tour-favorite",
    title: "Favorites",
    content: "Save bids to your favorites collection by clicking the banana.",
    disableOverlayClose: true,
    spotlightClicks: true,
  },
  {
    target: ".tour-link",
    title: "Link to bid",
    content: "The bid's source information can be found here!",
    placement: "top",
    disableOverlayClose: true,
    spotlightClicks: true,
  },
  {
    target: ".tour-getStarted",
    title: "Get started",
    content:
      "Ready to get started with this bid?! Click here to get in touch with a representative from The Bid Lab.",
    placement: "top",
    disableOverlayClose: true,
  },
  {
    target: ".tour-stateFilters",
    title: "Filters",
    content:
      "Select the states you want to include in your search with these filters. You can also hide results that require additional steps by selecting 'Hide Login Required'.",
    placement: "top",
    spotlightClicks: true,
  },
  {
    target: ".tour-additionalFilters",
    title: "Additional filters",
    content:
      "Select 'Relevance' to find results most relevant to your search term, 'Recently Added' for the most recent bids posted, and 'Closing Soon' to see which bids are about to close.",
    placement: "top",
    disableOverlayClose: true,
    spotlightClicks: true,
  },
   {
    target: ".tour-savesearch",
    title: "Save Search",
    content:
      "Stay in the know with Bid Banana's saved search feature. Get notified when new bids are posted that match your search criteria.",
    placement: "top",
    disableOverlayClose: true,
    spotlightClicks: true,
  },
  // final step of tour here
  {
    target: ".tour-end",
    title: "Thank you for touring Bid Banana!",
    content: "We are always here for you via chat if you need further assistance.",
    disableBeacon: false, // This makes the tour to start automatically without clicking
    placement: "top",
    disableOverlayClose: true,
  },
];

const INITIAL_STATE = {
  key: new Date(), //this field makes the tour re-render when restarted
  run: true,
  continuous: true,
  loading: false,
  scrollDuration: 600,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// reducer will manage updating local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Tour component
const Tour = () => {
  // tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [tourStatus, setTourStatus] = useState("seen");

  const { user } = useUserAuth();

  //Getting user data from firebase
  useEffect(() => {
    checkTourStatus();
  }, []);

  const checkTourStatus = async () => {
    // Find user UID
    const toursRef = doc(db, "users", user.uid);
    // Get reference to the tours ref
    const toursDocSnap = await getDoc(toursRef);
    // grab the data
    const toursDocData = toursDocSnap.data();
    // If tourSeen is undefined or false make statue "not seen", otherwise it has been seen
    if (toursDocData.tourSeen == undefined || toursDocData.tourSeen == false) {
      setTourStatus("not seen");
    } else {
      setTourStatus("seen");
    }
  };

  // is tour is "not seen" start the tour as it hasnt been seen before. Otherwise it has been "seen" and in that case "STOP" which has run set to false
  useEffect(() => {
    if (tourStatus === "not seen") {
      dispatch({ type: "START" });
    } else {
      dispatch({ type: "STOP" });
    }
  }, [tourStatus]);

  // useEffect(() => {
  //   // Auto start the tour if the tour has not been viewed before
  //   if (user) {
  //     dispatch({ type: "START" });
  //     console.log("check!", user);
  //   }
  // }, []);

  //set once tour is viewed, skipped or closed
  // const setTourViewed = () => {
  //   // localStorage.setItem("tour", "1");
  // };

  // Joyride callback: gets state changes by receiving object with current state
  const callback = async (data) => {
    const { action, index, type, status } = data;

    if (
      // if closed button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or tour has ended, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      const toursRef = doc(db, "users", user.uid);
      await updateDoc(toursRef, {
        tourSeen: true,
      });
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button have been clicked and update the stepIndex
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  // start tour manually by switching tourSeen to false
  const startTour = async () => {
    const toursRef = doc(db, "users", user.uid);
    await updateDoc(toursRef, {
      tourSeen: false,
    });
    console.log("clicked");
  };

  return (
    <>
      <div>

 {/* Manual start toggle  */}
        {/* <Menu
          transition="scale-y"
          withArrow={false}
          position="bottom-start"
          withinPortal
          offset={12}
        >
          <Menu.Target>
            <ActionIcon variant="transparent">
              <RxDotsVertical size={20} stroke={1.5} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IoMapOutline size={14} />} onClick={startTour}>
              Start tour
            </Menu.Item>
            <Menu.Item
              icon={<IoTrailSignOutline size={14} />}
              onClick={startTour}
            >
              Start tour
            </Menu.Item>
          </Menu.Dropdown>
        </Menu> */}

        <ReactJoyride
          {...tourState}
          callback={callback}
          showSkipButton={false}
          styles={{
            tooltipContainer: { textAlign: "center" },
            // Tooltip title styling
            tooltipTitle: {
              fontWeight: 600, // set the font weight here
            },
            // Next button styles
            buttonNext: {
              color: "#000",
              fontSize: "16px",
              backgroundColor: "#FEE299",
              borderRadius: 20,
              border: "1px solid #000",
              fontFamily: "Barlow",
              margin: "0px 8px 0px 0px",
              padding: "8px 15px",
            },
            // Back button styles
            buttonBack: {
              color: "#000",
              fontSize: "16px",
              marginLeft: "auto",
              marginRight:  5,
              fontFamily: "Barlow",
            },
            // Skip button styles
            buttonSkip: {
              color: "#000",
              fontSize: "16px",
              marginLeft: "auto",
              marginRight: 5,
              fontFamily: "Barlow",
            },
            // Tooltip
            tooltip: {
              borderRadius: 18,
              // border: 'solid 2px #87eeab',
            },
            // Tooltip arrow
            floater: {
              arrow: {
                color: "#87eeab",
                border: "solid 2px black",
              },
            },
            // Spotlight styles
            spotlight: {
              color: "#000",
              borderRadius: 18,
              border: "solid 2px black",
            },
            // General styling
            options: {
              primaryColor: "#5184B2",
              // arrowColor: '#87eeab',
              textColor: "#000",
              zIndex: 1000,
            },
          }}
          locale={{ last: "End tour" }}
        />
      </div>
    </>
  );
};

export { Tour };
