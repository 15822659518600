import React, { useEffect } from "react";
import { NavigationProgress, setNavigationProgress } from "@mantine/nprogress";

const ReadingProgressBar = () => {

  //Scroll logic
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;       
      const scrollPosition = window.scrollY; 
      const progress = (scrollPosition/totalHeight) * 100; 

      setNavigationProgress(progress)
    }
      // listener for when component is mounted
      window.addEventListener("scroll", handleScroll)

      //Cleanup the listener when the component is unmounted 
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
  }, [])

  return <NavigationProgress  className="bg-[#5184B2] rounded-r-lg"/>
}

export default ReadingProgressBar