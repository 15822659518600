import React, { useState } from "react";
import {
  Greeting,
  UploadBidForm,
  PleaseLogin,
  BananaBanner,
  Paywall,
} from "../components";
import { useUserAuth } from "../contexts/UserAuthContext";
import { useNavigate, Link } from "react-router-dom";
import { Text, Modal, TextInput, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Search = () => {
  
  useAbleCDPTracking();

  const bids_index = process.env.REACT_APP_PRIMARY_BIDS_INDEX;

  // State for upload a bid to bid banana Modal
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState("");

  const { user, data } = useUserAuth();
  const navigate = useNavigate();

  const titleText =
    user && data && data.name
      ? "Hi, " + data.name + "!"
      : "Welcome to Bid Banana";

  const placeholderMobile = useMediaQuery("(min-width: 900px");

  // Redirects user to a results page when they submit the search
  function executeSearch(event) {
    event.preventDefault();
    if (value === "") {
      navigate(`/results`);
    } else {
      navigate(`/results?${bids_index}%5Bquery%5D=${value}`, { state: { query: value } });
    }
  }

  // If the user is not logged in, do not load a favorites page.
  if (!user) {
    return (
      <div
        id="Search"
        className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
      >
        <div
          id="wrapper"
          className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
        >
          <BananaBanner title1="Uh Oh!" title2="Please login" />
          <section>
            <PleaseLogin
              text="Search is only available to Bid Banana users"
              description="Please login to access search or create an account!"
            />
          </section>
          <div
            id="buttons wrapper"
            className="flex text-center justify-center sm:mt-12 sm:mb-5 "
          >
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link
                to="/login"
                className="text-bb-light-green font-semibold mx-8 align-baseline"
              >
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    user.subscriptionStatus == null ||
    user.subscriptionStatus == "inactive" ||
    user.searchKey == null
  ) {
    return (
      <div className="flex justify-center">
        <Paywall
          text="The search feature is only available for paid users"
          body='Click the "checkout" below to purchase your subscription to Bid Banana'
          buttonText="Checkout"
        />
      </div>
    );
  }

  return (
    <div
      id="wrapper"
      className="h-fit flex-grow flex-col w-full relative justify-center "
    >
      <div className="flex flex-col h-full relative basis-1/2 ">
        <div className="mt-10 sm:mt-28">
          <div className=" flex flex-col items-center font-Roboto-Bold pt-3 flex-grow w-full mt-10">
            <Greeting
              title={titleText}
              text="Search and discover a"
              italics="&nbsp;bunch&nbsp; "
              text2="of bids - easily"
              className=""
            />

            <form
              className="flex flex-col w-full relative mt-1 items-center sm:max-w-xl lg:max-w-2xl"
              onSubmit={executeSearch}
            >
              <div className="flex flex-col w-full relative mt-1 items-center sm:max-w-xl lg:max-w-2xl">
                <TextInput
                  id="searchInput"
                  placeholder={
                    placeholderMobile
                      ? 'e.g. "school construction", "software development" or "medical services"'
                      : 'e.g. "school construction"'
                  }
                  classNames={{
                    wrapper:
                      "flex flex-col w-full relative mt-1 items-center sm:max-w-xl lg:max-w-2xl",
                    root: "w-11/12 items-center",
                    input: "hover:shadow-md focus-within:shadow-md",
                  }}
                  onSubmit={executeSearch}
                  size="md"
                  radius="xl"
                  value={value}
                  onChange={(e) => setValue(e.currentTarget.value)}
                />
              </div>
            </form>

            <Modal
              centered
              overlayColor="gray"
              overlayOpacity={0.55}
              overlayBlur={3}
              size="lg"
              opened={opened}
              onClose={() => setOpened(false)}
              onSubmit={() => setOpened(false)}
            >
              {/* Upload Bid Form */}
              <UploadBidForm />
            </Modal>

            <Text
              align="center"
              mt="md"
              className=""
              onClick={() => setOpened(true)}
            >
              <Link
                // to='/register'
                className="font-semibold text-bb-light-green"
              >
                Upload a bid
              </Link>{" "}
              to Bid Banana!
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
