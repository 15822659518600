import React, { useEffect, useState } from "react";
import {
  Loader,
  Button,
  Text,
  Card,
  Group,
  UnstyledButton,
  ActionIcon,
  Modal,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useUserAuth } from "../contexts/UserAuthContext";
import { BananaBanner, PleaseLogin, SavedSearchesCard } from "../components";
import { AiOutlineCheck } from "react-icons/ai";
import { showNotification, updateNotification } from "@mantine/notifications";
import { Link, NavLink } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import customerPortal from "../stripe/functions/CustomerPortal";
import { TbTrash } from "react-icons/tb";
import { getDocs } from "firebase/firestore";
import {
  setDoc,
  doc,
  getDoc,
  collection,
  DocumentSnapshot,
  docSn,
} from "firebase/firestore";
import { db } from "../Firebase";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const SavedSearches = () => {

  const [loading, setLoading] = useState(false);
  // State to hold saves search data
  const [savedSearches, setSavedSearches] = useState([]);
  //confirm delete modal
  const [deleteConfirmationVisible, setDeleteConfirmVisible] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);

  const { user, updateUserAccountData, data } = useUserAuth();
  useAbleCDPTracking()

  // Retrieve the saved searches data from firebase based on the users uid
  useEffect(() => {
    const fetchSavedSearches = async () => {
      if (user) {
        try {
          // Retrieve saved searches
          const userSavedSearchRef = doc(db, "saved_searches", user.uid);
          const docSnapshot = await getDoc(userSavedSearchRef);

          if (docSnapshot.exists()) {
            const searchData = docSnapshot.data();
            const searches = searchData.savedSearches || [];
            setSavedSearches(searches);
            // console.log(searches);
          } else {
            setSavedSearches([]);
          }
        } catch (error) {
          console.error("Error fetching saved searches", error);
        }
      }
    };

    fetchSavedSearches();
  }, [user]);


  // Function to initiate the deletion of a saved search
  const deleteSavedSearch = async (index) => {
    setIndexToDelete(index); // Set the index of the saved search to be deleted. responsible for storing the index of the saved search that is going to be deleted, ensuring that the correct item is removed from the list of saved searches.
    setDeleteConfirmVisible(true); // Show the delete confirmation modal
  };

  // Function to confirm and delete the saved search
  const confirmDelete = async (index) => {
    setDeleteConfirmVisible(true); // Show the delete confirmation modal
    try {
      const userSavedSearchRef = doc(db, "saved_searches", user.uid);
      const docSnapshot = await getDoc(userSavedSearchRef);

      if (docSnapshot.exists()) {
        const searchData = docSnapshot.data();
        const searches = searchData.savedSearches || [];
        searches.splice(index, 1); // Remove the saved search at the given index
        await setDoc(userSavedSearchRef, { savedSearches: searches });
        setSavedSearches(searches);
      }
    } catch (error) {
      console.error("Error deleting saved search", error);
    } finally {
      setDeleteConfirmVisible(false); // Hide the delete confirmation modal
    }
  };

  const dataForm = useForm({
    initialValues: {
      name: data ? data.name : "loading...",
      organizationName: data ? data.organization_name : "loading...",
      phoneNumber: data ? data.phone_number : "loading...",
    },
  });

  const emailForm = useForm({
    initialValues: {
      email: user ? user.email : "loading...",
    },
  });

  // Populates the forms after data has been loaded
  useEffect(() => {
    if (user && data) {
      dataForm.setValues({
        name: data.name,
        organizationName: data.organization_name,
        phoneNumber: data.phone_number,
      });
      emailForm.setValues({ email: user.email });
    } else if (user) {
      dataForm.setValues({ name: "", organizationName: "", phoneNumber: "" });
      emailForm.setValues({ email: user.email });
    }
  }, [user, data]);

  const titleText = user
    ? "Hi, " + user.displayName + "!"
    : "Welcome to Bid Banana";

  let userImg;

  if (user) {
    if (user.photoURL != null) {
      userImg = (
        <img
          className="h-12 rounded-full cursor-pointer inline-flex align-baseline"
          src={user.photoURL}
          height={48}
          width={48}
          alt="User Account"
          referrerPolicy="no-referrer"
        />
      );
    } else {
      userImg = <FaUserCheck size={22} />;
    }
  }

  const submitDataForm = async (name, organizationName, phoneNumber) => {
    showNotification({
      id: "load-data",
      title: "Updating user data...",
      color: "green",
      loading: true,
    });
    await updateUserAccountData(name, organizationName, phoneNumber);
    updateNotification({
      id: "load-data",
      title: "User data updated!",
      color: "green",
      icon: <AiOutlineCheck size={16} />,
      autoClose: 2000,
    });
  };

  // Render loader redirecting to Stripe payment portal
  if (loading) {
    return (
      <div
        id="wrapper"
        className="h-fit flex-grow flex-col w-full relative justify-center"
      >
        <Loader
          variant="dots"
          color="bb-light-green.0"
          size="xl"
          className="flex m-auto items-center justify-center h-screen"
        />
      </div>
    );
  }

  if (!user) {
    return (
      <div
        id="Favorites"
        className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
      >
        <div
          id="wrapper"
          className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
        >
          <BananaBanner title1="Oops!" title2="Please login" />
          <section>
            <PleaseLogin
              text="Settings page is only available to Bid Banana Users"
              description="Please login to view settings or create an account!"
            />
          </section>
          <div
            id="buttons wrapper"
            className="flex text-center justify-center sm:mt-12 sm:mb-5 "
          >
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link
                to="/login"
                className="text-bb-light-green font-semibold mx-8 align-baseline"
              >
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const CustomModalTitle = () => {
    return <h2 className="text-center text-lg font-bold">Delete Search</h2>;
  };

  return (
    <>
      {/* <BananaBanner/> */}
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <div id="inner-wrapper" className="sm:px-8 sm:pt-8 sm:pb-10">
          <section id="content" role="main">
            <div
              id="containedData"
              className=" py-4 sm:max-w-4xl sm:py-8 overflow-auto relative sm:m-auto"
            >
              {/* Header Bar  */}
              <div className="block w-full  my-4 sm:mb-8 pl-7 sm:pl-0">
                <Group>
                  <div id="photoURL">{userImg}</div>
                  <div style={{ flex: 1 }}>
                    <Text
                      size="sm"
                      weight={500}
                      className="text-xl text-black font-semibold"
                    >
                      {titleText}
                    </Text>
                    <Text color="dimmed" size="md">
                      Manage your saved searches or click on one to run it
                    </Text>
                  </div>
                </Group>
              </div>
              {/* Mobile menu */}
              <div className="flex justify-center sm:hidden"></div>
              {/* Side bar  */}
              <div
                id="sideBar"
                className=" pl-2 sm:block sm:text-lg sm:float-left sm:w-23/100"
              >
                <ul className="mb-5">
                  {/* Side Links */}

                  <NavLink to="/settings">
                    <li className="pl-1 text-lg font-medium">My Profile</li>
                  </NavLink>

                  <NavLink to="/savedsearches">
                    <li className="pl-1 text-lg font-medium"><strong>Saved Searches</strong></li>
                  </NavLink>


                  <NavLink to="/billing">
                    <li className="pl-1 text-lg font-medium">Billing</li>
                  </NavLink>

                </ul>
              </div>
              {/* Main Content */}
              <div id="main container" className="px-2 sm:float-right sm:w-4/5">
                {/* mapping over saved searches in doc */}
                {savedSearches.length > 0 ? (
                  savedSearches.map((search, index) => (
                    <SavedSearchesCard
                      search={search}
                      index={index}
                      deleteSavedSearch={deleteSavedSearch}
                      setSavedSearches={setSavedSearches} // Pass the setSavedSearches function
                      key={index}
                    />
                  ))
                ) : (
                  <Card withBorder radius="md" className="sm:w-4/5 my-2 py-12">
                    <Text className="flex justify-center">
                      You do not have any saved searches.
                    </Text>
                    <div className="mt-2 ">
                      <Text mr="md" fz="sm" c="dimmed" align="center">
                        To get notified about new results matching your search
                        criteria, go to&nbsp;&nbsp;
                        <Link
                          to="/results"
                          className="font-semibold text-bb-light-green justify-center"
                        >
                          Search.
                        </Link>
                      </Text>
                    </div>
                  </Card>
                )}
              </div>
            </div>
          </section>
          {/* Button Modals */}

          {/* Delete Modal  */}
          <Modal
            overlayColor="gray"
            overlayOpacity={0.55}
            overlayBlur={3}
            title={<CustomModalTitle />}
            opened={deleteConfirmationVisible}
            onClose={() => setDeleteConfirmVisible(false)}
            size="sm"
            centered
            shadow="md"
          >
            <div className="pb-4">
              <Text size="md">
                Deleting a saved search cannot be undone. Are you sure you want
                to delete this saved search?
              </Text>
            </div>
            {/* <div id="button-container" className="flex"> */}
            <Group position="right">
              <Button
                variant="default"
                size="sm"
                radius="xl"
                className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                onClick={() => setDeleteConfirmVisible(false)}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                size="sm"
                radius="xl"
                className="flex w-fit justify-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow bg-bb-yellow border-black duration-900 text-gray-900 font-normal"
                onClick={() => confirmDelete(indexToDelete)} // When the button is clicked, invoke the `confirmDelete` function with the `indexToDelete` value
              >
                DELETE
              </Button>
            </Group>
            {/* </div> */}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SavedSearches;
