import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { GlobalLoader } from "../components";
import BananaMan from "../data/BananaMan.svg";
import checkout from "../stripe/functions/Checkout";
import React, {useState} from "react";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const CanceledPayment = () => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <div
      id="wrapper"
      className="h-fit flex-grow w-full relative justify-center"
    >
      <Container className="mt-20">
        <SimpleGrid
          spacing={80}
          cols={2}
          breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
        >
          <Image src={BananaMan} className={classes.mobileImage} />
          <div>
            <Title className="font-semibold font-Barlow mb-4 sm:mt-12 ">
              You're almost there! 
            </Title>
            <Text color="dimmed" size="lg">
            We have your profile information, but your payment details need to be updated. Please check out below to continue.
            </Text>
            <div className="flex justify-center sm:block">
              <Button
                 onClick={async () => {
                  setLoading(true);
                  await checkout();
                }}
                variant="outline"
                size="lg"
                type="submit"
                radius="xl"
                mt="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                checkout
              </Button>
            </div>
          </div>
          <Image src={BananaMan} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default CanceledPayment;

// if this was mistake go back to checkout here or simply go home 