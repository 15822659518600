import { useEffect, useState } from 'react';

// Custom hook for Able CDP tracking
export const useAbleCDPTracking = () => {
  const [isAbleCDPLoaded, setIsAbleCDPLoaded] = useState(false);

  useEffect(() => {
    const loadAbleCDP = () => {
      if (window.uipe) {
        setIsAbleCDPLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.src = "https://app.ablecdp.com/ue.js";
      script.onload = () => {
        setIsAbleCDPLoaded(true);
      };
      script.onerror = () => console.error("Error loading Able CDP script");
      document.head.appendChild(script);
    };

    loadAbleCDP();
  }, []);

  useEffect(() => {
    if (isAbleCDPLoaded) {
      window.uipe('init', '652a4334-c77f-4593-9dcc-c4e9fd85b7bb'); // Replace 'YOUR_PROJECT_ID' with your actual project ID
      window.uipe('track', 'PageView');
    }
  }, [isAbleCDPLoaded]);
};
