import {
  List,
  Button,
  Anchor,
  Image,
  Box,
  BackgroundImage,
  Center,
  Text,
  Card,
  Avatar,
  SimpleGrid,
  AspectRatio,
} from "@mantine/core";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import React from "react";
import favorites from "../data/Favorites.webm";
import getStarted from "../data/getStarted.webm";
import bananabanner1 from "../data/BananaBanner1.png";
import leafright from "../data/leafright.png";
import apple from "../data/Apple.png";
import banana from "../data/Bid Banana UNPEELED Banana Full Color.png";
import banana2 from "../data/smallbanana.png";
import x from "../data/x.png";
import check from "../data/check.png";
import unpeeledzoom from "../data/unpeeledzoomed.png";
import testimonials from "../data/Testimonials.png";
import city from "../data/city.svg";
import pencil from "../data/journal-pencil.svg";
import texas from "../data/Vector 1.svg";
import videoThumbnail from "../data/Bidbanana1.png";
import video from "../data/BID BANANA full_NEW_2.webm";
import { Link } from "react-router-dom";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

// SEO Video thumbnail additional
const StructuredData = ({ thumbnailUrl, contentUrl }) => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": "Video Name",
    "description": "Description of the video.",
    "thumbnailUrl": thumbnailUrl,
    "contentUrl": contentUrl
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  );
}

const Home2 = () => {
  useAbleCDPTracking()

  const autoplay = useRef(Autoplay({ delay: 5000 }));

  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative items-center "
    >
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto  xl:gap-0 lg:py-16 lg:grid-cols-12 mb-5 ">
          <div className="mr-auto place-self-center px-2 lg:col-span-6">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
              The Most User-Friendly RFP Search Engine
            </h1>
            <p className="max-w-2xl mb-6 font-bold lg:mb-8 md:text-lg lg:text-xl">
              Searching for bid opportunities has never been so easy:
            </p>
            <List
              listStyleType="disc"
              size="lg"
              spacing="sm"
              className="font-base pb-5 "
            >
              <List.Item>
                Filter + favorite your searches to fit your company's needs
              </List.Item>
              <List.Item>
                Access over 25,000+ local, state, and federal RFPs
              </List.Item>
              <List.Item>Only $49.99/month for access to all RFPs</List.Item>
              <List.Item>Consultants on-hand to help if needed</List.Item>
            </List>
            <div className="flex items-center sm:block my-4">
              <Button
                variant="outline"
                as="a"
                size="xl"
                radius="xl"
                target="_blank"
                className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-black font-normal"
              >
                <Anchor
                  component={Link}
                  to="/register"
                  // href="https://bidbanana.thebidlab.com/register"
                  className="text-black font-normal hover:text-black no-underline hover:no-underline"
                >
                  Get started free
                </Anchor>
              </Button>
            </div>
          </div>
          {/* Gif of searching  */}
          <div
            id="gifSearch"
            className=" my-7 lg:mb-7 lg:col-span-6 flex-auto lg:max-w-full lg:ml-8"
          >
            <div id="video" className="text-center mt-6 ">
              <div className="inline-block">
                <div className="max-w-5xl ">
                  <video
                    controls
                    autoPlay
                    muted
                    className="mb-1 overflow-hidden bg-white rounded-lg border border-[#dedede] shadow-[0px_0px_0px_4px_rgba(241,241,241)] "
                    poster={videoThumbnail}
                    style={{ width: "100%", maxWidth: "800px" }}
                  >
                    <source src={video} type="video/webm" />
                  </video>
                </div>
              </div>
            </div>
            <div
              id="supporting-text"
              className=" flex text-center text-[#969696] text-base italic"
            >
              With over 25,000+ bids covering 2,000+ entities in all 50 states,
              you’ll be able to quickly find a bid that’s right for you and your
              business.
            </div>
          </div>
        </div>
      </section>
      <Box mx="auto">
        <BackgroundImage src={bananabanner1} radius="sm">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-10 md:pt-36">
              <h1 className="max-w-2xl mb-16 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                Why Users Love Bid Banana
              </h1>
            </div>
          </Center>
          <Center>
            <div id="boxes" className="pb-10 md:pb-36">
              <SimpleGrid
                cols={3}
                spacing="lg"
                breakpoints={[
                  { maxWidth: "md", cols: 3, spacing: "md" },
                  { maxWidth: "sm", cols: 1, spacing: "sm" },
                ]}
              >
                <div className="bg-bb-yellow px-5 py-5 w-[21rem]">
                  <div className="flex justify-center mb-10">
                    {/* <Image src={city} height={22}/> */}
                    <Avatar src={city} size="sm"></Avatar>
                  </div>
                  <h2 className=" flex justify-center text-xl font-extrabold tracking-tight pb-15 leading-none md:text-2xl">
                    2,000+
                  </h2>
                  <p className=" flex justify-center mb-6 pt-6 text-md font-light tracking-tight leading-none md:text-lg">
                    Entities
                  </p>
                </div>

                <div className="bg-leaf-lightest-green px-5 py-5 w-[21rem]">
                  <div className="flex justify-center mb-10">
                    {/* <Image src={city} height={22}/> */}
                    <Avatar src={pencil} size="sm"></Avatar>
                  </div>
                  <h2 className=" flex justify-center text-xl font-extrabold tracking-tight pb-15 leading-none md:text-2xl">
                    25,000+
                  </h2>
                  <p className=" flex justify-center mb-6 pt-6 text-md font-light tracking-tight leading-none md:text-lg">
                    Bids
                  </p>
                </div>

                <div className="bg-bb-lightyellow px-5 py-5 w-[21rem]">
                  <div className="flex justify-center mb-10">
                    {/* <Image src={city} height={22}/> */}
                    <Avatar src={texas} size="sm"></Avatar>
                  </div>
                  <h2 className=" flex justify-center text-xl font-extrabold tracking-tight pb-15 leading-none md:text-2xl">
                    All 50
                  </h2>
                  <p className=" flex justify-center mb-6 pt-6 text-md font-light tracking-tight leading-none md:text-lg">
                    States
                  </p>
                </div>
              </SimpleGrid>
            </div>
          </Center>
        </BackgroundImage>
      </Box>

      {/* 3rd section  */}
      <Box mx="auto" className="">
        <BackgroundImage src={leafright} radius="sm">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-10 md:pt-36">
              <h1 className="max-w-2xl mb-16 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                What Makes Bid Banana Great
              </h1>
            </div>
          </Center>
          <Center>
            <div id="boxes" className="pb-10 md:pb-26">
              <SimpleGrid
                cols={2}
                spacing="lg"
                breakpoints={[
                  { maxWidth: "md", cols: 2, spacing: "lg" },
                  { maxWidth: "sm", cols: 1, spacing: "sm" },
                ]}
              >
                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">
                      AI Document Analysis
                    </h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      Harness the power of generative AI to extract hidden
                      information from thousands of pages of documents,
                      providing insights others can’t access.
                    </p>
                  </div>
                </div>
                {/* 2nd card */}
                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">
                      Saved Search
                    </h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      Bid effortlessly with our Personalized Bidding Assistant.
                      Store and recall your frequently used search terms and
                      filters with a single click, and receive proactive email
                      notifications for new bids matching your saved searches,
                      ensuring you stay ahead of the competition.
                    </p>
                  </div>
                </div>
                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">
                      Intuitive Design
                    </h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      The platform was built to be easy to navigate for everyday
                      users, not bidding experts.
                    </p>
                  </div>
                </div>
                {/* second card */}
                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">
                      Quality Data
                    </h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      There's no need to worry about outdated bids or incomplete
                      information as our tool was designed to eliminate these
                      frustrations.
                    </p>
                  </div>
                </div>

                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="inline-flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">
                      Comprehensive Results
                    </h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      With over 25,000+ bids covering 2,000+ entities in all 50
                      states, you'll be able to find a bid that's right for you
                      and your business.
                    </p>
                  </div>
                </div>

                <div className="bg-white md:px-7 md:py-5 px-2 py-3 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="flex mb-2 ml-2">
                    {/* <Image src={city} height={22}/> */}
                    <img src={banana} className="max-w-[2rem]" />
                    <h3 className="text-2xl font-extrabold ml-3">Low Cost</h3>
                  </div>
                  <div>
                    <p className="text-base font-normal pl-12 mb-5">
                      Designed for small business owners, we are priced
                      significantly lower than our competitors that cost nearly
                      10x as much.
                    </p>
                  </div>
                </div>
              </SimpleGrid>
            </div>
          </Center>
          <Center>
            <SimpleGrid
              cols={2}
              spacing="lg"
              breakpoints={[
                { maxWidth: "md", cols: 2, spacing: "lg" },
                { maxWidth: "sm", cols: 1, spacing: "sm" },
              ]}
            >
              <div
                id="favoriting"
                className="my-7 flex-1  max-w-[21rem] sm:max-w-[27rem]"
              >
                <div className=" mb-1 overflow-hidden bg-white rounded-lg border border-[#dadada] shadow-[0px_0px_0px_4px_rgba(222,222,222)] sm:max-w-600 ">
                  <div className="lg:col-span-5 relative max-w-[21rem] sm:max-w-[27rem]">
                  <video autoPlay loop muted playsInline>
                      <source src={favorites} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                    {/* SEO video thumbnail url  */}
                <StructuredData thumbnailUrl={videoThumbnail} contentUrl={favorites} />

                  </div>
                </div>
                <div
                  id="supporting-text"
                  className=" flex justify-center text-center text-[#969696] text-base italic"
                >
                  Click on the banana to save it to your favorites.
                </div>
              </div>
              <div className="flex justify-center items-center ">
                <div className="sm:block mb-5 my-4 max-w-[21rem] sm:max-w-[27rem]">
                  <div className="">
                    <Button
                      variant="outline"
                      as="a"
                      size="xl"
                      radius="xl"
                      target="_blank"
                      className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-black font-normal"
                    >
                      <Anchor
                        component={Link}
                        to="/register"
                        // href="https://bidbanana.thebidlab.com/register"
                        className="text-black font-normal hover:text-black no-underline hover:no-underline"
                      >
                        Free trial
                      </Anchor>
                    </Button>
                  </div>
                </div>
              </div>
            </SimpleGrid>
          </Center>
        </BackgroundImage>
      </Box>

      {/* Apples to Bananas */}
      <Box mx="auto" className="">
        <section className="bg-white">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-10 md:pt-36">
              <h1 className="max-w-2xl mb-16 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                Don't compare apples to Bananas{" "}
              </h1>
            </div>
          </Center>
          <Center>
            <div id="table" className="pb-10 md:pb-36">
              <table className="table-auto">
                <thead className="font-bold text-sm sm:text-xl">
                  <tr>
                    <th className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]"></th>
                    <th className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={banana2} size="sm"></Avatar>
                    </th>
                    <th className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={apple} size="sm"></Avatar>
                    </th>
                  </tr>
                  <tr>
                    <th className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]"></th>
                    <th className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      Bid Banana
                    </th>
                    <th className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      Other RFP Databases
                    </th>
                  </tr>
                </thead>
                <tbody className="font-bold text-sm sm:text-xl">
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      50 State Coverage
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      Small Business Focus
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={x} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      User-friendly Design
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={x} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      RFP Experts Available
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={x} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      Private Bid Upload Ability
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={x} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      Month to Month Flexibility
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={check} size="sm"></Avatar>
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      <Avatar src={x} size="sm"></Avatar>
                    </td>
                  </tr>
                  <tr className="bg-bb-yellow">
                    <td className=" px-2 sm:inline-flex justify-center py-2 w-[10rem] xl:w-[37rem]">
                      Monthly Price
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      $49.99
                    </td>
                    <td className="inline-flex justify-center py-2 w-[7rem] xl:w-[18rem]">
                      $100-$500
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Center>
        </section>
      </Box>

      {/* Price */}

      <Box mx="auto" className="">
        <BackgroundImage src={unpeeledzoom} radius="sm">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-2 md:pt-5">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                $49.99/month
              </h1>
            </div>
          </Center>

          <Center>
            <div className="flex justify-center items-center ">
              <div className="sm:block md:p-9 my-4 max-w-[21rem] sm:max-w-[27rem]">
                <div className="">
                  <Button
                    variant="outline"
                    as="a"
                    size="xl"
                    radius="xl"
                    target="_blank"
                    className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-black font-normal"
                  >
                    <Anchor
                      component={Link}
                      to="/register"
                      // href="https://bidbanana.thebidlab.com/register"
                      className="text-black font-normal hover:text-black no-underline hover:no-underline"
                    >
                      Start my free trial
                    </Anchor>
                  </Button>
                </div>
              </div>
            </div>
          </Center>
        </BackgroundImage>
      </Box>
      {/* Powered by bidlab */}
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 mb-5">
          <div className="mr-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
              Bid Banana Powered by The Bid Lab
            </h1>
            <p className="max-w-2xl mb-6 mt-5 font-base lg:mb-8 md:text-lg lg:text-xl">
              Bid Banana was built by a team of RFP specialists and skilled
              developers. Together they created the industry's best RFP search
              engine. Bid Banana helps you easily find RFPs across all lines of
              business and geographic locations. Conducting RFP searches has
              never been simpler or more, dare we say, bananas.
            </p>

            <div className="flex justify-center items-center sm:block my-4">
              <Button
                variant="outline"
                as="a"
                size="xl"
                radius="xl"
                target="_blank"
                className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-black font-normal"
              >
                <Anchor
                  component={Link}
                  to="/register"
                  // href="https://bidbanana.thebidlab.com/register"
                  className="text-black font-normal hover:text-black no-underline hover:no-underline"
                >
                  7-day free trial
                </Anchor>
              </Button>
            </div>
          </div>
          {/* Gif of getting started/ kicking off a bid   */}
          <div
            id="gifSearch"
            className=" my-7 lg:mb-7 lg:col-span-5 flex-auto max-w-[32rem] lg:max-w-full"
          >
            <div className="mb-1 overflow-hidden bg-white rounded-lg mt-10 border border-[#dedede] shadow-[0px_0px_0px_4px_rgba(241,241,241)] sm:max-w-600 ">
              <div className="relative flex-col flex w-full">
              <video autoPlay loop muted playsInline>
                      <source src={getStarted} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                    {/* SEO video thumbnail url  */}
                <StructuredData thumbnailUrl={videoThumbnail} contentUrl={getStarted} />

              </div>
            </div>
            <div
              id="supporting-text"
              className=" flex text-center justify-center text-[#969696] text-base italic"
            >
              Click "Get Started" to connect with an expert from The Bid Lab.
            </div>
          </div>
        </div>
      </section>

      {/* testimonials */}

      <Box mx="auto" className="">
        <BackgroundImage src={testimonials} radius="sm">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-3 md:pt-12">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                Why Businesses Love Bid Banana
              </h1>
            </div>
          </Center>
          <div style={{ height: 400, display: "flex" }}>
            <Carousel
              // sx={{ maxWidth: 300 }}
              mx="auto"
              controlSize={35}
              loop
              draggable
              withIndicators
              height={300}
              plugins={[autoplay.current]}
              onMouseEnter={autoplay.current.stop}
              onMouseLeave={autoplay.current.reset}
              className="max-w-sm sm:max-w-xl md:max-w-4xl"
            >
              <Carousel.Slide>
                <Card>
                  <div className="bg-white md:px-7 md:py-5 px-2 py-3">
                    <div>
                      <p className=" text-sm md:text-xl font-normal pl-12 mb-5">
                        “Working on RFPs can be stressful, overwhelming and
                        time-consuming, but I never felt alone or lost because
                        The Bid Lab was there every step of the way. And, with a
                        30x return on investment, I will definitely keep
                        expanding my relationship with The Bid Lab!”
                      </p>
                    </div>
                    <div>
                      <p className="font-bold text-md pl-12">Janki Patel</p>
                      <p className="font-base text-md pl-12">
                        Jump Ahead Pediatrics
                      </p>
                    </div>
                  </div>
                </Card>
              </Carousel.Slide>
              <Carousel.Slide>
                <Card>
                  <div className="bg-white md:px-7 md:py-5 px-2 py-3">
                    <div>
                      <p className=" text-sm md:text-xl font-normal pl-12 mb-5">
                        “I was blown away by how your team could take over 100
                        pages of information and requirements and edit it all
                        down to 20, along with completing it within the strict
                        time restraints. We look forward to doing it again as
                        part of our business growth plan.”
                      </p>
                    </div>
                    <div>
                      <p className="font-bold text-md pl-12">Mark Boeing</p>
                      <p className="font-base text-md pl-12">
                        Environmental Disinfection Solutions
                      </p>
                    </div>
                  </div>
                </Card>
              </Carousel.Slide>
              <Carousel.Slide>
                <Card>
                  <div className="bg-white md:px-7 md:py-5 px-2 py-3">
                    <div>
                      <p className=" text-sm md:text-xl  font-normal pl-12 mb-5">
                        “We never expected that another business would be able
                        to deliver bid wins for us the way The Bid Lab has. We
                        look forward to continuing this relationship while
                        expanding our business in the public sector.”
                      </p>
                    </div>
                    <div>
                      <p className="font-bold text-md pl-12">Douglas Masch</p>
                      <p className="font-base text-md pl-12">DEC Contracting</p>
                    </div>
                  </div>
                </Card>
              </Carousel.Slide>
              <Carousel.Slide>
                <Card>
                  <div className="bg-white md:px-7 md:py-5 px-2 py-3">
                    <div>
                      <p className=" text-sm md:text-xl  font-normal pl-12 mb-5">
                        “The Bid Lab team exceeded my expectations before I even
                        signed on to partner with them. From the very first
                        conversation, I knew they were the company that could
                        help me respond to my first RFP. I now not only have
                        expanded my business, but also my knowledge of what it
                        takes to respond to RFPs.”
                      </p>
                    </div>
                    <div>
                      <p className="font-bold text-md pl-12">Michele Walter</p>
                      <p className="font-base text-md pl-12">
                        Life From the Summit
                      </p>
                    </div>
                  </div>
                </Card>
              </Carousel.Slide>
              {/* ...other slides */}
            </Carousel>
          </div>
        </BackgroundImage>
      </Box>

      {/* Get started */}
      <Box mx="auto" className="">
        <section className="bg-white">
          <Center p="md">
            <div className="place-self-center px-2 lg:col-span-7 pt-3 md:pt-12">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
                Try Bid Banana today
              </h1>
            </div>
          </Center>
          <div className="flex justify-center">
            <p className="text-center max-w-2xl mb-2 font-base lg:mb-4 md:text-lg lg:text-xl">
              Save time and money with Bid Banana – the best RFP search engine
              for small businesses.
            </p>
          </div>

          <Center>
            <div className="flex justify-center items-center ">
              <div className="sm:block md:p-9 my-4 max-w-[21rem] sm:max-w-[27rem]">
                <div className=" mb-6 md:mb-10">
                  <Button
                    variant="outline"
                    as="a"
                    size="xl"
                    radius="xl"
                    target="_blank"
                    className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-black font-normal"
                  >
                    <Anchor
                      component={Link}
                      to="/register"
                      // href="https://bidbanana.thebidlab.com/register"
                      className="text-black font-normal hover:text-black no-underline hover:no-underline"
                    >
                      Start my free trial
                    </Anchor>
                  </Button>
                </div>
              </div>
            </div>
          </Center>
        </section>
      </Box>
    </div>
  );
};

export default Home2;
