import React from "react";
import { useUserAuth } from "../contexts/UserAuthContext";
import { Highlight } from "react-instantsearch-hooks-web";
import {
  Button,
  ActionIcon,
  Group,
  Modal,
  Spoiler,
  Center,
  Card,
  Tooltip,
  Anchor,
} from "@mantine/core";
import { HiOutlineLockClosed } from "react-icons/hi";
import { FaRegEyeSlash} from "react-icons/fa";
import { TiCreditCard } from "react-icons/ti";
import { doc, getDoc, deleteDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../Firebase";
import { HiOutlineX } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";
import { showNotification, updateNotification } from "@mantine/notifications";

const HiddenHits = ({ hit }) => {

  const AuthContext = useUserAuth();

  const removeFromHidden = async (objectID, title) => {
    showNotification({
      id: "remove-hidden",
      title: "Moving bid to public index...",
      loading: true,
      autoClose: 3000,
      message: `Removed "${title}" from favorites!`,
      color: "blue",
    });
    try {

      const docRef = doc(db, "hidden_bids", objectID);
      const docSnapshot = await getDoc(docRef);
      const docData = docSnapshot.data();

      await addDoc(collection(db, "bids"), docData);
      await deleteDoc(doc(db, "hidden_bids", objectID));

      updateNotification({
        id: "remove-hidden",
        title: "Success! The bid is now visible to the public.",
        color: "green",
        icon: <AiOutlineCheck size={16} />,
        autoClose: 2000,
      });
    } catch (e) {
      console.log(e);
      updateNotification({
        id: "remove-hidden",
        title: "Something went wrong...",
        color: "red",
        icon: <HiOutlineX size={16} />,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="sm:flex flex-col items-start">
      <div className="justify-center md:max-w-4xl  md:self-stretch">
        <article className="break-words whitespace-pre-line flex-nowrap mb-10 mx-3 border-1 border-gray-200 rounded-xl drop-shadow-sm max-w-4xl">
          <Group position="apart" className="flex-nowrap items-start">
            <span className=" flex p-2 pt-4 ml-3 font-semibold flex-wrap sm:flex-nowrap">
              Title:&nbsp;&nbsp;
              <p className=" sm:max-w-3xl">
                <Highlight
                  attribute="title"
                  hit={hit}
                  classNames={{
                    root: "pl-0 font-light xs:max-w-xs xl:max-w-lg md:max-w-lg",
                    nonHighlighted: "",
                    highlighted:
                      "bg-gradient-to-l from-leaf-lightest-green to-bb-yellow",
                  }}
                />
              </p>
            </span>
            <div className="top-0 sm:flex sm:flex-col ">
              <div className="flex flex-row justify-end">
                <div className="inline-flex pr-3">
                  <Tooltip
                    label="Un-hide this bid"
                    withArrow
                    position="top"
                    offset={5}
                    openDelay={600}
                    transition="pop"
                    transitionDuration={200}
                    color="blue"
                    onClick={() => {
                      removeFromHidden(hit.objectID, hit.title);
                    }}
                  >
                    <ActionIcon
                      variant="transparent"
                      size="xl"
                      color="gray.4"
                      radius="lg"
                      className="mt-2"
                    >
                      <FaRegEyeSlash size={24} />
                    </ActionIcon>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Group>
          {AuthContext.user.admin && (
            <span className="flex p-2 ml-3 font-semibold flex-wrap">
              Site Name:&nbsp;&nbsp;{" "}
              <p className="pl-0 font-light">{hit.site_name}</p>
            </span>
          )}
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            Agency:&nbsp;&nbsp;{" "}
            <span className="max-w-md sm:max-w-[33rem] pl-0 font-light">
              {hit.agency}
            </span>
          </span>
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            State:&nbsp;&nbsp; <p className="pl-0 font-light">{hit.state}</p>
          </span>
          <span className="flex p-2 ml-3 font-semibold">
            Open Date:{" "}
            <p className="max-w-3xl pl-2 font-light">{hit.open_date}</p>
          </span>
          <span className="flex p-2 ml-3 font-semibold">
            Close Date: <p className="pl-2 font-light">{hit.close_date}</p>
          </span>
          <span className="break-words max-w-sm sm:max-w-2xl">
            <span className="pl-2 ml-3 font-semibold">Description: </span>
            <span className="flex px-0 mr-3 text-justify">
              <Spoiler
                maxHeight={48}
                showLabel="Show more"
                hideLabel="Hide"
                className=" max-w-[22rem] lg:max-w-3xl md:max-w- sm:max-w-[33rem]  self-stretch xs:break-words sm:break-words flex-column -indent-5 whitespace-normal mr-3 p-2 ml-3 font-light justify-center"
              >
                <Highlight
                  attribute="description"
                  hit={hit}
                  classNames={{
                    root: "text-justify p-2 ml-3 font-light",
                    nonHighlighted: "text-justify",
                    highlighted:
                      "bg-gradient-to-l from-leaf-lightest-green to-bb-yellow",
                  }}
                />
              </Spoiler>
            </span>
          </span>

          {/* Botom left card links */}
          <Card.Section>
            <div className="flex justify-center sm:flex sm:flex-col z-1 relative sm:justify-end pr-1 mr-2 my-2 pb-3">
              <div>
                <Group position="apart" spacing="md">
                  <span className="flex mx-3 pl-2 ">
                    <div className={hit.login_required ? "visible" : "hidden"}>
                      <Tooltip
                        label="This bid requires an additional login"
                        withArrow
                        multiline
                        position="bottom"
                        width={240}
                        openDelay={400}
                        transition="pop"
                        transitionDuration={200}
                        color="blue"
                      >
                        <p className="mr-4">
                          <HiOutlineLockClosed
                            size={24}
                            className="text-gray-400"
                          />
                        </p>
                      </Tooltip>
                    </div>
                    <div
                      className={hit.payment_required ? "visible" : "hidden"}
                    >
                      <Tooltip
                        label="This bid requires additional payment"
                        withArrow
                        multiline
                        position="bottom"
                        width={240}
                        openDelay={400}
                        transition="pop"
                        transitionDuration={200}
                        color="blue"
                      >
                        <p className="">
                          <TiCreditCard
                            size={26}
                            stroke={1.5}
                            className="text-gray-400"
                          />
                        </p>
                      </Tooltip>
                    </div>
                  </span>
                  <Group spacing="md" className="m-2 mr-5">
                    <Center>
                      <Button
                        variant="default"
                        size="sm"
                        radius="xl"
                        className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                      >
                        <Anchor
                          href={hit.url}
                          target="_blank"
                          className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline"
                        >
                          Link to bid
                        </Anchor>
                      </Button>
                    </Center>
                  </Group>
                </Group>
              </div>
            </div>
          </Card.Section>
        </article>
      </div>
    </div>
  );
};

export default HiddenHits;