import React from "react";
import { Skeleton } from "@mantine/core";
import BananaBanner from "../BananaBanner";

const FavoritesSkeleton = () => {
  return (
    <div
      id="Favorites"
      className="h-fit flex-grow flex-col w-full relative justify-center "
    >
      <BananaBanner title1="Favorite" title2="fruits of your labor" />
      <div className="max-w-5xl m-auto">
        <div className="flex-col align items-center mb-4 overflow-hidden py-20 px-5">
          <span>
            {/* Skeleton for SVG */}

            {/* Skeleton for the h3 */}
            <Skeleton className="m-auto block h-4 w-3/4 mb-2" />

            {/* Skeleton for the p */}
            <Skeleton className="m-auto block h-3 w-5/6 mb-4" />
            <Skeleton className="m-auto block h-3 w-4/5 mb-6" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FavoritesSkeleton;
