import React from "react";
import {
  Button,
  List,
  Anchor,
  ThemeIcon,
  Tabs,
  Card,
  SimpleGrid,
  Image,
  Divider,
  Accordion,
  Text,
} from "@mantine/core";
import Bidlabgroup from "../data/Bidlabgroup.svg";
import { IconCircleCheck, IconCircle } from "@tabler/icons";
import { BananaBanner } from "../components";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineSolution,
  AiOutlineEdit,
  AiOutlineFontSize,
  AiOutlineBgColors,
  AiOutlineLike,
} from "react-icons/ai";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Getexperthelp = () => {
  useAbleCDPTracking()

  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <BananaBanner
        title1="Bid Banana"
        title2="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x The Bid Lab&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />

      {/* Header */}
      {/* This could eventually go into its own component  */}
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">
              The Bid Lab’s Experts Are Ready To Help You Win RFPs
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              The Bid Lab is the only RFP consulting firm of its kind,
              specializing in guiding small and medium-sized businesses through
              the proposal response process. We have RFP experts ready to help
              you respond to that perfect opportunity you discover in Bid
              Banana.
            </p>
            <List
              type="unordered"
              size="lg"
              spacing="sm"
              className="text-gray-500 pb-5 "
              icon={
                <ThemeIcon color="#5184B2" size={24} radius="xl">
                  <IconCircleCheck size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>Trusted by 100s of small business owners</List.Item>
              <List.Item>
                Experience working on 1000s of RFP responses
              </List.Item>
              <List.Item>
                <Text>
                  Success stories in virtually every industry you can check out
                  by
                  <Anchor
                    href="https://www.thebidlab.com/case-studies/"
                    target="_blank"
                    className="font-semibold text-green-300"
                  >
                    &nbsp;clicking here.
                  </Anchor>
                </Text>
              </List.Item>
            </List>
            <div className="flex justify-center sm:block my-4">
              <Button
                variant="outline"
                as="a"
                size="md"
                radius="xl"
                target="_blank"
                className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-gray-600 font-normal"
              >
                <Anchor
                  href="https://www.thebidlab.com/contact/"
                  target="_blank"
                  className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline"
                >
                  Book a consultation
                </Anchor>
              </Button>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={Bidlabgroup} />
          </div>
        </div>
      </section>

      <Tabs
        color="bb-light-green.0"
        variant="pills"
        radius="xl"
        defaultValue="bidManager"
        classNames={{ tabLabel: "text-black border-black" }}
      >
        <Tabs.List className="flex justify-center">
          <Tabs.Tab value="bidManager" className="text-xl">
            Bid Manager{" "}
          </Tabs.Tab>
          <Tabs.Tab value="alacarte" className="text-xl">
            À-La-Carte
          </Tabs.Tab>
        </Tabs.List>

        {/* End of Header */}

        {/* Tab 1 Bid Manager */}
        <Tabs.Panel value="bidManager" pt="xs">
          <div className="flex flex-col items-center">
            <div className="m-4 sm:m-12">
              {/* Service descriptions */}

              <br />

              <div className="flex justify-center">
                <div className="my-4 p-2 items-center bg-white rounded-lg border shadow-sm md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <p className=" font-light text-gray-500 lg:mb-4 md:text-lg lg:text-xl">
                      From strategizing your first bid opportunity to submitting
                      the perfect proposal, The Bid Lab’s RFP experts assist
                      with proposals of all kinds, lengths, scopes, and formats.
                      We are industry agnostic and will supplement your
                      company’s expertise.
                    </p>
                  </div>
                </div>
              </div>

              {/* Step 1 */}
              <div className="flex flex-col justify-between p-4  leading-normal md:md:w-[64rem] ">
                <h1 className="mb-3 text-2xl font-bold tracking-tight text-gray-900">
                  What Do We Do?
                </h1>
              </div>
              <div className="flex justify-center">
                <br />
                <List>
                  <SimpleGrid
                    cols={2}
                    spacing="xl"
                    breakpoints={[
                      { maxWidth: "md", cols: 2, spacing: "md" },
                      { maxWidth: "sm", cols: 2, spacing: "sm" },
                      { maxWidth: "xs", cols: 2, spacing: "sm" },
                    ]}
                  >
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900 ">
                        Narrow down the perfect opportunity
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Determine eligibility
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Take the first pass
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Identify information gaps
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Write compelling content
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Edit and proof for accuracy
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Design an aesthetically pleasing response document
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Submit the proposal via the determined medium
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Save all developed content in the response database
                      </h5>
                    </List.Item>
                    <List.Item className="p-4 rounded-lg border">
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Follow up on contract award
                      </h5>
                    </List.Item>
                  </SimpleGrid>
                </List>
              </div>
            </div>

            <div className="flex flex-col justify-between p-4  leading-normal md:md:w-[64rem] ">
              <h1 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                How Do We Do It?
              </h1>
              <br />
              <SimpleGrid
                cols={2}
                breakpoints={[
                  { maxWidth: "md", cols: 2, spacing: "md" },
                  { maxWidth: "sm", cols: 1, spacing: "sm" },
                  { maxWidth: "xs", cols: 1, spacing: "sm" },
                ]}
              >
                <div className="px-2 flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <div className="flex">
                      <p className="mb-2 px-2 font-light uppercase bg-green-300 lg:mb-0 md:text-lg lg:text-xl">
                        1
                      </p>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 lg:mb-0 md:text-lg lg:text-xl">
                        &nbsp;&nbsp;Positivity
                      </h5>
                    </div>
                    <p className="mb-3 text-xl font-normal text-gray-500">
                      Anyone can do the job. Only we do the job with the right
                      attitude, even when deadlines are tight.
                    </p>
                  </div>
                </div>

                <div className="px-2 flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <div className="flex">
                      <p className="mb-2 px-2 font-light uppercase bg-green-300 lg:mb-0 md:text-lg lg:text-xl">
                        2
                      </p>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 lg:mb-0 md:text-lg lg:text-xl">
                        &nbsp;&nbsp;Flexibility
                      </h5>
                    </div>
                    <p className="mb-3 text-xl font-normal text-gray-500 ">
                      Every firm has its own way of doing things. Our RFP
                      experts will work around your people, processes, and
                      schedules.
                    </p>
                  </div>
                </div>

                <div className="px-2 flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <div className="flex">
                      <p className="mb-2 px-2 font-light uppercase bg-green-300 lg:mb-0 md:text-lg lg:text-xl">
                        3
                      </p>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 lg:mb-0 md:text-lg lg:text-xl">
                        &nbsp;&nbsp;Transparency
                      </h5>
                    </div>
                    <p className="mb-3 text-xl font-normal text-gray-500">
                      Have any questions during the process? Call us and we’ll
                      share the status of your bid at any time.
                    </p>
                  </div>
                </div>

                <div className="px-2 flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <div className="flex">
                      <p className="mb-2 px-2 font-light uppercase bg-green-300 lg:mb-0 md:text-lg lg:text-xl">
                        4
                      </p>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 lg:mb-0 md:text-lg lg:text-xl">
                        &nbsp;&nbsp;Quality
                      </h5>
                    </div>
                    <p className="mb-3 text-xl font-normal text-gray-500">
                      Our 4-eye review process ensures that even the smallest of
                      errors are caught.
                    </p>
                  </div>
                </div>

                <div className="px-2 flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                  <div className="flex flex-col justify-between p-4 leading-normal">
                    <div className="flex">
                      <p className="mb-2 px-2 font-light uppercase bg-green-300 lg:mb-0 md:text-lg lg:text-xl">
                        5
                      </p>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 lg:mb-0 md:text-lg lg:text-xl">
                        &nbsp;&nbsp;Reliability
                      </h5>
                    </div>
                    <p className="mb-3 text-xl font-normal text-gray-500">
                      Our team has never missed a deadline, and we don’t plan on
                      starting anytime soon.
                    </p>
                  </div>
                </div>
              </SimpleGrid>
              <br />

              <div className="flex flex-col justify-between py-4  leading-normal md:w-[64rem] ">
                <h1 className="mb-5 text-2xl font-bold tracking-tight text-gray-900">
                  Who Does It?
                </h1>
                <p className="mb-3 text-xl font-normal text-gray-500">
                  Think of The Bid Lab as your organization’s personal RFP team.
                </p>
              </div>
              <div className="flex px-4 md:p-0">
                <br />
                <List listStyleType="disc">
                  <SimpleGrid
                    cols={3}
                    spacing="xl"
                    breakpoints={[
                      { maxWidth: "md", cols: 2, spacing: "md" },
                      { maxWidth: "sm", cols: 1, spacing: "sm" },
                      { maxWidth: "xs", cols: 1, spacing: "sm" },
                    ]}
                  >
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineFundProjectionScreen
                            size={24}
                            color="white"
                          />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900 ">
                        Project Manager
                      </h5>
                    </List.Item>
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineLike size={24} color="white" />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Compliance Master
                      </h5>
                    </List.Item>
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineFontSize size={24} color="white" />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Technical Writer
                      </h5>
                    </List.Item>
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineEdit size={24} color="white" />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Editor
                      </h5>
                    </List.Item>
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineBgColors size={24} color="white" />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Designer
                      </h5>
                    </List.Item>
                    <List.Item
                      className="md:p-4 md:w-[32rem]"
                      icon={
                        <ThemeIcon color="#5184B2" size={36} radius="xl">
                          <AiOutlineSolution size={24} color="white" />
                        </ThemeIcon>
                      }
                    >
                      <h5 className="mb-2 text-xl font-normal tracking-tight text-gray-900">
                        Account Manager
                      </h5>
                    </List.Item>
                  </SimpleGrid>
                </List>
              </div>
            </div>

            <br />
            <div>
              <div className="flex justify-start  leading-normal md:w-[64rem] ">
                <h1 className="mb-8 px-4  mt-6 text-2xl font-bold tracking-tight text-gray-900">
                  Why The Bid Lab?
                </h1>
              </div>
              <div className="flex justify-start px-6">
                <br />
                <List>
                  <SimpleGrid cols={1} spacing="md">
                    <List.Item className="md:p-1">
                      <h5 className="text-xl font-normal bg-bb-blue tracking-tight text-gray-900 ">
                        NO minimum turnaround times
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO limit to revisions
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO industry exclusions
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO hidden fees
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO office hours -24/7 coverage
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO missed deadlines
                      </h5>
                    </List.Item>
                    <List.Item className="md:p-1">
                      <h5 className="text-xl bg-bb-blue font-normal tracking-tight text-gray-900">
                        NO complaints!
                      </h5>
                    </List.Item>
                  </SimpleGrid>
                </List>
              </div>
              <br />
            <div className="flex flex-col justify-between p-4  leading-normal md:w-[64rem] ">
              <h1 className="mb-5 text-2xl font-bold tracking-tight text-gray-900">
                Where Do I Get Started?
              </h1>
              <p className="mb-3 font-normal text-xl text-gray-500">
                The Bid Lab can take a look at your Bid Banana RFP and tell you
                exactly how we would help you win it. Want a free estimate? Book
                a consultation, call us or send an email. Don’t go bananas. The
                Bid Lab is here.
              </p>
            </div>
            </div>
            
          </div>

          <div className="flex justify-center my-4 sm:m-8">
            <Button
              variant="outline"
              as="a"
              size="xl"
              radius="xl"
              target="_blank"
              className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-gray-600 font-normal"
            >
              <Anchor
                href="https://www.thebidlab.com/contact/"
                target="_blank"
                className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline"
              >
                Book a consultation
              </Anchor>
            </Button>
          </div>
          <br/>
        </Tabs.Panel>

        {/* end of tab 1 */}

        {/* tab 2 Al A Carte  */}

        <Tabs.Panel value="alacarte" pt="xs">
          <div className="flex flex-col items-center">
            <div className="m-4 sm:m-12">
              {/* Service descriptions */}

              <br />
              <div className="m-4 p-2 flex flex-col items-center bg-white rounded-lg border shadow-sm md:flex-row md:md:w-[64rem]">
                <div className="flex flex-col justify-between p-4 leading-normal">
                  <p className="mb-3 font-normal text-gray-500 md:text-lg lg:text-xl">
                    With the understanding that each business we work with has
                    unique needs and internal capabilities, we’re offering an
                    à-la-carte version of our Bid Manager service. This version
                    allows clients to choose segments of our service that best
                    fit their needs, and it’s perfect for companies that require
                    some of our Bid Manager services but not the entire
                    end-to-end offering. Our goal at The Bid Lab is to
                    complement your team and contribute support where it’s
                    needed. So, if you’re looking for service flexibility, to
                    internally manage aspects of your bidding process or simply
                    to keep costs down, our À-La-Carte Bid Manager service is a
                    great choice for you.
                  </p>
                </div>
              </div>

              {/* alacarte services list */}

              <div className=" flex flex-col items-center bg-white md:flex-row md:max-w-5xl">
                <div className="flex flex-col justify-between leading-normal ">
                  <div className="flex justify-center">
                    <p className="max-w-2xl flex mt-2  justify-center text-justify mb-6 font-medium lg:mb-8 md:text-lg lg:text-xl">
                      List of À-La-Carte Services:
                    </p>
                  </div>
                  <SimpleGrid
                    cols={2}
                    breakpoints={[
                      { maxWidth: "md", cols: 2, spacing: "md" },
                      { maxWidth: "sm", cols: 1, spacing: "sm" },
                      { maxWidth: "xs", cols: 1, spacing: "sm" },
                    ]}
                  >
                    <Accordion variant="default">
                      <Accordion.Item
                        value="Eligibility Verification"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Eligibility Verification
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="RFP Response Document Template Creation"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                RFP Response Template Creation
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="RFP Response Document Template Creation
"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Response First Pass
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Technical Writing"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Technical Writing
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item value="Formatting" className="border-b-0">
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Formatting
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Editing and Proofing"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Editing and Proofing
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Graphic Design"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Graphic Design
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Packaging and Delivery"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Packaging and Delivery
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item value="Follow-Up" className="border-b-0">
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Follow-Up
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Response Database Creation"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Response Database Creation
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Content Evaluation"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Content Evaluation
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Win Rate Enhancement Consulting"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Win Rate Enhancement Consulting
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion variant="default">
                      <Accordion.Item
                        value="Portal Registrations"
                        className="border-b-0"
                      >
                        <div className="mx-4 p-2  items-center bg-white rounded-lg border shadow-sm md:flex-row md:w-[32rem]">
                          <div className="flex flex-col justify-between p-4 leading-normal">
                            <Accordion.Control className="hover:bg-white text-green-300">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                Portal Registrations
                              </h5>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <p className="mb-3 font-normal text-gray-500">
                                Determining your company’s eligibility to bid on
                                an RFP can be challenging and time-consuming.
                                And, many times responders are already well into
                                their proposal before realizing they actually
                                don’t qualify for the contract due to a small
                                technicality they overlooked. Let our team of
                                dedicated experts pull all of the necessary
                                minimum qualifications from a bid to allow you
                                to quickly verify your eligibility. We’ll
                                analyze the RFP in scope to verify your firm’s
                                eligibility, saving your team countless hours of
                                filtering and research.
                              </p>
                            </Accordion.Panel>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>
                  </SimpleGrid>
                </div>
              </div>

              {/* list content */}

              <div className="flex justify-center my-4 sm:m-8">
                <Button
                  variant="outline"
                  as="a"
                  size="xl"
                  radius="xl"
                  target="_blank"
                  className="flex w-fit  bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-gray-600 font-normal"
                >
                  <Anchor
                    href="https://www.thebidlab.com/contact/"
                    target="_blank"
                    className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline"
                  >
                    Book a consultation
                  </Anchor>
                </Button>
              </div>
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Getexperthelp;
