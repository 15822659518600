import { Skeleton } from '@mantine/core'
import React from 'react'

const CheckoutSkeleton = () => {
  return (
    <div className="max-w-[690px]">
    <div className="grid gap-1 m-auto mt-1">
        <div className="justify-center grid gap-1 m-auto ">
            <Skeleton height={30} width={120} />
        </div>
        <br />
        <div className="flex justify-between">
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={150} />
        </div>
        <div className="flex justify-between mt-2">
            <Skeleton height={20} width={90} />
            <Skeleton height={20} width={150} />
        </div>

        {/* Top section */}
        <Skeleton height={20} width={50} className="mt-3" />
        <Skeleton height={30} width="100%" className="mt-2" />
        <Skeleton height={30} width="100%" className="mt-2" />
        <Skeleton height={30} width="100%" className="mt-2" />
        <Skeleton height={40} width="100%" className="my-3" />
        <Skeleton height={20} width={200} className="mt-2" />

        {/* Divider */}
        <Skeleton height={2} width="100%" className="my-3" />
    </div>
</div>

  )
}

export default CheckoutSkeleton