import React from "react";
import { Skeleton, Center, Text } from "@mantine/core";

const ArticleSkeleton = () => {
  return (
    <div className="flex flex-col items-center w-full">
    {/* For title */}
    <div className="mb-4 mt-5">
      <Skeleton height={30} width={250} />
    </div>
    {/* For image */}
    <div className="w-full sm:max-w-4xl mb-4 mt-2 sm:p-0 p-4">
      <Skeleton height="400px" width="100%" radius="md" />
    </div>
    {/* For paragraphs */}
    <div className="block px-4 sm:mx-7 w-full sm:max-w-4xl mt-2 sm:mt-4 mb-8">
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="80%" mt={6} radius="xl" />
      <Skeleton height={20} width="90%" mt={6} radius="xl" />
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="70%" mt={6} radius="xl" />
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="80%" mt={6} radius="xl" />
      <Skeleton height={20} width="90%" mt={6} radius="xl" />
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="70%" mt={6} radius="xl" />
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="80%" mt={6} radius="xl" />
      <Skeleton height={20} width="90%" mt={6} radius="xl" />
      <Skeleton height={20} width="100%" mt={6} radius="xl" />
      <Skeleton height={20} width="70%" mt={6} radius="xl" />
    </div>
  </div>
  );
};

export default ArticleSkeleton;
