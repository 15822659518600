import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Space, TextInput, Image, Select, MultiSelect } from "@mantine/core";
import Bidlabgroup from "../../data/bidlabgroup.png";
import TBLWorkstation from "../../data/TBLWorkstation.png";
import TBLDocuments from "../../data/TBLDocuments.png";
import TBLCollaborate from "../../data/TBLCollaborate.png";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { showNotification, updateNotification } from "@mantine/notifications";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { HiOutlineX } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";

const BidDataErrorForm = (props) => {
  const { user } = useUserAuth();
  // const [value, setValue] = useState([]);

  const form = useForm({
    initialValues: {
      title: props.title,
      url: props.url,
      fields: [""],
      additionalNotes: "",
    },
  });

  return (
    <div className="m-x-xs m-3">
      <form
        id="data-error-form"
        onSubmit={form.onSubmit(async (values) => {
          try {
            console.log(values);
            if (values.fields.length !== 0 && props.title != "" && props.url != "") {

              console.log(user.email);

              showNotification({
                id: "data-error-report-transaction",
                title: "Processing request...",
                color: "blue",
                loading: true,
              });

              try {
                const doc = await addDoc(collection(db, "mail"), {
                  to: "jacob@thebidlab.com, maurice@thebidlab.com, aaron@thebidlab.com, shweta@thebidlab.com",
                  message: {
                    subject: "Bid Banana: Inaccurate Bid Data",
                    html: `
                    <p>${user.email} has reported incorrect bid data for an opportunity.</p>
                    <p><b>Bid Title:</b> ${props.title}</p>
                    <p><b>URL:</b> ${props.url}</p>
                    <p><b>Site Name:</b> ${props.siteName}</p>
                    <p><b>Inaccurate Field(s):</b> ${values.fields.toString()}</p>
                    <p><b>Additional Notes:</b> ${values.additionalNotes === "" ? "None" : values.additionalNotes}</p>
                    `,
                  },
                });

                updateNotification({
                  id: "data-error-report-transaction",
                  title: "Success! Thank you for help!",
                  color: "green",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 7500,
                });
              } catch (e) {
                console.log(e);
                updateNotification({
                  id: "data-error-report-transaction",
                  title: "An error has occurred submitting your request. Please try again later or contact help if the issue persists.",
                  color: "red",
                  icon: <HiOutlineX size={16} />,
                  autoClose: 7500,
                });
              }
            } else {
              showNotification({
                title: "A required field has been left empty.",
                autoClose: 5000,
                color: "red",
              });
            }
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <section className="m-2 p-2 flex justify-center rounded-xl">
          <Image radius="md" src={TBLWorkstation} height={300}/>
        </section>
        <section className="m-2 p-2  ">
          <article className="px-2">
            <div className="font-semibold text-xl font-Lexend-Deca-Regular">Report Inaccurate Bid Data</div>
            <Space h="sm" />
            <div className="text-justify font-Lexend-Deca-Regular">Errors happen, but accuracy matters. Help us enhance our data quality by pointing out any fields that missed the mark.</div>
            <Space h="md" />
          </article>
          <article className="px-2">
            <TextInput
              mt="sm"
              mb="md"
              label="Bid Title"
              disabled
              required
              placeholder="Bid Title"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label: "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required: "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...form.getInputProps("title")}
            />
            <TextInput
              mt="sm"
              mb="md"
              label="Bid Source URL"
              disabled
              required
              placeholder="Bid Source URL"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label: "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required: "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...form.getInputProps("url")}
            />
            <MultiSelect
              mt="sm"
              mb="md"
              label="Inaccurate Field(s)"
              required
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label: "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required: "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              data={["Title", "State", "Agency", "Open Date", "Close Date", "Description", "Other"]}
              {...form.getInputProps("fields")}
            />
            <TextInput
              label="Additional notes (optional)"
              classNames={{
                input:
                  "font-Lexend-Deca-Regular focus:ring-1 transition ease-in-out focus:shadow-[0_0_4px_1px_rgb(0,208,228,0.3)] focus:shadow-[#00d0e4]focus:shadow:sm focus:outline-none focus:border-[#00d0e4] focus:ring-[#00d0e4] font-light rounded px-2 py-3 border border-[#cbd6e2] text-base bg-[#f5f8fa] text-[#33475b] leading-4 align-middle",
                label: "font-Lexend-Deca-Regular text-sm font-medium color-[#33475b] leading-6",
                required: "font-Lexend-Deca-Regular text-sm font-medium text-[#33475b] leading-6",
              }}
              {...form.getInputProps("additionalNotes")}
            />
          </article>
        </section>
        <div className="flex">
          <section className="ml-auto m-2 p-1 flex justify-end mb-3">
            <Button
              variant="default"
              size="sm"
              type="submit"
              className="border-1 border-[#425b76] text-[#425b76] font-Lexend-Deca-Regular"
            >
              Submit
            </Button>
          </section>
        </div>
      </form>
    </div>
  );
};

export default BidDataErrorForm;
