import { MediaQuery } from '@mantine/core'
import React from 'react'
import guidebannerleft from '../data/guidebannerleft.svg'
import guidebannerright from '../data/guidebannerright.svg'


const BananaBanner = ({title1,title2}) => {
  return (
    <div>
        <section className="flex justify-center w-full mb-2 px-4 sm:mt-0 sm:px-0 md:mt-0 lg:px-0 xl:mt-0 z-0">
        <div className="sm:text-center lg:text-center xl:text-center">
         <div className='inline-flex justify-between'> 
              <img
                src={guidebannerleft}
                width={440}
                height={200}
                alt='banana leaves'
                className="xl:block hidden"
              />
              <div className='m-1 pt-5 sm:flex sm:p-5 sm:m-5 text-center items-center'>
             <div className="text-3xl sm:flex-col align-middle sm:text-4xl font-bold tracking-tight text-gray-900 md:text-5xl">
            <span className=" text-4xl md:text-5xl align-bottom font-Roboto-Bold">{title1}</span>
            <br/>
            <span className="text-4xl sm:block text-transparent md:text-5xl bg-clip-text bg-gradient-to-tl from-bb-yellow to-leaf-medium-green  xl:inline font-Roboto-Bold"> {title2}</span>
          </div>
          </div>
            <img
              src={guidebannerright}
              width={440}
              height={200}
              alt='banana leaves'
              className="xl:block hidden"
            />
        </div>
      
         
          {/* description here if needed
          <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.</p> */}
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          </div>
        </div>
      </section>
    </div>
  )
}
//!!bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-bb-yellow  to-leaf-medium-green
//bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-green-800 via-green-300 to-yellow-400
//bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-stone-400 via-emerald-100 to-sky-500
//bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-emerald-800 via-amber-100 to-sky-300
//bg-gradient-to-bl from-sky-900 via-cyan-500 to-purple-800
export default BananaBanner