import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getFunctions } from "firebase/functions";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

let firebaseConfig;

if (
  process.env.REACT_APP_FIREBASE_ENVIRONMENT == "thebidlab-firebasedatabase"
) {
  // Production configuration
  firebaseConfig = {
    apiKey: "AIzaSyBZdd1FMZOLCkz54wVy1DzMY9sOK5X6rPY",
    authDomain: "thebidlab-firebasedatabase.firebaseapp.com",
    databaseURL:
      "https://thebidlab-firebasedatabase-default-rtdb.firebaseio.com",
    projectId: "thebidlab-firebasedatabase",
    storageBucket: "thebidlab-firebasedatabase.appspot.com",
    messagingSenderId: "930968709546",
    appId: "1:930968709546:web:bf43771653a9db4f82a3d3",
    measurementId: "G-212FPZMDBR",
  };
} else {
  // Development configuration
  firebaseConfig = {
    apiKey: "AIzaSyCOGI9D1qcXyY_-x3K8UX1bFjOyS2EsWRU",
    authDomain: "bidbanana-dev.firebaseapp.com",
    projectId: "bidbanana-dev",
    storageBucket: "bidbanana-dev.appspot.com",
    messagingSenderId: "981775735994",
    appId: "1:981775735994:web:e346cb05cbc9cb681bf683",
    measurementId: "G-JP7GBT1D2Q",
  };
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LfD5rsjAAAAAH42GyT1BSt8pRubp0nET6VJWZg2'),
//   isTokenAutoRefreshEnabled: true
// });
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});
const auth = getAuth(app);

export { app, auth, db, functions, analytics, payments };
